import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
// import Sidebar from "../../common/Sidebar - Copy";
import Sidebar from "../../common/Sidebar";
import Footer from "../../common/Footer";
import axios from 'axios';
import { getToken, triggerAlert } from '../../utils'
import Loader from "../../common/Loader";
import DashboardSectionOne from "./DashboardSectionOne";
// import DashboardSectionTwo from "./DashboardSectionTwo";
import CountUp from 'react-countup';
function Dashboard() {
    document.title = "Talent scan - Dashboard"
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const api = process.env.REACT_APP_CUSTURL;
    const token = getToken();
    const [isLoading, setIsLoading] = useState(false);
    const [dashboardCounts, setDashboardCounts] = useState(null);

    const fetchData = async () => {
        // setIsLoading(true);
        try {
            const response = await axios.get(api_url + `dashboard/get_dashboard_count`, token);

            const response_data = response.data;
            // console.log('response_dataCards', response_data);

            if (response_data.error_code === 200) {
                const itemsArray = response_data.results.data
                // console.log('status', dashboardCounts.ticket_data[0].status)
                setDashboardCounts(itemsArray);
                // setIsLoading(false);
            } else if (response.status === 204) {
                setDashboardCounts([]);
                // setIsLoading(false);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the card details");
            }
        } catch (error) {
            // setIsLoading(false);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    };

    useEffect(() => {
        // fetchData();
        getcustomerlist()
        // alert('I am called')
    }, [])
    const [customercount, setcustomercount] = useState([])
    const getcustomerlist = async () => {
        try {
            const response = await axios.get(`/apiV1/dashboard/customer_status_count`);
            if (response.data) {
                let results = response.data;
                setcustomercount(results)


            }
        } catch (error) {
            console.log("not found");

        }
    };

    const [openticketsview, setopenticketsview] = useState({})
    const [totallist, settotallist] = useState([{}])
    useEffect(() => {

        getcustomerlist1()
    }, [])
    const getcustomerlist1 = async () => {
        try {
            const response = await axios.get(`/apiV1/dashboard/get_dashboard_count`);
            if (response.data) {
                let results = response.data.results;
                setopenticketsview(results)


            }
        } catch (error) {
            console.log("not found");

        }
    };




    return (
        <>
            <div id="layout-wrapper">

                <Header />
                <Sidebar />

                {/* <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== --> */}
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18">Dashboard</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                {/* <!-- <li className="breadcrumb-item"><a href="javascript: void(0);">Dashboard</a></li>
                                            <li className="breadcrumb-item active">Horizontal</li> --> */}
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className="row">
                                {/* Container for the first three cards */}
                                <div className="col-xxl-6 col-xl-6 col-lg-6 pt-4">


                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className="card-title mb-0 flex-grow-1 card-title-custom">
                                                Customer status
                                            </h4>
                                            <hr />
                                        </div>

                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="row align-items-center">
                                                        <div className="col-12">
                                                            <h4 className="card-title mb-0 flex-grow-1 text-org pb-2">Total Customer</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <h4 className="">{customercount.total_customers}</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="toll-free IconContainer">
                                                                <div className="rounded-circle avatar-xl">
                                                                    <i className="fa fa-user-plus" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="row align-items-center">
                                                        <div className="col-12">
                                                            <h4 className="card-title mb-0 flex-grow-1 text-org pb-2">Active Customer</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <h4 className="">{customercount.total_active_cust}</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="toll-free IconContainer">
                                                                <div className="rounded-circle avatar-xl">
                                                                    <i className="fa fa-users" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="row align-items-center">
                                                        <div className="col-12">
                                                            <h4 className="card-title mb-0 flex-grow-1 text-org pb-2">Inactive Customer</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <h4 className="">{customercount.total_inactive_cust}</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="toll-free IconContainer">
                                                                <div className="rounded-circle avatar-xl">
                                                                    <i className="fa fa-user-times" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Container for the next three cards */}
                                <div className="col-xxl-6 col-xl-6 col-lg-6 pt-4">

                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className="card-title mb-0 flex-grow-1 card-title-custom">
                                                Ticket status
                                            </h4>
                                            <hr />
                                        </div>

                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="row align-items-center">
                                                        <div className="col-12">
                                                            <h4 className="card-title mb-0 flex-grow-1 text-org pb-2">Open Ticket</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <h4 className="">{openticketsview.Open}</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="toll-free IconContainer">
                                                                <div className="rounded-circle avatar-xl">
                                                                    <i className="fa fa-hourglass-start fns-12 " aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="row align-items-center">
                                                        <div className="col-12">
                                                            <h4 className="card-title mb-0 flex-grow-1 text-org pb-2">In Progress</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <h4 className="">{openticketsview.Pending}</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="toll-free IconContainer">
                                                                <div className="rounded-circle avatar-xl">
                                                                    <i className="fa fa-hourglass-half fns-12 " aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="row align-items-center">
                                                        <div className="col-12">
                                                            <h4 className="card-title mb-0 flex-grow-1 text-org pb-2">Close Ticket</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <h4 className="">{openticketsview.Closed}</h4>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="toll-free IconContainer">
                                                                <div className="rounded-circle avatar-xl">
                                                                    <i className="fa fa-hourglass-end fns-12 " aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>













                            {/* <!-- end row--> */}
                            <DashboardSectionOne />
                            {/* <DashboardSectionTwo /> */}


                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}

                    <Footer />
                </div>
                {/* <!-- end main content--> */}
            </div>
            {/* <!-- END layout-wrapper --> */}

            {/* <!-- Right bar overlay--> */}
            <div className="rightbar-overlay"></div>
        </>
    );
}

export default Dashboard;