import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { pageReload , getToken , triggerAlert ,set_menu_details ,set_user_permission } from '../utils';
import axios from 'axios';

function Sidebar() {
    const location = useLocation();
	const api_url = process.env.REACT_APP_API_BASE_URL;
    const [menuState, setMenuState] = useState({
        // customers: false,
        // dids: false,
        // billing: false,
        // reports: false,
        // support: false,
        // number_portability: false,
        // endpoints: false,
        // manage_admin:false,
    });
    const [subMenuState, setSubMenuState] = useState({
        // customer_list: false,
        // create_customer: false,
        // active_customers: false,
        // create_service: false,
        // manage_service: false,
        // assigned_dids: false,
        // unassigned_dids: false,
        // order_dids: false,
        // billing_info: false,
        // payment_details: false,
        // create_invoice: false,
        // invoice: false,
        // balance_history: false,
        // transaction_history: false,
        // request_status: false,
        // endpoints: false,
        // coming_soon: false,
        // user_department:false,
        // user_logs_list:false,
        // user_level:false,
        // user_list:false,
        // user_menu:false,
        // menu_permission:false,

    });

    const [menuList, setMenuList] = useState([])
    const [userParentModules, setUserParentModules]	= useState([]);  
    const [userChildModules, setUserChildModules]  	= useState([]);   

    const toggleMainMenu = (mainmenu) => {
        // setMenuState((prevState) => ({
        //     ...prevState,
        //     [mainmenu]: !prevState[mainmenu]
        // }));
        setMenuState((prevState) => {
            // Close any previously opened main menu
            const updatedState = { ...prevState };
            for (const key in updatedState) {
                if (key !== mainmenu) {
                    updatedState[key] = false;
                }
            }

            // Update the clicked main menu state
            updatedState[mainmenu] = !prevState[mainmenu];

            return updatedState;
        });
    };

    const toggleSubMenu = (submenu) => { 
        // if (location.pathname === submenu) {
        //     pageReload();
        // }

        // const submodule = submenu.split("/")[2];
        // setSubMenuState((prevState) => ({
        //     ...prevState,
        //     [submodule]: !prevState[submodule]
        // }));
    };

    const fetchMenuList = async () => {
		try {
			
            const itemsArray = JSON.parse(sessionStorage.getItem('admin_menu_data'));
 
            // Initialize menuState and subMenuState based on menuList
            setMenuState((prevState) => {
                const menuStateObj = { ...prevState };
                itemsArray.forEach((item) => {
                    const mainMenuKey = item.page_name;
                    menuStateObj[mainMenuKey] = menuStateObj[mainMenuKey] || false;

                    if (item.submenu && item.submenu.length > 0) {
                        item.submenu.forEach((subItem) => {
                            const subMenuKey = subItem.page_name.split('/').pop();
                            menuStateObj[subMenuKey] = menuStateObj[subMenuKey] || false;
                            subItem.child_menu = subMenuKey; // Add this line
                        });
                    }
                });
                return menuStateObj;
            });

            setSubMenuState((prevState) => {
                const subMenuStateObj = { ...prevState };
                itemsArray.forEach((item) => {
                    if (item.submenu && item.submenu.length > 0) {
                        item.submenu.forEach((subItem) => {
                            const subMenuKey = subItem.page_name.split('/').pop();
                            subMenuStateObj[subMenuKey] = subMenuStateObj[subMenuKey] || false;
                        });
                    }
                });
                return subMenuStateObj;
            });

            // console.log("itemsArray", itemsArray);
            setMenuList(itemsArray);

			
		} catch (error) {
			// console.log('error', error);
			triggerAlert('error', 'Oops...', 'Something went wrong');
		}
	}

    const fetchUserPermissionData = async () => {  

        try { 
  
            
            const itemsArray = JSON.parse(sessionStorage.getItem('admin_permission_data'));

            const parent_id_string  = itemsArray.parent_id;
            const parent_id_array   = parent_id_string.split(',').map(Number);   
            setUserParentModules(parent_id_array);

            ///////////////////////////////
            const child_id_string  = itemsArray.child_id;
            const child_id_array   = child_id_string.split(',').map(Number);   
            setUserChildModules(child_id_array);


                 
            

        } catch (error) {  
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };
    const [customers, setcustomers] = useState(false)
    const [Billing, setBilling] = useState(false)
    const [Reports, setReports] = useState(false)
    const [Supports, setSupports] = useState(false)
    const [ManageAdmin, setManageAdmin] = useState(false)
    const [customerslist, setcustomerslist] = useState(false)
    const [BillingInformation, setBillingInformation] = useState(false)
    const [PaymentDetails, setPaymentDetails] = useState(false)
    const [Invoice, setInvoice] = useState(false)
    const [CreateInvoice, setCreateInvoice] = useState(false)
    const [Revenue, setRevenue] = useState(false)
    const [Userlogs, setUserlogs] = useState(false)
    const [ManageTickets, setManageTickets] = useState(false)
    const [CreateTickets, setCreateTickets] = useState(false)
    const [TicketsCategories, setTicketsCategories] = useState(false)
    const [PredefinedReplies, setPredefinedReplies] = useState(false)
    const [UserLevel, setUserLevel] = useState(false)
    const [UserList, setUserList] = useState(false)
    const [Plans, setPlans] = useState(false)
    const [Products, setProducts] = useState(false)
    const [Buycredit, setBuyCredit] = useState(false)
    const [Creditconfig, setCreditConfig] = useState(false)
    const [settings, setsettings] = useState(false)
    const [MenuPermissions, setMenuPermissions] = useState(false)
    const fetchMenuListApi = async () => {   
        let role_id = sessionStorage.getItem('user_type')
        let token =sessionStorage.getItem("admin_user_token")
        try { 
            const response = await axios.get(`apiV1/module-mappings/?role_id=${role_id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const response_data = response.data   
            if (response.status === 200) { 
                const itemsArray = response_data.results; 
                sessionStorage.setItem('modulepermissions',  JSON.stringify(itemsArray))
                showpageswith(response_data.results)
                setMenuList(itemsArray)
            } else if (response.status === 204) { 

            }
            else { 
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {  
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };

    const fetchUserPermissionDataApi = async () => {  
        try { 
  
            const response      = await axios.get(api_url + 'manage_admin/admin_menu_by_id'); 
            const response_data = response.data 

            if (response.status === 200) { 
                const itemsArray = response_data.results[0]; 

				set_user_permission(itemsArray)
                fetchUserPermissionData();
                 
            } else if (response.status === 204) { 

            }
            else { 
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {  
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };
    const showpageswith = (itemsArraysd)=>{
        itemsArraysd.map((item, i)=>{
            item.sub_module_info.map((submodule, i)=>{
                if(submodule.name== "Customer List" && submodule.permissions.indexOf(1) !== -1 ){
                    setcustomerslist(true)
                }

                if(submodule.name== "Billing Information" && submodule.permissions.indexOf(1) !== -1 ){
                    setBillingInformation(true)
                }
                if(submodule.name== "Payment Details" && submodule.permissions.indexOf(1) !== -1 ){
                    setPaymentDetails(true)
                }
                if(submodule.name== "Invoice" && submodule.permissions.indexOf(1) !== -1 ){
                    setInvoice(true)
                }
                if(submodule.name== "Create Invoice" && submodule.permissions.indexOf(1) !== -1 ){
                    setCreateInvoice(true)
                }

                if(submodule.name== "Revenue" && submodule.permissions.indexOf(1) !== -1 ){
                    setRevenue(true)
                }

                if(submodule.name== "User logs" && submodule.permissions.indexOf(1) !== -1 ){
                    setUserlogs(true)
                }
                if(submodule.name== "Manage Tickets" && submodule.permissions.indexOf(1) !== -1 ){
                    setManageTickets(true)
                }
                if(submodule.name== "Create Tickets" && submodule.permissions.indexOf(1) !== -1 ){
                    setCreateTickets(true)
                }
                if(submodule.name== "Tickets Categories" && submodule.permissions.indexOf(1) !== -1 ){
                    setTicketsCategories(true)
                }
                if(submodule.name== "Predefined Replies" && submodule.permissions.indexOf(1) !== -1 ){
                    setPredefinedReplies(true)
                }
                if(submodule.name== "User Role" && submodule.permissions.indexOf(1) !== -1 ){
                    setUserLevel(true)
                }
                if(submodule.name== "User List" && submodule.permissions.indexOf(1) !== -1 ){
                    setUserList(true)
                }
                if(submodule.name== "Access Privileges" && submodule.permissions.indexOf(1) !== -1 ){
                    setMenuPermissions(true)
                }
                if(submodule.name== "Products" && submodule.permissions.indexOf(1) !== -1 ){
                    setProducts(true)
                }
                if(submodule.name== "Plans" && submodule.permissions.indexOf(1) !== -1 ){
                    setPlans(true)
                }
                if(submodule.name== "Buy Additional Credits" && submodule.permissions.indexOf(1) !== -1 ){
                    setBuyCredit(true)
                }
                if(submodule.name== "Credit Config" && submodule.permissions.indexOf(1) !== -1 ){
                    setCreditConfig(true)
                }
                
            })    

            if(item.module_info.name== "Customers" && item.module_info.permissions.indexOf(1) !== -1 ){
                setcustomers(true)
            }

            if(item.module_info.name== "Billing" && item.module_info.permissions.indexOf(1) !== -1 ){
                setBilling(true)
            }
            if(item.module_info.name== "Reports" && item.module_info.permissions.indexOf(1) !== -1 ){
                setReports(true)
            }
            if(item.module_info.name== "Supports" && item.module_info.permissions.indexOf(1) !== -1 ){
                setSupports(true)
            }
            if(item.module_info.name== "User Management" && item.module_info.permissions.indexOf(1) !== -1 ){
                setManageAdmin(true)
            }
            if(item.module_info.name== "Settings" && item.module_info.permissions.indexOf(1) !== -1 ){
                setsettings(true)
            } 
            
        })
    }
    
    useEffect(() => {

        let itemsArraysd = JSON.parse(sessionStorage.getItem('modulepermissions'))
        if(itemsArraysd){
            showpageswith(itemsArraysd)
        } else{
            fetchMenuListApi();
        }
        //fetchMenuListApi()
        let admin_menu_data = sessionStorage.getItem('admin_menu_data');
        if(admin_menu_data){ 
            fetchMenuList();
        }else{
            
        } 
         
        // let admin_permission_data = sessionStorage.getItem('admin_permission_data');
        // if(admin_permission_data){ 
        //     fetchUserPermissionData();
        // }else{
        //    // fetchUserPermissionDataApi()
        // } 

		if (location) {
			const path_name = location.pathname;
			const main_module = path_name.split("/")[1];
			const sub_module = path_name.split("/")[2];
            unsetData(sub_module);

			setMenuState((prevState) => ({
				...prevState,
				[main_module]: true,
			}));
			setSubMenuState((prevState) => ({
				...prevState,
				[sub_module]: true,
			}));
		}

    }, []);

    const  unsetData = (pathname) => {
        if (pathname != 'request_status') {
            sessionStorage.setItem('num_req_count', "0");
        } 
        if (pathname != 'endpoints') {
            sessionStorage.setItem('count', "0");
        } 
        if (pathname != 'assigned_dids') {
            sessionStorage.setItem('did_count', "0");
        }
    }

    //console.log(userParentModules);

    return (

        <div className="vertical-menu">
            <div data-simplebar className="h-100">
                {/* <!--- Sidemenu --> */}
                <div id="sidebar-menu">
                    {/* <!-- Left Menu Start --> */}
                    <ul className="metismenu list-unstyled" id="side-menu">
                        
                         
                        
                        <li className={menuState['dashboard'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('dashboard')}>
                            <Link to="/dashboard" className={menuState['dashboard'] ? 'active' : ''}>
                                <i className="bx  bx bx-home-alt"></i>
                                <span data-key="t-dashboard">Dashboard</span>
                            </Link>
                        </li>
                        
                        {customers && customerslist ? 
                        <li className={menuState['customers'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('customers')}>
                            <a role='button' className="has-arrow waves-effect" aria-expanded={menuState['customers']} >
                                <i className="bx bx-user-circle"></i>
                                <span data-key="t-ui-elements">Customers</span>
                            </a>
                            <ul className={menuState['customers'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['customers']}>
                                {customerslist &&
                                <li className={subMenuState['customerslist'] ? 'mm-active' : ''}>
                                    <Link to="/customers/customerslist" data-key="t-lightbox" className={subMenuState['customerslist'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_user/customerslist')}>Customers List</Link>
                                </li>  }
                                
                            </ul>
                        </li> : ""}
                        {Billing && (BillingInformation || PaymentDetails || Invoice || CreateInvoice) ? 
                        <li className={menuState['billing'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('billing')}>
                            <a role='button' className="has-arrow waves-effect" aria-expanded={menuState['billing']} >
                                <i className="mdi mdi-account-cash-outline"></i>
                                <span data-key="t-pages">Billing</span>
                            </a>
                            <ul className={menuState['billing'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['billing']}>
                                {BillingInformation &&
                                <li className={subMenuState['billing_info'] ? 'mm-active' : ''}>
                                    <Link to="/billing/billing_info" data-key="t-starter-page" className={subMenuState['billing_info'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/billing_info')}>
                                        Billing Information</Link>
                                </li>}
                                {PaymentDetails &&
                                <li className={subMenuState['payment_details'] ? 'mm-active' : ''}>
                                    <Link to="/billing/payment_details" data-key="t-maintenance" className={subMenuState['payment_details'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/payment_details')}>
                                        Payment Details</Link></li>}
                                {/* <li className={subMenuState['card_details'] ? 'mm-active' : ''}>
                                    <Link to="/billing/card_details" data-key="t-maintenance" className={subMenuState['card_details'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/card_details')}>
                                        Card Details</Link></li> */}
                                {Invoice &&
                                <li className={subMenuState['invoice'] ? 'mm-active' : ''}>
                                    <Link to="/billing/invoice" data-key="t-coming-soon" className={subMenuState['invoice'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/invoice')}>
                                        Invoice </Link></li>}
                                {CreateInvoice &&
                                <li className={subMenuState['create_invoice'] ? 'mm-active' : ''}>
                                    <Link to="/billing/create_invoice" data-key="t-timeline" className={subMenuState['create_invoice'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/create_invoice')}>
                                        Create Invoice</Link></li>}
                                {/* <li className={subMenuState['balance_history'] ? 'mm-active' : ''}>
                                    <Link to="/billing/balance_history" data-key="t-faqs" className={subMenuState['balance_history'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/balance_history')}>
                                        Balance History</Link></li>
                                <li className={subMenuState['transaction_history'] ? 'mm-active' : ''}>
                                    <Link to="/billing/transaction_history" data-key="t-faqs" className={subMenuState['transaction_history'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/transaction_history')}>
                                        Transaction History</Link></li> */}
                            </ul>
                        </li> : ""}
                        {Reports && (Revenue || Userlogs) ?
                        <li className={menuState['reports'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('reports')}>
                            <a href="#/" className="has-arrow waves-effect" aria-expanded={menuState['reports']} >
                                <i className="mdi mdi-file-document-multiple-outline"></i>
                                <span data-key="t-components">Reports</span>
                            </a>
                            <ul className={menuState['reports'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['reports']}>
                                
                                {Revenue && <li className={subMenuState['revenue'] ? 'mm-active' : ''}><Link to="/reports/revenue" data-key="t-buttons" className={subMenuState['revenue'] ? 'active' : ''} onClick={() => toggleSubMenu('/revenue')}>Revenue</Link></li>}
                                {Userlogs && <li className={subMenuState['user_logs_list'] ? 'mm-active' : ''}><Link to="/reports/user_logs_list" data-key="t-cards" className={subMenuState['user_logs_list'] ? 'active' : ''} onClick={() => toggleSubMenu('/user_logs_list')}>User Logs</Link></li>}
                            </ul>
                        </li> : ""}
                        {Supports && (ManageTickets || CreateTickets || TicketsCategories || PredefinedReplies) ?
                        <li className={menuState['support'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('support')}>
                            <a role='button' className="has-arrow waves-effect" aria-expanded={menuState['support']} >
                                <i className="mdi mdi-headset"></i>
                                <span data-key="t-ui-elements">Supports</span>
                            </a>
                            <ul className={menuState['support'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['support']}>
                               {ManageTickets && <li className={subMenuState['manage_tickets'] ? 'mm-active' : ''}>
                                    <Link to="/support/manage_tickets" data-key="t-lightbox" className={subMenuState['manage_tickets'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/manage_tickets')}>Manage Tickets</Link></li>}
                                {CreateTickets && <li className={subMenuState['create_tickets'] ? 'mm-active' : ''}>
                                    <Link to="/support/create_tickets" data-key="t-range-slider" className={subMenuState['create_tickets'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/create_tickets')}>Create Tickets</Link></li>}
                               {TicketsCategories && <li className={subMenuState['categories'] ? 'mm-active' : ''}>
                                    <Link to="/support/categories" data-key="t-sweet-alert" className={subMenuState['categories'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/categories')}>Ticket Categories</Link></li>}
                                    {TicketsCategories && <li className={subMenuState['create_categories'] ? 'mm-active' : ''}>
                                    <Link to="/support/create_categories" data-key="t-session-timeout" className={subMenuState['create_categories'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/create_categories')}>Create Categories</Link>
                                </li>}
                                {PredefinedReplies && <li className={subMenuState['predefined_replies'] ? 'mm-active' : ''}>
                                    <Link to="/support/predefined_replies" data-key="t-session-timeout" className={subMenuState['predefined_replies'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/predefined_replies')}>Predefined Replies</Link>
                                </li>}
                                {/* <li className={subMenuState['customer_notes'] ? 'mm-active' : ''}>
                                    <Link to="/support/customer_notes" data-key="t-session-timeout" className={subMenuState['customer_notes'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/customer_notes')}>Customer Notes</Link>
                                </li> */}
                            </ul>
                        </li> : ""}
                        {ManageAdmin && (MenuPermissions || UserLevel || UserList) ?
                        <li className={menuState['manage_user'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('manage_user')}>
                            <a  role='button' className="has-arrow waves-effect" aria-expanded={menuState['manage_user']} >
                                <i className="bx bx-user"></i>
                                <span data-key="t-ui-elements">User Management</span>
                            </a>
                            <ul className={menuState['manage_user'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['manage_user']}>
                                {UserList && <li className={subMenuState['user_list'] ? 'mm-active' : ''}>
                                    <Link to="/manage_user/user_list" data-key="t-lightbox" className={subMenuState['user_list'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_user/user_list')}>User List</Link>
                                </li>  }
                                {UserLevel && <li className={subMenuState['user_roles'] ? 'mm-active' : ''}>
                                    <Link to="/manage_user/user_roles" data-key="t-lightbox" className={subMenuState['user_roles'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_user/user_roles')}>User Roles</Link>
                                </li>  }
                                {MenuPermissions && <li className={subMenuState['access_privileges'] ? 'mm-active' : ''}>
                                    <Link to="/manage_user/access_privileges" data-key="t-lightbox" className={subMenuState['access_privileges'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_user/access_privileges')}>Access Privileges</Link>
                                </li>  }
                            </ul>
                        </li> : ""}
                        {settings && (Products ||Plans || Buycredit || Creditconfig ) ?
                        <li className={menuState['settings'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('settings')}>
                            <a role='button' className="has-arrow waves-effect" aria-expanded={menuState['settings']} >
                                <i className="bx bx-cog"></i>
                                <span data-key="t-ui-elements">Settings</span>
                            </a>
                            <ul className={menuState['settings'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['settings']}>
                                {Products && 
                                <li className={subMenuState['products'] ? 'mm-active' : ''}>
                                    <Link to="/settings/products" data-key="t-lightbox" className={subMenuState['products'] ? 'active' : ''} onClick={() => toggleSubMenu('/settings/products')}>Products</Link>
                                </li>}
                                {Plans &&
                                <li className={subMenuState['plans'] ? 'mm-active' : ''}>
                                    <Link to="/settings/plans" data-key="t-lightbox" className={subMenuState['plans'] ? 'active' : ''} onClick={() => toggleSubMenu('/settings/plans')}>Plans</Link>
                                </li>  }
                                {Buycredit &&
                                <li className={subMenuState['buycredit'] ? 'mm-active' : ''}>
                                    <Link to="/settings/Buycredit" data-key="t-lightbox" className={subMenuState['buycredit'] ? 'active' : ''} onClick={() => toggleSubMenu('/settings/Buycredit')}>Buy Additional Credits</Link>
                                </li> }
                                {Creditconfig &&
                                <li className={subMenuState['creditconfig'] ? 'mm-active' : ''}>
                                    <Link to="/settings/Creditconfig" data-key="t-lightbox" className={subMenuState['creditconfig'] ? 'active' : ''} onClick={() => toggleSubMenu('/settings/Creditconfig')}>Credit Config</Link>
                                </li>}
                            </ul>
                        </li> :""}
                        {/* <li className={menuState['manage_admin'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('manage_admin')}>
                            <a href="#/" className="has-arrow waves-effect" aria-expanded={menuState['manage_admin']} >
                                <i className="bx bx-user"></i>
                                <span data-key="t-ui-elements">Manage Admin</span>
                            </a>
                            <ul className={menuState['manage_admin'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['manage_admin']}>
                                <li className={subMenuState['user_department'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/user_department" data-key="t-lightbox" className={subMenuState['user_department'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/user_department')}>User Departments</Link>
                                </li>  
                                <li className={subMenuState['user_logs_list'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/user_logs_list" data-key="t-lightbox" className={subMenuState['user_logs_list'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/user_logs_list')}>User Logs</Link>
                                </li>  
                                <li className={subMenuState['user_level'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/user_level" data-key="t-lightbox" className={subMenuState['user_level'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/user_level')}>User Level</Link>
                                </li>  
                                <li className={subMenuState['user_list'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/user_list" data-key="t-lightbox" className={subMenuState['user_list'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/user_list')}>User List</Link>
                                </li> 
                                <li className={subMenuState['user_menu'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/user_menu" data-key="t-lightbox" className={subMenuState['user_menu'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/user_menu')}>User Menu</Link>
                                </li> 
                                <li className={subMenuState['menu_permission'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/menu_permission" data-key="t-lightbox" className={subMenuState['menu_permission'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/menu_permission')}>Menu Permission</Link>
                                </li> 
                            </ul>
                        </li> */}
                        {/* { menuList.map((item) => (
                            < >
                            
                                <li key={item.id} className={location.pathname === "/customers/customer_list" ? 'mm-active' : ''}  onClick={() => toggleMainMenu(item.page_name)}>
                                    {item.sub_module_info.length ? (
                                    <>
                                    <a href="#/" className="has-arrow waves-effect" aria-expanded={item.module_info.name}  >
                                        <i className={item.menu_icon}></i>
                                        <span data-key="t-apps">{item.module_info.name}</span>
                                    </a>

                                    <ul className={location.pathname === "/customers/customer_list" ?   'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse' } >
                                    {item.sub_module_info.map((submenu) => (
                                        < >
                                         
                                        <li key={submenu.id} className={subMenuState[submenu.child_menu] ? 'mm-active' : ''}>
                                            <Link to={submenu.page_name} className={subMenuState[submenu.child_menu] ? 'active' : ''} onClick={() => toggleSubMenu(submenu.page_name)}>
                                                <span data-key="t-calendar">{submenu.name}</span>
                                            </Link>
                                        </li>
                                         
                                        </>
                                    ))} 
                                    
                                    </ul>
                                    </>
                                    ) : (
                                    <Link to={item.page_name} className={menuState[item.page_name] ? 'active' : ''}>
                                        <i className={item.menu_icon}></i>
                                        <span data-key="t-dashboard">{item.menu_name}</span>
                                    </Link>
                                    )}
                                    
                                </li>
                            
                            </>
                        ))} */}
                        {/* <li className={menuState['dids'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('dids')}>
                            <a href="#/" className="has-arrow  waves-effect" aria-expanded={menuState['dids']}  >
                                <i className="bx bx-receipt"></i>

                                <span data-key="t-authentication">DIDs</span>
                            </a>
                            <ul className={menuState['dids'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['dids']}>
                                <li className={subMenuState['assigned_dids'] ? 'mm-active' : ''}>
                                    <Link to="/dids/assigned_dids" data-key="t-login" className={subMenuState['assigned_dids'] ? 'active' : ''} onClick={() => toggleSubMenu('/dids/assigned_dids')}>
                                        Assigned DIDs</Link>
                                </li>
                                <li className={subMenuState['unassigned_dids'] ? 'mm-active' : ''}>
                                    <Link to="/dids/unassigned_dids" data-key="t-register" className={subMenuState['unassigned_dids'] ? 'active' : ''} onClick={() => toggleSubMenu('/dids/unassigned_dids')}>
                                        Unassigned DIDs</Link>
                                </li>
                                <li className={subMenuState['order_dids'] ? 'mm-active' : ''}>
                                    <Link to="/dids/order_dids" data-key="t-logout" className={subMenuState['order_dids'] ? 'active' : ''} onClick={() => toggleSubMenu('/dids/order_dids')}>
                                        Order DIDs</Link>
                                </li>
                            </ul>
                        </li>

                        <li className={menuState['billing'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('billing')}>
                            <a href="#/" className="has-arrow waves-effect" aria-expanded={menuState['billing']} >
                                <i className="mdi mdi-account-cash-outline"></i>
                                <span data-key="t-pages">Billing</span>
                            </a>
                            <ul className={menuState['billing'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['billing']}>
                                <li className={subMenuState['billing_info'] ? 'mm-active' : ''}>
                                    <Link to="/billing/billing_info" data-key="t-starter-page" className={subMenuState['billing_info'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/billing_info')}>
                                        Billing Information</Link>
                                </li>
                                <li className={subMenuState['payment_details'] ? 'mm-active' : ''}>
                                    <Link to="/billing/payment_details" data-key="t-maintenance" className={subMenuState['payment_details'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/payment_details')}>
                                        Payment Details</Link></li>
                                <li className={subMenuState['card_details'] ? 'mm-active' : ''}>
                                    <Link to="/billing/card_details" data-key="t-maintenance" className={subMenuState['card_details'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/card_details')}>
                                        Card Details</Link></li>
                                <li className={subMenuState['invoice'] ? 'mm-active' : ''}>
                                    <Link to="/billing/invoice" data-key="t-coming-soon" className={subMenuState['invoice'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/invoice')}>
                                        Invoice </Link></li>
                                <li className={subMenuState['create_invoice'] ? 'mm-active' : ''}>
                                    <Link to="/billing/create_invoice" data-key="t-timeline" className={subMenuState['create_invoice'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/create_invoice')}>
                                        Create Invoice</Link></li>
                                <li className={subMenuState['balance_history'] ? 'mm-active' : ''}>
                                    <Link to="/billing/balance_history" data-key="t-faqs" className={subMenuState['balance_history'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/balance_history')}>
                                        Balance History</Link></li>
                                <li className={subMenuState['transaction_history'] ? 'mm-active' : ''}>
                                    <Link to="/billing/transaction_history" data-key="t-faqs" className={subMenuState['transaction_history'] ? 'active' : ''} onClick={() => toggleSubMenu('/billing/transaction_history')}>
                                        Transaction History</Link></li>
                            </ul>
                        </li>

                        <li className={menuState['reports'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('reports')}>
                            <a href="#/" className="has-arrow waves-effect" aria-expanded={menuState['reports']} >
                                <i className="mdi mdi-file-document-multiple-outline"></i>
                                <span data-key="t-components">Reports</span>
                            </a>
                            <ul className={menuState['reports'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['reports']}>
                                <li className={subMenuState['coming_soon'] ? 'mm-active' : ''}><Link to="/coming_soon" data-key="t-alerts" className={subMenuState['coming_soon'] ? 'active' : ''} onClick={() => toggleSubMenu('/coming_soon')}> CDRs</Link></li>
                                <li className={subMenuState['coming_soon'] ? 'mm-active' : ''}><Link to="/coming_soon" data-key="t-buttons" className={subMenuState['coming_soon'] ? 'active' : ''} onClick={() => toggleSubMenu('/coming_soon')}>Revenue</Link></li>
                                <li className={subMenuState['coming_soon'] ? 'mm-active' : ''}><Link to="/coming_soon" data-key="t-cards" className={subMenuState['coming_soon'] ? 'active' : ''} onClick={() => toggleSubMenu('/coming_soon')}>Call History</Link></li>
                            </ul>
                        </li>

                        <li className={menuState['support'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('support')}>
                            <a href="#/" className="has-arrow waves-effect" aria-expanded={menuState['support']} >
                                <i className="mdi mdi-headset"></i>
                                <span data-key="t-ui-elements">Supports</span>
                            </a>
                            <ul className={menuState['support'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['support']}>
                                <li className={subMenuState['manage_tickets'] ? 'mm-active' : ''}>
                                    <Link to="/support/manage_tickets" data-key="t-lightbox" className={subMenuState['manage_tickets'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/manage_tickets')}>Manage Tickets</Link></li>
                                <li className={subMenuState['create_tickets'] ? 'mm-active' : ''}>
                                    <Link to="/support/create_tickets" data-key="t-range-slider" className={subMenuState['create_tickets'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/create_tickets')}>Create Tickets</Link></li>
                                <li className={subMenuState['categories'] ? 'mm-active' : ''}>
                                    <Link to="/support/categories" data-key="t-sweet-alert" className={subMenuState['categories'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/categories')}>Ticket Categories</Link></li>
                                <li className={subMenuState['create_categories'] ? 'mm-active' : ''}>
                                    <Link to="/support/create_categories" data-key="t-session-timeout" className={subMenuState['create_categories'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/create_categories')}>Create Categories</Link>
                                </li>
                                <li className={subMenuState['predefined_replies'] ? 'mm-active' : ''}>
                                    <Link to="/support/predefined_replies" data-key="t-session-timeout" className={subMenuState['predefined_replies'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/predefined_replies')}>Predefined Replies</Link>
                                </li>
                                <li className={subMenuState['customer_notes'] ? 'mm-active' : ''}>
                                    <Link to="/support/customer_notes" data-key="t-session-timeout" className={subMenuState['customer_notes'] ? 'active' : ''} onClick={() => toggleSubMenu('/support/customer_notes')}>Customer Notes</Link>
                                </li>
                            </ul>
                        </li>

                        <li className={menuState['number_portability'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('number_portability')}>
                            <a href="#/" className="has-arrow  waves-effect" aria-expanded={menuState['number_portability']} >
                                <i className="mdi mdi-phone-rotate-portrait"></i>
                                <span data-key="t-forms">Number Portability</span>
                            </a>
                            <ul className={menuState['number_portability'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['number_portability']}>
                                <li className={subMenuState['request_status'] ? 'mm-active' : ''}>
                                    <Link to="/number_portability/request_status" data-key="t-form-elements" className={subMenuState['request_status'] ? 'active' : ''} onClick={() => toggleSubMenu('/number_portability/request_status')}>Request Status </Link>
                                </li>
                                 
                            </ul>
                        </li>

                        <li className={menuState['switches'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('switches')}>
                            <a href="#/" className="has-arrow waves-effect" aria-expanded={menuState['switches']} >
                                <i className="mdi mdi-ip-outline"></i>
                                <span data-key="t-tables">End Points</span>
                            </a>
                            <ul className={menuState.switches ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState.switches}>
                                <li className={subMenuState['endpoints'] ? 'mm-active' : ''}>
                                    <Link to="/endpoints/endpoints" data-key="t-login" className={subMenuState['endpoints'] ? 'active' : ''} onClick={() => toggleSubMenu('/endpoints/endpoints')}>End Points</Link>
                                </li>

                            </ul>
                        </li>

                        <li className={`${menuState['others'] ? 'mm-active' : ''}`} onClick={() => toggleMainMenu('others')}>
                            <a href="#/" className="has-arrow waves-effect" aria-expanded={menuState['others']} >
                                <i className="mdi mdi-format-list-bulleted"></i>
                                <span data-key="t-charts">Others</span>
                            </a>
                            <ul className={menuState['others'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['others']}>
                                <li className={subMenuState['coming_soon'] ? 'mm-active' : ''}><Link to="/coming_soon" data-key="t-apex-charts" className={subMenuState['coming_soon'] ? 'active' : ''} onClick={() => toggleSubMenu('/coming_soon')}>User Documents</Link></li>
                                <li className={subMenuState['coming_soon'] ? 'mm-active' : ''}><Link to="/coming_soon" data-key="t-e-charts" className={subMenuState['coming_soon'] ? 'active' : ''} onClick={() => toggleSubMenu('/coming_soon')}>Promotional Offer</Link></li>
                                <li className={subMenuState['coming_soon'] ? 'mm-active' : ''}><Link to="/coming_soon" data-key="t-chartjs-charts" className={subMenuState['coming_soon'] ? 'active' : ''} onClick={() => toggleSubMenu('/coming_soon')}>Promotional Code</Link></li>
                            </ul>
                        </li>

                        <li className={menuState['manage_admin'] ? 'mm-active' : ''} onClick={() => toggleMainMenu('manage_admin')}>
                            <a href="#/" className="has-arrow waves-effect" aria-expanded={menuState['manage_admin']} >
                                <i className="bx bx-user"></i>
                                <span data-key="t-ui-elements">Manage Admin</span>
                            </a>
                            <ul className={menuState['manage_admin'] ? 'sub-menu mm-collapse mm-show' : 'sub-menu mm-collapse'} aria-expanded={menuState['manage_admin']}>
                                <li className={subMenuState['user_department'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/user_department" data-key="t-lightbox" className={subMenuState['user_department'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/user_department')}>User Departments</Link>
                                </li>  
                                <li className={subMenuState['user_logs_list'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/user_logs_list" data-key="t-lightbox" className={subMenuState['user_logs_list'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/user_logs_list')}>User Logs</Link>
                                </li>  
                                <li className={subMenuState['user_level'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/user_level" data-key="t-lightbox" className={subMenuState['user_level'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/user_level')}>User Level</Link>
                                </li>  
                                <li className={subMenuState['user_list'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/user_list" data-key="t-lightbox" className={subMenuState['user_list'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/user_list')}>User List</Link>
                                </li> 
                                <li className={subMenuState['user_menu'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/user_menu" data-key="t-lightbox" className={subMenuState['user_menu'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/user_menu')}>User Menu</Link>
                                </li> 
                                <li className={subMenuState['menu_permission'] ? 'mm-active' : ''}>
                                    <Link to="/manage_admin/menu_permission" data-key="t-lightbox" className={subMenuState['menu_permission'] ? 'active' : ''} onClick={() => toggleSubMenu('/manage_admin/menu_permission')}>Menu Permission</Link>
                                </li> 
                            </ul>
                        </li> */}

                    </ul>
                </div>
                {/* <!-- Sidebar --> */}
            </div>
        </div>
    );
}

export default Sidebar;
