import React, { useEffect, useState } from "react";
import Header from '../../../common/Header'
import Sidebar from '../../../common/Sidebar'
import Footer from '../../../common/Footer'
import PageHeader from '../../PageHeader'
import PaginationComponent from "../../PaginationComponent";
import axios from "axios"; 
import { Table } from 'react-bootstrap';
import { triggerAlert, handleTableRowClick, ConfirmationAlert, getToken , get_user_menu_permission,pageReload } from "../../../utils";
import Loader from '../../../common/Loader' 
import { useNavigate , Link } from 'react-router-dom'  
import {menuIdData} from '../../../utils/constants'
import DataTable from 'react-data-table-component';
import { useForm, Controller } from 'react-hook-form';
import { Modal, ModalBody } from "react-bootstrap";
import { Menu, MenuItem } from "@mui/material";
function UserLevel() {

    const api_url       = process.env.REACT_APP_API_CUSTOMER_URL;
	const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
    const mainHeading   = "User Level";
    const heading       = "Admin / User Level";
	const token         = getToken();
    const navigate      = useNavigate();

    const [isLoading, setIsLoading]         = useState();
    const [listData, setListData]           = useState([]); 
	const [selectedRow, setSelectedRow] 	= useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null); 
	const [pageCount, setPageCount]         = useState(0);
	const [pageSlNo, setPageSlNo]           = useState(0);
	const [currentPage, setCurrentPage]     = useState(1);
	const [perPageLimit, setPerPageLimit]   = useState(show50Entries);
	const [searchQuery, setSearchQuery]     = useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [Createrole, setCreaterole] = useState(false);
    const [selectedidAction, setselectedidAction] = useState('')
    const [selectedidData, setselectedidData] = useState({})
    // Menu Permissions
    const [addPermission, setAddPermission]         = useState(false);
    const [editPermission, setEditPermission]       = useState(false); 
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, control: controlForm, getValues: getValuesForm, watch } = useForm({
        defaultValues: {
            ismanager: "N"
        },
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event,row) => {
        setAnchorEl(event.currentTarget);
        setselectedidAction(row.id)
        setselectedidData(row)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (Createrole) {
            // Call handleClose function when modal is opened
            handleClose();
        }
    }, [Createrole]);

    const columns = [
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">S.No</label>, sortable: true, sortField: 'industry', cell: row => 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                    <span>{row.Num}</span>
                </div>
            </>,         
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Role</label>, sortable: true, sortField: 'current_last_positon',  cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                    {row.name}
                </div>
            </>,
        },
          
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Status</label>, sortable: true, selector:row =>row.is_deleted,  cell: row=> 
            <>
                 {row.is_deleted   ? (
                    <span className="btn btn-success btn-sm btn-rounded waves-effect waves-light">Active</span>
                ) : row.is_deleted ==false   ? (
                    <span className="btn btn-danger btn-sm btn-rounded waves-effect waves-light">Inactive</span>
                ) :  (
                    <span className="btn btn-info btn-sm btn-rounded waves-effect waves-light">Unknown</span>
                )}
            </>,
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Action</label>, sortable: true, selector:row =>row.is_deleted,  cell: row=> 
            <>
                {/* {customeredit && <div className="btn-group float-end col-md-1  ml-10">
                    <button type="button" className="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{"marginLeft": "10px"}}>
                        Action <i className="mdi mdi-chevron-down"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end  ">
                        <>
                        <a className="dropdown-item hand_icon" onClick={()=>{onloadroleinfo(row); setselectedid(row.id); setiseditedpage(true); setCreaterole(true); setmessge('Update')}}>Edit</a>
                        {row.is_deleted && customerdelete ? 
                        <a className="dropdown-item hand_icon" onClick={() =>  statusChange(!row.is_deleted, row.id)}  >  Deactivate</a> : customerdelete ? 
                        <a className="dropdown-item hand_icon" onClick={() =>  statusChange(!row.is_deleted, row.id)}>Activate</a> : ""}
                        </>
                        
                    </div>
                </div>} */}
              
              

              {
                        customeredit &&
                            <div>
                                <div
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(event)=>handleClick(event,row)}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-primary w-md btn-md dropdown-toggle"
                                        // data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{ "marginLeft": "10px" }}
                                    >
                                        Action
                                        <i className="mdi mdi-chevron-down"></i>
                                    </button>
                                </div>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        sx: {
                                            boxShadow: '0px 0px 0px 0px rgba(0,0,0,1), 0px 0px 0px 1px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
                                            border: '1px solid #e0e0e0', // Custom border color
                                            outline: 'none', // Remove the black border,        
                                            minWidth: '132px'

                                        },
                                        style: {
                                            border: '1px solid #fff', // Ensure border is applied
                                        }
                                    }}
                                >
                                        <MenuItem onClick={()=>{onloadroleinfo(selectedidData); setselectedid(selectedidAction); setiseditedpage(true); setCreaterole(true); setmessge('Update')}}>
                                            Edit
                                        </MenuItem>
                                    {
                                        selectedidData.is_deleted && customerdelete ? 
                                        <MenuItem onClick={() =>  statusChange(!selectedidData.is_deleted, selectedidAction)}>
                                            Deactivate
                                        </MenuItem>
                                        :
                                        customerdelete ?
                                        <MenuItem onClick={() =>  statusChange(!selectedidData.is_deleted, selectedidAction)}>
                                            Activate
                                        </MenuItem>
                                        :''
                                    }
                                </Menu>
                            </div>
                            }
            </>,
        },      
        
    ];
    
    const [iseditedpage, setiseditedpage] = useState(false)
    const [selectedid, setselectedid] = useState()
    const [messge, setmessge] = useState(false)
    const onloadroleinfo = (data) => {
        setValueForm('name', data.name)
    }
    const fetchListData = async (page,size=perPage, searchkey) => { 
        if(!searchkey){
            setIsLoading(true);
        }
        const per_page = perPageLimit; 
        let offcet = parseInt(page)-1 
        try { 

            setPageSlNo((page - 1) * per_page);

            const response      = await axios.get(`apiV1/role/?limit=${size}&offset=${offcet*perPage}${searchkey ? `&search=${searchkey}` : ""}`); 
            const response_data = response.data 

            if (response.status === 200) {  
                const itemsArray    = response_data.results ;  
                itemsArray.map((item, i)=>{
                    item.Num = i + 1;
                    return item
                })
                const total_pages   = response_data.count; 
                setTotalRows(total_pages);
                setListData(itemsArray); 
                setCurrentPage(page)
                setPerPage(size)
                setIsLoading(false);
            } else if (response.status === 204) {
                setListData([]); 
                setIsLoading(false); 
                setPageCount(0); 
            }
            else {
                setListData([]); 
                setIsLoading(false); 
                setPageCount(0); 
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) { 
            setListData([]); 
            setIsLoading(false); 
            setPageCount(0); 
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };
    const onSubmit = (data) => {
        if(iseditedpage){
            axios.patch(`/apiV1/role/${selectedid}/`, data).then((response) => {
                fetchListData(currentPage, perPage, searchQuery);
                setCreaterole(false)

                resetForm();
                triggerAlert('success', 'success', 'Data updated successfully');
            })
            .catch((error) => {
                triggerAlert('error','name role with this name already exists.');
            });
         
        }else{
            axios.post("/apiV1/role/", data).then((response) => {
                fetchListData(currentPage, perPage, searchQuery);
                setCreaterole(false)
                resetForm();
                triggerAlert('success', 'success', 'Data added successfully');
            })
        
            .catch((error) => {
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            });
        }
        
    }
    // Delete Trunk
    const statusChange =  (status, selectedid) => {  
        handleClose()
		ConfirmationAlert('Are you sure you want to continue!', 'Continue', async () => {
            const post_data = {
                "is_deleted": status
            }
            try { 
                const response = await axios.delete(`apiV1/role/${selectedid}/`, { data: post_data}); 
                
                const response_data = response.data;
                if (response_data.status_code === 200) {
                    triggerAlert('success', 'success', 'Status updated Successfully!!'); 
                    fetchListData(currentPage, perPage, searchQuery);
                
                } else {
                    triggerAlert('error', 'Oops...', 'Something went wrong..');
                }
            } catch (error) {
                triggerAlert('error', 'Oops...', 'Something went wrong..');
            }
        })
		
	};
 

    const handleKeySearch = (e) => { 
		setSearchQuery(e.target.value);
		const searchkey = e.target.value;
		fetchListData(currentPage,perPage, searchkey); // Update search results on every change
	};
    const access_privileges = JSON.parse(sessionStorage.getItem('modulepermissions'))
    const [customerlist, setcustomerlist] = useState(false)
    const [customeradd, setcustomeradd] = useState(false)
    const [customeredit, setcustomeredit] = useState(false)
    const [customerdelete, setcustomerdelete] = useState(false)
    // Menu Permissions
    useEffect(() => { 
        const menu_id = menuIdData.user_level;
        setAddPermission(get_user_menu_permission(menu_id,'add'));
        setEditPermission(get_user_menu_permission(menu_id,'edit')); 

        access_privileges.map((access, i)=>{
            access.sub_module_info.map((subaccess,k)=>{
                if(subaccess.name == "User Role" && subaccess.permissions.indexOf(1)!== -1){
                    setcustomerlist(true)
                }
                if(subaccess.name == "User Role" && subaccess.permissions.indexOf(2)!== -1){
                    setcustomeradd(true)
                }
                if(subaccess.name == "User Role" && subaccess.permissions.indexOf(3)!== -1){
                    setcustomeredit(true)
                }
                if(subaccess.name == "User Role" && subaccess.permissions.indexOf(4)!== -1){
                    setcustomerdelete(true)
                }

            })

            
        })
	}, []);


    const handlePageChange = page => {
        fetchListData(page, perPage, searchQuery);
        setCurrentPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchListData(page, newPerPage, searchQuery);
        setPerPage(newPerPage);
    };
    const handleChangeCheckBox = () => {

    }
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    useEffect(() => { 
		setCurrentPage(1);
		fetchListData(currentPage, perPage, searchQuery); // Fetch data for the initial page
	}, [perPageLimit]);
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */} 
                                <>
                                     
                                    <div className="row  ">
                                        <div className="col-12">
                                            <div className="card">
                                                {customeradd &&
                                                <div className="card-header">
                                                     
                                                    <div className="btn-group float-end col-md-1  ml-10">
                                                        <button type="button" className="btn btn-primary w-md btn-md  " onClick={()=>{setCreaterole(true); setiseditedpage(false);  setmessge('Create'); resetForm()}}  style={{"marginLeft": "10px"}}>
                                                            Create Role 
                                                        </button>
                                                    </div> 
                                                    
                                                </div> }
                                                <div className="card-body">
                                                    <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                        <div className="row">
															 
															<div className="col-sm-12 col-md-12 mb-2">
																<div className="btn-group float-end ">
																	<input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleKeySearch} />
																	&nbsp;&nbsp;
																</div>
															</div>
														</div>
                                                        {!isLoading ? 
                                                        <DataTable 
                                                            columns={columns} 
                                                            data={listData} 
                                                            pagination
                                                            paginationServer
                                                            fixedHeaderScrollHeight='600px'
                                                            fixedHeader
                                                            persistTableHead
                                                            paginationTotalRows={totalRows}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                            // selectableRows={selectedRows}
                                                            // onSelectedRowsChange={(e) => handleChangeCheckBox(e)}
                                                            clearSelectedRows={toggledClearRows}
                                                            paginationDefaultPage={currentPage}
                                                            paginationPerPage={perPage}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}
                                                            expandableRows
                                                            
                                                        />: 
                                                        <div className='loader-overlay text-white'>
                                                            <Loader />
                                                        </div>
                                                        }
                                                        
                                                    </div>

                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </> 



                        </div>  
                    </div>
                   
                    <Footer />
                </div>
                 
            </div>
            
            <div className="rightbar-overlay"></div>

            <Modal size='md' show={Createrole} onHide={() => setCreaterole(false)} className='  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">{messge} Role  </h1> </Modal.Header>
                <form onSubmit={handleSubmitForm(onSubmit)}>
                <ModalBody>
                    <div className="mb-3">
                        <label for="formrow-email-input" className="form-label">Name <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" {...registerForm('name', {
                            required: 'Firstname is required', pattern: {
                                value: /^[A-Za-z][A-Za-z\s]*$/,
                                message: 'Please enter only alphabets and ensure the first character is not a space',
                            }
                        })}  />
                        {errorsForm.name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.name.message}</div>}
                    </div>
                </ModalBody>
                <Modal.Footer>
                    <div className="py-2 mb-3">
                        <div className="row">
                            <div className="col-12 text-center">
                                <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setCreaterole(false); }} > Cancel </button>
                                <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" > Save </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default UserLevel;
