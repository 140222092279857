import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import Footer from "../../common/Footer";
import PageHeader from "../PageHeader";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import Loader from "../../common/Loader";
import { triggerAlert, getToken, simpleAlert } from "../../utils";
import { useForm, Controller } from "react-hook-form";
import React from "react";
function Revenue() {
  document.title = "Talent scan - Revenue"
  const api_url = process.env.REACT_APP_API_BASE_URL;

  const token = getToken();
  const mainHeading = "User Revenue";
  const heading = "Reports / User Revenue";
  const [isLoading, setIsLoading] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();

  // Fetch Data Api integration
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const startYear = 2023;
  const [years, setYears] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showTableall, setShowTableall] = useState(false);
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState([]);

  const handleYearChange = (event) => {
    setYears(event.target.value);
  };

  const fetchPromoData = async (e) => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        api_url + `monthwise_revenue_view?year=${years}`,
        token
      );

      const data = response.data.results;
      //console.log(data);
      setPosts(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  useEffect(() => {
    const generateYears = () => {
      let yearsArray = [];
      for (let year = currentYear; year >= startYear; year--) {
        yearsArray.push(year);
      }
      setYears(yearsArray);
    };

    generateYears();
  }, [currentYear, startYear]);

  const saveSubmit = async (data) => {
    // console.log("Selected year:", data.year);
    const year = data.year;

    if (data.year !== "") {
      try {
        setIsLoading(true);

        const response = await axios.get(`/apiV1/monthwise_revenue_view?year=${year}`,
          // api_url + `monthwise_revenue_view?year=${year}`,
          // token
        );

        // const response = await axios.get(`http://127.0.0.1:8000/payg_admin/monthwise_revenue_view?year=${year}`,token); //  monthly_revenue_view?year=2021

        // const response = await axios.get(`http://127.0.0.1:8000/payg_admin/monthwise_revenue_view?year=${year}`); //  monthly_revenue_view?year=2021
        const data = response.data.results.revenue_list;
        const data_total = response.data.results.total_revenue;
        const error = response.data.error_code;
        // console.log('data',data_total);
        // console.log('errorssdsdsd',error);
        if (response.data.error_code == "200") {
          setPosts(data);
          setTotal(data_total);
          setShowTable(true);

          setIsLoading(false);
        } else {
          setIsLoading(false);
          setShowTable(false);

          setPosts([]);
          setTotal([]);
          // triggerAlert("Error",'Oops...', 'Data Is Not Available');
        }
      } catch (error) {
        setIsLoading(false);
        setShowTable(false);

        setPosts([]);
        setTotal([]);
        // triggerAlert('error', 'Oops...', 'Something went wrong..');
      }
    } else {
      simpleAlert("Select the Year!!");
    }
  };

  const tableData = {}; // Object to store data for each month

  // Iterate over the posts to organize data by month
  Object.entries(posts).forEach(([year, data]) => {
    data.forEach((entry) => {
      const { month, monthly_revenue } = entry;
      if (!tableData[month]) {
        tableData[month] = {
          month,
          [year]: monthly_revenue,
        };
      } else {
        tableData[month][year] = monthly_revenue;
      }
    });
  });

  function getMonthName(monthNumber) {
    const date = new Date(2000, monthNumber - 1);
    return date.toLocaleString('default', { month: 'long' });
  }


  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        {isLoading && (
          <div className="loader-overlay text-white">
            <Loader />
          </div>
        )}
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageHeader mainHeading={mainHeading} heading={heading} />
              {/* end page title */}

              <div
                class="modal fade bs-example-modal-center"
                tabindex="-1"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">User Document</h5>
                      {/* <button class="btn btn-warning w-md me-md-2" type="button" data-bs-toggle="modal" data-bs-target=".bs-example-modal-center2">Create Revenue Name</button> */}
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row  mt-4">
                <div class="col-xl-12">
                  <div class="card-body">
                    <form
                      onSubmit={handleSubmit(saveSubmit)}
                      novalidate="novalidate"
                    >
                      <div class="row g-3" data-select2-id="20">
                        {/* <div class="col-md-2">
                                                        <label class="form-label" for="formtabs-first-name">Year</label>
                                                        <input class="form-control mb-1" type="number" id="dateFor" value={dateFor} onChange={(e) => { setDateFor(e.target.value); setDisplayError(false); }} />
                                                        {displayError && <div style={{ color: 'red' }}>Year is required</div>}
                                                    </div> */}

                        <div class="col-md-2">
                          <label htmlFor="year" class="form-label">
                            Select a Year:
                          </label>
                          <select
                            id="year"
                            name="year"
                            {...register("year", {
                              required: "Year is required",
                            })}
                            class="form-control"
                          >
                            <option value="">Select a year</option>
                            <option value="all">All</option>
                            {years &&
                              years.map((year, index) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                          </select>
                          {errors.year && (
                            <div
                              style={{
                                color: "red",
                                fontSize: "14px",
                                marginTop: "5px",
                              }}
                            >
                              {errors.year.message}
                            </div>
                          )}
                        </div>

                        <div class="col-md-4">
                          <label class="form-label" for="formtabs-last-name">
                            &nbsp;
                          </label>
                          <div class="button-items">
                            <button type="submit" class="btn btn-primary w-md">
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      {/* Render the table headers and body */}
                      {showTable && (
                        <table className="table-responsive table-bordered table" style={{ width: '100%' }}>
                          <thead style={{ backgroundColor: 'rgb(237, 237, 237)' }}>
                            <tr>
                              <th>ID</th>
                              <th>Month</th>
                              {Object.keys(posts).map((year) => (
                                <th key={year}>{year}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {Object.values(tableData).map((rowData, rowIndex) => (
                              <tr key={`row-${rowIndex}`}>
                                <td>{rowIndex + 1}</td> {/* Assuming ID starts from 1 */}
                                <td>{getMonthName(rowData.month)}</td> {/* Display month name instead of number */}
                                {Object.keys(posts).map((year) => (
                                  <td key={`${getMonthName(rowData.month)}-${year}`}>{rowData[year] || '---'}</td>
                                ))}
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={2} className="text-end">Revenue Yearly:</td>
                              {Object.entries(posts).map(([year, data]) => (
                                <td key={`revenue-${year}`}>
                                  {/* Calculate total revenue for each year */}
                                  {data.reduce((acc, curr) => (curr.monthly_revenue ? acc + parseInt(curr.monthly_revenue) : acc), 0)}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      )}
                      {!showTable && (
                        <table
                          className="table-responsive table-bordered table"
                          style={{ width: "100%" }}
                        >
                          <thead
                            style={{ backgroundColor: "rgb(237, 237, 237)" }}
                          >
                            <tr>
                              <th>ID</th>
                              <th>Month</th>
                            </tr>
                          </thead>
                          <tbody>
                            {" "}
                            <tr>
                              <td colSpan="9" className="text-center">No data available</td>
                            </tr>
                          </tbody>{" "}
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* container-fluid */}
          </div>
          {/* End Page-content */}
          <Footer />
        </div>
        {/* end main content*/}
      </div>
      {/* END layout-wrapper */}

      {/* Right bar overlay*/}
      <div className="rightbar-overlay"></div>
    </>
  );
}

export default Revenue;
