import React, { useEffect, useState } from "react";
import Header from '../../common/Header'
import Sidebar from '../../common/Sidebar'
import Footer from '../../common/Footer'
import PageHeader from '../../pages/PageHeader'
import axios from "axios";
import { triggerAlert, ConfirmationAlert } from '../../utils';
import Loader from '../../common/Loader'

import DataTable from 'react-data-table-component';
import { Modal, ModalBody } from "react-bootstrap";
import { useForm, } from 'react-hook-form';
import { Menu, MenuItem } from "@mui/material";
export default function Creditconfig() {
    document.title = "Talent scan - Credit Config"
    const mainHeading = "Creditconfig List";
    const heading = "Settings / Creditconfig List";
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, clearErrors, setError, trigger } = useForm();
    const [isEditing, setIsEditing] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const fetchListData = async () => {
        setIsLoading(true);
        try {
            let token = sessionStorage.getItem("admin_user_token")
            const response = await axios.get(`/apiV1/create-pricing-conf/`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const response_data = response.data
            if (response.status === 200) {
                setIsEditing(false)
                const itemsArray = response_data.results;
                itemsArray.map((item, i) => {
                    item.Num = i + 1;
                    return item
                })
                setValueForm("per_resume_cost", response_data.results[0].per_resume_cost)
                setValueForm("per_test_cost", response_data.results[0].per_test_cost)
                setValueForm("per_user_cost", response_data.results[0].per_user_cost)
                setIsLoading(false);
            } else if (response.status === 204) {
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
        }
    };


    const onSubmit = (data) => {
        data.updated_by = sessionStorage.getItem('admin_id')
        axios.post(`/apiV1/create-pricing-conf/`, data).then((response) => {
            fetchListData(1);
            resetForm();
            triggerAlert('success', 'success', 'Data updated successfully');
        })
            .catch((error) => {
                triggerAlert('error', 'Oops...', error.response.data.non_field_errors);
            });

    };
    const access_privileges = JSON.parse(sessionStorage.getItem('modulepermissions'))
    const [customerlist, setcustomerlist] = useState(false)
    const [customeradd, setcustomeradd] = useState(false)
    const [customeredit, setcustomeredit] = useState(false)
    const [customerdelete, setcustomerdelete] = useState(false)
    useEffect(() => {
        fetchListData(1);
        access_privileges.map((access, i) => {
            access.sub_module_info.map((subaccess, k) => {
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(1) !== -1) {
                    setcustomerlist(true)
                }
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(2) !== -1) {
                    setcustomeradd(true)
                }
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(3) !== -1) {
                    setcustomeredit(true)
                }
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(4) !== -1) {
                    setcustomerdelete(true)
                }

            })


        })
    }, []);
    const handlecloseedit = () => {
        setIsEditing(false)
        clearErrors("per_user_cost")
        clearErrors("per_test_cost")
        clearErrors("per_resume_cost")
    }
    const validateNumber = (e) => {
        const { name, value } = e.target;
        if (value === '') {
            setError(name, {
                type: 'manual',
                message: `${name} is required`,
            });
        } else if (/^\d*$/.test(value)) {
            clearErrors(name);
        } else {
            setError(name, {
                type: 'manual',
                message: 'Please enter only numbers',
            });
        }
    };
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>
                                <div className="row">
                                    <form onSubmit={handleSubmitForm(onSubmit)}>
                                        <div className="d-flex justify-content-end mb-3">
                                            {!isEditing && (
                                                <button className="btn btn-dark radius-md rounded-circle" onClick={() => setIsEditing(true)}>
                                                    <i className="bx bx-edit" style={{ fontSize: "20px", marginTop: "3px" }}></i>
                                                </button>
                                            )}
                                        </div>

                                        <div className="d-flex">
                                            <div className="form-group col-md-3 mb-3">
                                                <div className="mb-3">
                                                    <label htmlFor="per_user_cost" className="form-label">
                                                        Per User Cost(USD)<span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...registerForm('per_user_cost', {
                                                            required: 'Per User Cost is required',
                                                            pattern: {
                                                                value: /^\d+(\.\d{1,2})?$/, 
                                                                message: 'Please enter a valid number',
                                                            },
                                                        })}
                                                        name="per_user_cost"
                                                        disabled={!isEditing}
                                                        onKeyUp={() => {
                                                            trigger("per_user_cost");
                                                        }}
                                                    />
                                                    {errorsForm.per_user_cost && (
                                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                            {errorsForm.per_user_cost.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-3 ms-3">
                                                <label htmlFor="formrow-email-input" className="form-label">
                                                    Per Test Cost(USD) <span className="text-danger">*</span>
                                                </label>
                                                <input type="text" className="form-control"
                                                    {...registerForm('per_test_cost', {
                                                        required: 'Per Test Cost is required',
                                                        pattern: {
                                                            value: /^\d+(\.\d{1,2})?$/, 
                                                            message: 'Please enter only numbers',
                                                        }
                                                    })}
                                                    name="per_test_cost"
                                                    disabled={!isEditing}
                                                    onKeyUp={() => {
                                                        trigger("per_test_cost");
                                                    }}
                                                />
                                                {errorsForm.per_test_cost && (
                                                    <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.per_test_cost.message}</div>
                                                )}
                                            </div>

                                            <div className="form-group col-md-3 ms-3">
                                                <label htmlFor="formrow-email-input" className="form-label">
                                                    Per Resume Cost(USD) <span className="text-danger">*</span>
                                                </label>
                                                <input type="text" className="form-control"
                                                    {...registerForm('per_resume_cost', {
                                                        required: 'Per Resume Cost is required',
                                                        pattern: {
                                                            value: /^\d+(\.\d{1,2})?$/,
                                                            message: 'Please enter only numbers',
                                                        }
                                                    })}
                                                    name="per_resume_cost"
                                                    disabled={!isEditing} 
                                                    onKeyUp={() => {
                                                        trigger("per_resume_cost");
                                                    }}
                                                />
                                                {errorsForm.per_resume_cost && (
                                                    <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.per_resume_cost.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        {isEditing && (
                                            <div className="py-2 mt-3">
                                                <div className="row">
                                                    <div className="col-12 text-end">
                                                        <button className='btn btn-warning w-md btn-md me-2' type="button" onClick={() => { handlecloseedit(); fetchListData(1); }}>Cancel</button>
                                                        <button className='btn btn-primary w-md btn-md' type="submit" disabled={!isEditing} >Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </form>
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}

        </>
    )

}
