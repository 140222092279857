import React, { useEffect, useState } from "react";
import Header from '../../common/Header'
import Sidebar from '../../common/Sidebar'
import Footer from '../../common/Footer'
import PageHeader from '../../pages/PageHeader'
import axios from "axios";
import { triggerAlert, ConfirmationAlert } from '../../utils';
import Loader from '../../common/Loader'

import DataTable from 'react-data-table-component';
import { Modal, ModalBody } from "react-bootstrap";
import { useForm, } from 'react-hook-form';
import { Menu, MenuItem } from "@mui/material";
export default function Buycredit() {
    document.title = "Talent scan - Buy Additional Credits"
    const mainHeading = "BuyAdditional CreditList";
    const heading = "Settings / BuyAdditional CreditList";
    const [isLoading, setIsLoading] = useState(true);
    const [listData, setListData] = useState([]);
    const [SuccessAlert, setSuccessAlert] = useState(false);
    const [CreatenewUser, setCreatenewUser] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [editid, seteditid] = useState('')
    const [editidData, seteditidData] = useState({})
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedid, setselectedid] = useState('')
    const [textbase, settextbase] = useState("Create")
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, setError,clearErrors,trigger} = useForm();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event,row) => {
        setAnchorEl(event.currentTarget);
        setselectedid(row.id)
        seteditidData(row)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (CreatenewUser) {
            // Call handleClose function when modal is opened
            handleClose();
        }
    }, [CreatenewUser]);

    const columns = [
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">S.No</label>, sortable: true, selector: row => row.Num, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                        <span>{row.Num}</span>
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Plan Name</label>, sortable: true, selector: row => row.plan_name, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.plan_name}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">No. of Resumes</label>, sortable: true, selector: row => row.no_of_resumes, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.no_of_resumes}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">No of Users</label>, sortable: true, selector: row => row.no_of_users, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.no_of_users}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">No of Tests</label>, sortable: true, selector: row => row.no_of_tests, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.no_of_tests}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Price </label>, sortable: true, selector: row => row.price, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.price}
                    </div>
                </>,
        },

        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Status</label>, sortable: true, selector: row => row.is_deleted, cell: row =>
                <>
                    {row.is_deleted ? (
                        <span className="btn btn-danger btn-sm btn-rounded waves-effect waves-light">Inactive</span>

                    ) : row.is_deleted == false ? (
                        <span className="btn btn-success btn-sm btn-rounded waves-effect waves-light">Active</span>
                    ) : (
                        <span className="btn btn-info btn-sm btn-rounded waves-effect waves-light">Unknown</span>
                    )}
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Action</label>, sortable: true, selector: row => row.is_deleted, cell: row =>
                <>
                    {/* {customeredit || customerdelete ? <div className="btn-group float-end col-md-1  ml-10">
                        <button type="button" className="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ "marginLeft": "10px" }}>
                            Action <i className="mdi mdi-chevron-down"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end  ">
                        {customeredit  && <div className="dropdown-item hand_icon" onClick={() => { seteditid(row.id); settextbase("Update"); setCreatenewUser(true);  onloadeditedData(row);}}>Edit</div>}
                            {customerdelete && <div className="dropdown-item hand_icon" onClick={() => statusChange(!row.is_deleted, row.id)}>Delete</div>}
                        </div>
                    </div> : ""} */}

                    {
                        customeredit || customerdelete ?
                            <div>
                                <div
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(event)=>handleClick(event,row)}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-primary w-md btn-md dropdown-toggle"
                                        // data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{ "marginLeft": "10px" }}
                                    >
                                        Action
                                        <i className="mdi mdi-chevron-down"></i>
                                    </button>
                                </div>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        sx: {
                                            boxShadow: '0px 0px 0px 0px rgba(0,0,0,1), 0px 0px 0px 1px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
                                            border: '1px solid #e0e0e0', // Custom border color
                                            outline: 'none', // Remove the black border,        
                                            minWidth: '132px'

                                        },
                                        style: {
                                            border: '1px solid #fff', // Ensure border is applied
                                        }
                                    }}
                                >
                                    {
                                        customeredit &&
                                        <MenuItem onClick={() => { seteditid(selectedid); settextbase("Update"); setCreatenewUser(true); onloadeditedData(editidData); }}>
                                            Edit
                                        </MenuItem>

                                    }
                                    {
                                        customerdelete &&
                                        <MenuItem onClick={() => statusChange(!row.is_deleted, selectedid)}>
                                            Delete
                                        </MenuItem>

                                    }
                                </Menu>
                            </div>
                            : ""}

                </>,
        },



    ];
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const handleKeySearch = (e) => {
        setCurrentPage(1);
        setPerPage(10);
        setSearchQuery(e.target.value);
        const searchkey = e.target.value;
        fetchListData(1, perPage, searchkey);
    };
    const onloadeditedData = (data) => {
        setValueForm("plan_name", data.plan_name)
        setValueForm("no_of_users", data.no_of_users)
        setValueForm("no_of_tests", data.no_of_tests)
        setValueForm("no_of_resumes", data.no_of_resumes)
        setValueForm("no_of_users", data.no_of_users)
        setValueForm("price", data.price)

    }
    const fetchListData = async (page, page_size = perPage, search = searchQuery) => {
        setIsLoading(true);
        let offcet = parseInt(page) - 1
        try {
            let token =sessionStorage.getItem("admin_user_token")
            const response = await axios.get(`/apiV1/additional-unit-plan/?is_deleted=false${search ? `&search=${search}` : ""}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const response_data = response.data
            console.log(response_data);
            
            if (response.status === 200) {
                const itemsArray = response_data.results;
                itemsArray.map((item, i) => {
                    item.Num = i + 1;
                    return item
                })
                const total_pages = response_data.count;
                setTotalRows(total_pages);
                setListData(itemsArray);
                setIsLoading(false);
            } else if (response.status === 204) {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            }
            else {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            }

        } catch (error) {
            setListData([]);
            setIsLoading(false);
            setTotalRows(0);
        }
    };

    const statusChange = (status, id) => {
        handleClose()
        ConfirmationAlert('Are you sure you want to continue!', 'Continue', async () => {
            const post_data = {
                "is_deleted": status
            }
            try {
                const response = await axios.delete(`/apiV1/additional-unit-plan/${id}/`);
                const response_data = response.data;
                if (response_data.status_code === 200) {
                    triggerAlert('success', 'success', 'Data deleted Successfully!!');
                    fetchListData(1);
                } else {
                    triggerAlert('error', 'Oops...', 'Something went wrong..');
                }
            } catch (error) {
                triggerAlert('error', 'Oops...', 'Something went wrong..');
            }
        })

    };
    const onSubmit = (data) => {
        if (editid) {
            data.updated_by = sessionStorage.getItem('admin_id')
            axios.patch(`/apiV1/additional-unit-plan/${editid}/`, data).then((response) => {
                fetchListData(1);
                setCreatenewUser(false)
                resetForm();
                triggerAlert('success', 'success', 'Data updated successfully');
            })
                .catch((error) => {
                    triggerAlert('error', 'Oops...', error.response.data.message);
                });
        } else {
            axios.post("/apiV1/additional-unit-plan/", data).then((response) => {
                console.log(response.data);
                
                handleClose()
                fetchListData(1);
                setCreatenewUser(false)
                resetForm();
                triggerAlert('success', 'success', 'Data added successfully');
            })
            .catch((error) => {
                triggerAlert('error', 'Oops...', error.response.data.message);
            });
        }

    };
    const handlePageChange = page => {
        fetchListData(page);
        setCurrentPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchListData(page, newPerPage, searchQuery);
        setPerPage(newPerPage);
    };
    const access_privileges = JSON.parse(sessionStorage.getItem('modulepermissions'))
    const [customerlist, setcustomerlist] = useState(false)
    const [customeradd, setcustomeradd] = useState(false)
    const [customeredit, setcustomeredit] = useState(false)
    const [customerdelete, setcustomerdelete] = useState(false)
    useEffect(() => {
        fetchListData(1);
        access_privileges.map((access, i) => {
            access.sub_module_info.map((subaccess, k) => {
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(1) !== -1) {
                    setcustomerlist(true)
                }
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(2) !== -1) {
                    setcustomeradd(true)
                }
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(3) !== -1) {
                    setcustomeredit(true)
                }
                if (subaccess.name == "Plans" && subaccess.permissions.indexOf(4) !== -1) {
                    setcustomerdelete(true)
                }

            })


        })
    }, []);
    const validateNumber = (e) => {
        const { name, value } = e.target;

        if (value === '') {
            setError(name, {
                type: 'manual',
                message: `${name} is required`,
            });
        } else if (/^\d*$/.test(value)) {
            clearErrors(name);
        } else {
            setError(name, {
                type: 'manual',
                message: 'Please enter only numbers',
            });
        }
    };
    const handleModalclose = () => {
        setCreatenewUser(false)
        clearErrors("plan_name")
        clearErrors("no_of_users")
        clearErrors("no_of_tests")
        clearErrors("no_of_resumes")
        clearErrors("price")
    }
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>

                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                {customeradd &&
                                                    <div className="btn-group float-end col-md-2  ml-10">
                                                        <button type="button" className="btn btn-primary w-md    btn-md" onClick={() => { setCreatenewUser(true); settextbase("Create"); seteditid(); resetForm(); }} style={{ "marginLeft": "10px" }}>
                                                         Create Credit Plan
                                                        </button>

                                                    </div>}

                                            </div>
                                            <div className="card-body">
                                                <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12 mb-2">
                                                            <div className="btn-group float-end ">
                                                                <input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleKeySearch} />
                                                                &nbsp;&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {!isLoading ?
                                                        <DataTable
                                                            columns={columns}
                                                            data={listData}
                                                            pagination
                                                            paginationServer
                                                            fixedHeaderScrollHeight='600px'
                                                            fixedHeader
                                                            persistTableHead
                                                            paginationTotalRows={totalRows}
                                                            paginationComponentOptions={paginationComponentOptions}

                                                            paginationDefaultPage={currentPage}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}


                                                        /> :
                                                        <div className='loader-overlay text-white'>
                                                            <Loader />
                                                        </div>
                                                    }
                                                    <hr />
                                                </div>

                                            </div>
                                        </div>
                                    </div> {/* <!-- end col -->*/}
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}

            <div className="rightbar-overlay"></div>
            <Modal size='md' show={CreatenewUser} onHide={() => setCreatenewUser(false)} className='  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton onClick={handleModalclose}> <h1 className="modal-title fs-5 ">{textbase} Credit Plan  </h1> </Modal.Header>

                <form onSubmit={handleSubmitForm(onSubmit)}>
                    <ModalBody>
                        <div className="row">
                            <div className="mb-3">
                                <label for="formrow-email-input" className="form-label">Plan Name <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" {...registerForm('plan_name', {
                                    required: 'Plan Name is required',  pattern: {
                                        value: /^[A-Za-z0-9][A-Za-z0-9_\-\s]*$/,
                                        message: 'Please ensure the first character is not a space, and only alphanumeric characters, spaces, underscores, and hyphens are allowed',
                                      },
                                })} name="plan_name" />
                                {errorsForm.plan_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.plan_name.message}</div>}
                            </div>
                            <div className="mb-3">
                                <label for="formrow-email-input" className="form-label">No of Users <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" {...registerForm('no_of_users', {
                                    required: 'No of Users is required', pattern:
                                    {
                                        value: /^\d+$/,
                                        message: 'Please enter only numbers',
                                    }
                                })} name="no_of_users" onKeyUp={validateNumber} />
                                {errorsForm.no_of_users && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.no_of_users.message}</div>}
                            </div>
                            <div className="mb-3">
                                <label for="formrow-email-input" className="form-label">No of Tests <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" {...registerForm('no_of_tests', {
                                    required: 'No of tests is required', pattern:
                                    {
                                        value: /^\d+$/,
                                        message: 'Please enter only numbers',
                                    }
                                })} name="no_of_tests" onKeyUp={validateNumber}/>
                                {errorsForm.no_of_tests && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.no_of_tests.message}</div>}
                            </div>
                            <div className="mb-3">
                                <label for="formrow-email-input" className="form-label">No of Resumes <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" {...registerForm('no_of_resumes', {
                                    required: 'No of Resumes is required', pattern:
                                    {
                                        value: /^\d+$/,
                                        message: 'Please enter only numbers',
                                    }
                                })} name="no_of_resumes" onKeyUp={validateNumber} />
                                {errorsForm.no_of_resumes && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.no_of_resumes.message}</div>}
                            </div>
                            <div className="mb-3">
                                <label for="formrow-email-input" className="form-label">Price <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" {...registerForm('price', {
                                    required: 'Price is required', pattern:
                                    {
                                        value: /^\d+(\.\d{1,2})?$/,
                                        message: 'Please enter only numbers',
                                      }                                      
                                })} name="price" onKeyUp={()=>trigger("price")} />
                                {errorsForm.price && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.price.message}</div>}
                            </div>
                        </div>



                    </ModalBody>
                    <Modal.Footer>
                        <div className="py-2 mb-3">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setCreatenewUser(false);handleModalclose() }} > Cancel </button>
                                    <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn"  > Save </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </form>
            </Modal>
        </>
    )

}
