import React, { useEffect, useState } from "react";
import Header from '../../common/Header'
import Sidebar from '../../common/Sidebar'
import Footer from '../../common/Footer'
import PageHeader from '../../pages/PageHeader'
import axios from "axios";
import { triggerAlert, ConfirmationAlert, getToken, get_user_menu_permission, pageReload } from '../../utils';
import Loader from '../../common/Loader'
import { useNavigate, Link } from 'react-router-dom'
import { menuIdData } from '../../utils/constants'
import DataTable from 'react-data-table-component';
import { Modal, ModalBody } from "react-bootstrap";
import { useForm, Controller } from 'react-hook-form';
import AutoSearchSelect from '../CommonSelectDynamic'
import moment from "moment-timezone";
import { Menu, MenuItem } from "@mui/material";
export default function CustomersListNew() {
    document.title = "Talent scan - CustomerList"
    const mainHeading = "Customers List";
    const heading = "Customers / Customers List";
    const [isLoading, setIsLoading] = useState(true);
    const [listData, setListData] = useState([]);
    const [SuccessAlert, setSuccessAlert] = useState(false);
    const [CreatenewUser, setCreatenewUser] = useState(false);
    const [EditnewUser, setEditnewUser] = useState(false);
    const [totalRows, setTotalRows] = useState('');
    const [selectedid, setselectedid] = useState('')
    const [selectedidData, setselectedidData] = useState({})
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [editid, seteditid] = useState('')
    const [searchQuery, setSearchQuery] = useState('');
    const [customerfilter, setcustomerfilter] = useState(0);
    const [textbase, settextbase] = useState("Create")
    const [products, setProducts] = useState([]);
    const access_privileges = JSON.parse(sessionStorage.getItem('modulepermissions'))
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, control: controlForm, getValues: getValuesForm, watch, trigger: triggerForm } = useForm();
    const { register: registerEdit, handleSubmit: handleSubmitEdit, formState: { errors: errorsEdit }, setValue: setValueEdit, reset: resetEdit, control: controlEdit, getValues: getValuesEdit, watch: watchEdit, trigger: triggerEdit } = useForm();
    const { register, handleSubmit, getValues } = useForm();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);

        setselectedid(row.id)
        setselectedidData(row)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const columns = [
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">S.No</label>, sortable: true, selector: row => row.Num, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                        <span>{row.Num}</span>
                    </div>
                </>,
            width: '100px',
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Contact Person Name</label>, sortable: true, selector: row => row.contact_person, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.contact_person}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Company Name</label>, sortable: true, selector: row => row.cmp_name, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.cmp_name}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Billing Address</label>, sortable: true, selector: row => row.location, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.location === "NULL" ? "-" : row.location}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Phone</label>, sortable: true, selector: row => row.phone, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.phone}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Email</label>, sortable: true, selector: row => row.corp_email, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.corp_email}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Registered Date</label>, sortable: true, selector: row => row.created_at, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {moment(row.created_at).format('DD-MM-YYYY')}
                    </div>
                </>,
        },

        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Activation Mail</label>, sortable: true, selector: row => row.is_activation_mail_sent, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.is_activation_mail_sent ? "Sent" : "Not Sent"}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Is Authenticated</label>, sortable: true, selector: row => row.is_authenticated, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.is_authenticated ? "Yes" : "No"}
                    </div>
                </>,
            width: '140px',
        },
        // {
        //     name: <label className="DarkBlue4_Color font_weight_500 font_14">Company Website</label>, sortable: true, selector: row => row.cmp_website, cell: row =>
        //         <>
        //             <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //                 {row.cmp_website}
        //             </div>
        //         </>,
        // },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Lead generated from</label>, sortable: true, selector: row => row.lead_generated_from, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.lead_generated_from}
                    </div>
                </>,
        },
        // {
        //     name: <label className="DarkBlue4_Color font_weight_500 font_14">Tenant Type</label>, sortable: true, selector: row => row.tenant_type, cell: row =>
        //         <>
        //             <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //                 {row.tenant_type}
        //             </div>
        //         </>,
        // },
        // {
        //     name: <label className="DarkBlue4_Color font_weight_500 font_14">Subscription Start Date</label>, sortable: true, selector: row => row.subs_start_date, cell: row =>
        //         <>
        //             <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //                 {row.subs_start_date}
        //             </div>
        //         </>,
        // },
        // {
        //     name: <label className="DarkBlue4_Color font_weight_500 font_14">Subscription End Date</label>, sortable: true, selector: row => row.subs_end_date, cell: row =>
        //         <>
        //             <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //                 {row.subs_end_date}
        //             </div>
        //         </>,
        // },
        // {
        //     name: <label className="DarkBlue4_Color font_weight_500 font_14">Subscription Renew Date</label>, sortable: true, selector: row => row.subs_renew_date, cell: row =>
        //         <>
        //             <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //                 {row.subs_renew_date}
        //             </div>
        //         </>,
        // },
        // {
        //     name: <label className="DarkBlue4_Color font_weight_500 font_14">License Count</label>, sortable: true, selector: row => row.license_count, cell: row =>
        //         <>
        //             <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //                 {row.license_count}
        //             </div>
        //         </>,
        // },
        // {
        //     name: <label className="DarkBlue4_Color font_weight_500 font_14">Domain</label>, sortable: true, selector: row => row.domain, cell: row =>
        //         <>
        //             <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //                 {row.domain}
        //             </div>
        //         </>,
        // },
        // {
        //     name: <label className="DarkBlue4_Color font_weight_500 font_14">Product</label>, sortable: true, selector: row => row.product_name, cell: row =>
        //         <>
        //             <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //                 {row.product_name}
        //             </div>
        //         </>,
        // },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Subscription Plan</label>, sortable: true, selector: row => row.subscription_plan_name, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.subscription_plan_name}
                    </div>
                </>,
        },



        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Status</label>, sortable: true, selector: row => row.current_setup_status, cell: row =>
                <>
                    {row.is_deleted ? (
                        <span className="btn btn-danger btn-sm btn-rounded waves-effect waves-light">Inactive</span>

                    ) : row.is_deleted == false ? (
                        <span className="btn btn-success btn-sm btn-rounded waves-effect waves-light">Active</span>
                    ) : (
                        <span className="btn btn-info btn-sm btn-rounded waves-effect waves-light">Unknown</span>
                    )}
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Action</label>, sortable: true, selector: row => row.is_deleted, cell: row =>
                <>
                    {/* <div className="btn-group float-end col-md-1  ml-10">
                        <button type="button" className="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ "marginLeft": "-5px" }}>
                            Action <i className="mdi mdi-chevron-down"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end  ">

                            {customeredit ? <div className="dropdown-item hand_icon" onClick={() => { seteditid(row.id); settextbase("Update"); setEditnewUser(true); onloadeditedData(row); }}>Edit</div> : ""}


                            {row.is_deleted ?
                                <div className="dropdown-item hand_icon" onClick={() => statusChange(0, row.id)}>Activate</div>
                                : <div className="dropdown-item hand_icon" onClick={() => statusChange(1, row.id)}  >Deactivate  </div>
                            }

                        </div>
                    </div> */}

                    <div>
                        <div
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, row)}
                        >
                            <button
                                type="button"
                                className="btn btn-primary w-sm btn-md dropdown-toggle"
                                // data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            // style={{ "marginLeft": "10px" }}
                            >
                                Action
                                <i className="mdi mdi-chevron-down"></i>
                            </button>
                        </div>
                        <Menu
                            className="actionSubMenu"
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                sx: {
                                    boxShadow: '0px 0px 0px 0px rgba(0,0,0,1), 0px 0px 0px 1px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
                                    border: '1px solid #e0e0e0', // Custom border color
                                    outline: 'none', // Remove the black border,        
                                    minWidth: '134px',

                                },
                                style: {
                                    border: '1px solid #fff', // Ensure border is applied
                                }
                            }}
                        >
                            {
                                customeredit ?
                                    <MenuItem onClick={() => { seteditid(selectedid); handleClose(); settextbase("Update"); setEditnewUser(true); onloadeditedData(selectedidData); }}>
                                        Edit
                                    </MenuItem>
                                    : ""
                            }
                            {customerdelete && selectedidData && (
                                selectedidData.is_deleted ? (
                                    <MenuItem onClick={() => statusChange(0, selectedid)}>
                                        Activate
                                    </MenuItem>
                                ) : (
                                    <MenuItem onClick={() => statusChange(1, selectedid)}>
                                        Deactivate
                                    </MenuItem>
                                )
                            )}
                        </Menu>
                    </div>
                </>
        },
    ];

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const handleKeySearch = (e) => {
        setCurrentPage(1);
        setSearchQuery(e.target.value);
        const searchkey = e.target.value;
        console.log(searchkey);
        fetchListData(1, perPage, searchkey, customerfilter);
    };
    const onloadeditedData = (data) => {
        setValueEdit("cmp_name", data.cmp_name)
        setValueEdit("contact_person", data.contact_person)
        setValueEdit("subscription", data.subscription)
        setValueEdit("user_type", data.user_type)
        if (data.billing_address) {
            setValueEdit('address_line_1', data.billing_address.address_line_1)
            setValueEdit('address_line_2', data.billing_address.address_line_2)
            setValueEdit('city', data.billing_address.city)
            setValueEdit('state', data.billing_address.state)
            setValueEdit('country', data.billing_address.country)
            setValueEdit('postal_code', data.billing_address.postal_code)
        }
        else {
            setValueEdit('address_line_1', '')
            setValueEdit('address_line_2', '')
            setValueEdit('city', '')
            setValueEdit('state', '')
            setValueEdit('country', '')
            setValueEdit('postal_code', '')
        }
        setValueEdit("phone", data.phone)
        setValueEdit("designation", data.designation)
        setValueEdit("location", data.location)
        setValueEdit("lead_generated_from", data.lead_generated_from || "")
        setValueEdit("category", data.category)
        setValueEdit("company_size", data.company_size)

    }

    const {
        register: registerAdd,
        formState: { errors },
    } = useForm();
    const fetchListData = async (page, size = perPage, searchkey, status = customerfilter) => {
        setIsLoading(true);
        try {
            const searchQuery = searchkey ? `&search=${searchkey}` : "";
            console.log(searchQuery);
            const response = await axios.get(`apiV1/active-customers/?limit=${size}&offset=${(page - 1) * 10}${status == 1 ? `&is_deleted=false` : status == 2 ? `&is_deleted=true` : ""}${searchQuery}`);
            const response_data = response.data;
            console.log(response_data);
            if (response.status === 200) {
                const itemsArray = response_data.results;
                itemsArray.map((item, i) => {
                    item.Num = i + 1;
                    return item;
                });
                const total_pages = response_data.count;
                setTotalRows(total_pages);
                setPerPage(size);
                setCurrentPage(page);
                setListData(itemsArray);
                setIsLoading(false);
            } else if (response.status === 204) {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            } else {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            }
        } catch (error) {
            setListData([]);
            setIsLoading(false);
            setTotalRows(0);
        }
    };
    const [customerCount, setcustomerCount] = useState({})
    const fetchAllCountsinCustomers = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`apiV1/live-customers/`);
            const response_data = response.data
            if (response.status === 200) {
                const itemsArray = response_data;
                setcustomerCount(itemsArray)
            }

        } catch (error) {

        }
    };

    const statusChange = (status, id) => {
        handleClose()
        ConfirmationAlert('Are you sure you want to continue!', 'Continue', async () => {
            const post_data = {
                "is_deleted": status
            }
            var form_data = new FormData();

            for (var key in post_data) {
                form_data.append(key, post_data[key]);
            }
            try {
                const response = await axios.patch(`apiV1/create-customer/${id}/`, form_data);
                const response_data = response.data;
                if (response_data) {
                    triggerAlert('success', 'success', 'Status updated Successfully!!');
                    fetchListData(currentPage, perPage, searchQuery);
                    fetchAllCountsinCustomers()
                } else {
                    triggerAlert('error', 'Oops...', 'Something went wrong..');
                }
            } catch (error) {
                triggerAlert('error', 'Oops...', 'Something went wrong..');
            }
        })

    };
    const onSubmitEdit = (data) => {
        var form_data = new FormData();
        setIsLoading(true);
        for (var key in data) {
            form_data.append(key, data[key]);
        }
        axios.patch(`apiV1/create-customer/${editid}/`, form_data).then((response) => {
            if (response.data) {
                let formdataadd = new FormData();
                formdataadd.append('postal_code', data.postal_code)
                formdataadd.append('country', data.country)
                formdataadd.append('address_line_2', data.address_line_2)
                formdataadd.append('phone_number', data.phone)
                formdataadd.append('address_type', "BILLING")
                formdataadd.append('address_line_1', data.address_line_1)
                formdataadd.append('customer', editid)
                formdataadd.append('city', data.city)
                formdataadd.append('state', data.state)

                axios.post(`apiV1/add-customer-address/`, formdataadd).then((response) => {
                    fetchListData(currentPage, perPage, searchQuery);
                    setEditnewUser(false)
                    resetForm();
                    setIsLoading(false);
                    triggerAlert('success', 'success', 'Data updated successfully');
                })
                    .catch((error) => {

                    });
            }


        })
            .catch((error) => {

                if (error.response.data.message) {
                    triggerAlert('error', 'Oops...', error.response.data.message);
                } else {
                    triggerAlert('error', 'Oops...', 'Something went wrong here..');
                }

            });
    }
    const onSubmit = (data) => {
        data.corp_email = data.corp_email.toLowerCase();
        var form_data = new FormData();
        setIsLoading(true);
        // Append all data keys to form_data
        for (var key in data) {
            form_data.append(key, data[key]);
        }
        form_data.append('is_activation_mail_sent', true);
        
        axios.post("apiV1/create-customer/", form_data)
            .then((response) => {
                if (response.data.status_code !== 400) {
                    let formdataadd = new FormData();
                    formdataadd.append('postal_code', data.postal_code);
                    formdataadd.append('country', data.country);
                    formdataadd.append('address_line_2', data.address_line_2);
                    formdataadd.append('phone_number', data.phone);
                    formdataadd.append('address_type', "BILLING");
                    formdataadd.append('address_line_1', data.address_line_1);
                    formdataadd.append('customer', response.data.data.id);
                    formdataadd.append('city', data.city);
                    formdataadd.append('state', data.state);


                    // Add is_activation_mail_sent to formdataadd
                    formdataadd.append('is_activation_mail_sent', true);

                    axios.post(`apiV1/add-customer-address/`, formdataadd)
                        .then((response) => {
                            fetchListData(currentPage, perPage, searchQuery);
                            setCreatenewUser(false);
                            setIsLoading(false);
                            resetForm();
                            triggerAlert('success', 'success', 'Data added successfully');
                        })
                        .catch((error) => {
                            console.error('Error adding customer address:', error);
                            triggerAlert('error', 'Oops...', 'Failed to add customer address.');
                        });
                } else {
                    if (response.data.message) {
                        triggerAlert('error', 'Oops...', response.data.message);
                        setIsLoading(false);
                        
                    } else {
                        triggerAlert('error', 'Oops...', 'Something went wrong here..');
                        setIsLoading(false);
                    }
                }
            })
            .catch((error) => {
                console.log('Error creating customer:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    triggerAlert('error', 'Oops...', error.response.data.message);
                    setIsLoading(false);
                } else {
                    triggerAlert('error', 'Oops...', 'Something went wrong here..');
                    setIsLoading(false);
                }
            });
    };

    const handlePageChange = page => {
        fetchListData(page, perPage, searchQuery);
        setCurrentPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchListData(page, newPerPage, searchQuery);
        setPerPage(newPerPage);
    };
    const fetchSubscriptionPlans = async () => {
        try {
            let token = sessionStorage.getItem("admin_user_token")
            const response = await axios.get(`apiV1/add-subscription-plan/`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const response_data = response.data
            if (response.status === 200) {
                const itemsArray = response_data.results;
                setSubscriptionPlans(itemsArray);

            }

        } catch (error) {

        }
    };


    const [customerlist, setcustomerlist] = useState(false)
    const [customeradd, setcustomeradd] = useState(false)
    const [customeredit, setcustomeredit] = useState(false)
    const [customerdelete, setcustomerdelete] = useState(false)
    useEffect(() => {
        fetchListData(1);
        fetchSubscriptionPlans();
        fetchAllCountsinCustomers();

        access_privileges.map((access, i) => {
            access.sub_module_info.map((subaccess, k) => {
                if (subaccess.name == "Customer List" && subaccess.permissions.indexOf(1) !== -1) {
                    setcustomerlist(true)
                }
                if (subaccess.name == "Customer List" && subaccess.permissions.indexOf(2) !== -1) {
                    setcustomeradd(true)
                }
                if (subaccess.name == "Customer List" && subaccess.permissions.indexOf(3) !== -1) {
                    setcustomeredit(true)
                }
                if (subaccess.name == "Customer List" && subaccess.permissions.indexOf(4) !== -1) {
                    setcustomerdelete(true)
                }

            })


        })
    }, []);
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>
                                <div class="row">
                                    <div class=" col-md-2 " onClick={() => { fetchListData(1, perPage, searchQuery, 0); setcustomerfilter(0) }}>
                                        <div className="info-tiles pe-5 mx-2 mb-2" style={{ backgroundColor: "rgb(0, 95, 115)" }} >
                                            <div class="tiles-heading" data-toggle="tooltip" title="Click here">Total Customers</div>
                                            <div class="tiles-body"><span>{customerCount.total_customers}</span></div>
                                        </div>

                                    </div>
                                    <div class=" col-md-2 " onClick={() => { fetchListData(1, perPage, searchQuery, 1); setcustomerfilter(1) }}>
                                        <div className="info-tiles pe-5 mx-2 mb-2" style={{ backgroundColor: "rgb(202, 103, 2)" }}>
                                            <div class="tiles-heading" data-toggle="tooltip" title="Click here">Active Customers</div>
                                            <div class="tiles-body">{customerCount.total_active_cust}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-2" onClick={() => { fetchListData(1, perPage, searchQuery, 2); setcustomerfilter(2) }}>
                                        <div className="info-tiles  pe-5 mb-2" style={{ backgroundColor: "rgb(187, 62, 3)" }}>
                                            <div class="tiles-heading" data-toggle="tooltip" title="Click here">Inactive Customers</div>
                                            <div class="tiles-body">{customerCount.total_inactive_cust}</div>
                                        </div>
                                    </div>
                                    {customeradd ?
                                        <div className="offset-lg-4 col-md-2 text-end">
                                            <div className="btn-group float-end    ml-10">
                                                <button type="button" className="btn btn-primary w-md btn-md" onClick={() => { setCreatenewUser(true); settextbase("Create"); seteditid(); resetForm(); }} style={{ "marginLeft": "10px" }}> Create Customers</button>
                                            </div>
                                        </div> : ""}
                                </div>
                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">

                                            <div className="card-body">
                                                <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12 mb-2">
                                                            <div className="btn-group float-end ">
                                                                <input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleKeySearch} />
                                                                &nbsp;&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {!isLoading ?
                                                        <DataTable
                                                            columns={columns}
                                                            data={listData}
                                                            pagination
                                                            paginationServer
                                                            fixedHeaderScrollHeight='600px'
                                                            fixedHeader
                                                            persistTableHead
                                                            paginationTotalRows={totalRows}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                            paginationPerPage={perPage}
                                                            paginationDefaultPage={currentPage}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}


                                                        /> :
                                                        <div className='loader-overlay text-white'>
                                                            <Loader />
                                                        </div>
                                                    }
                                                    <hr />
                                                </div>

                                            </div>
                                        </div>
                                    </div> {/* <!-- end col -->*/}
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}

            <div className="rightbar-overlay"></div>
            <Modal size='lg' show={CreatenewUser} onHide={() => setCreatenewUser(false)} className='  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">{textbase} Customer  </h1> </Modal.Header>

                <form onSubmit={handleSubmitForm(onSubmit)}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">
                                        Company Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...registerForm('cmp_name', {
                                            required: 'Company Name is required',
                                            pattern: {
                                                value: /^\S.*/,
                                                message: 'Company Name should not start with a space',
                                            },
                                        })}
                                        name="cmp_name"
                                        onKeyUp={() => triggerForm('cmp_name')}
                                    />
                                    {errorsForm.cmp_name && (
                                        <div className="text-danger" style={{ fontSize: '14px' }}>
                                            {errorsForm.cmp_name.message}
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label class="form-label" for="formrow-Email-input" > Email <span style={{ color: "red" }}>*</span> </label>
                                    <input type="email" name="corp_email" id="user" class="form-control" placeholder="sample@gmail.com"
                                        {...registerForm("corp_email", {
                                            required: "Email is required",
                                            pattern: {
                                                value: /^(?!.*\s)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                message: "Invalid Email Id format",
                                            },
                                        })}
                                        onKeyUp={() => triggerForm("corp_email")}
                                    />

                                    {errorsForm.corp_email && (<div className="text-danger" style={{ fontSize: '14px' }}> {errorsForm.corp_email.message}  </div>)}
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="mb-3">
                                    <label  class="form-label" for="formrow-Email-input" > Company Email <span style={{ color: "red" }}>*</span> </label>     
                                    <input type="email" name="cont_person_email" id="user" class="form-control" placeholder="sample@gmail.com"
                                      {...registerForm("cont_person_email", { required: "Email is required",
                                        pattern: {
                                           value: /^(?!.*\s)[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                          message: "Invalid Email Id format",
                                        },
                                      })}
                                    />
                                    
                                    {errorsForm.cont_person_email && ( <div className="text-danger"> {errorsForm.cont_person_email.message}  </div> )}
                                </div>
                            </div> */}
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="formrow-cpassword-input" className="form-label">
                                        Mobile <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="formrow-cpassword-input"
                                        className="form-control"
                                        {...registerForm('phone', {
                                            required: 'Mobile is required',
                                            pattern: {
                                                value: /^\+?(\d{1,3})?[\s-]?(\(?\d{3}\)?)[\s-]?(\d{3})[\s-]?(\d{4})$/,
                                                message: 'Please enter a valid phone number',
                                            },
                                        })}
                                        name="phone"
                                        onKeyUp={() => triggerForm('phone')}
                                    />
                                    {errorsForm.phone && (
                                        <div className="text-danger" style={{ fontSize: '14px' }}>
                                            {errorsForm.phone.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Contact Person Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('contact_person', {
                                        required: 'Contact person is required', pattern: {
                                            value: /^[A-Za-z0-9]+$/,
                                            message: "This field allows only alphabets and numbers. No spaces or special characters are allowed.",
                                        }
                                    })} name="contact_person" onKeyUp={() => triggerForm("contact_person")} />
                                    {errorsForm.contact_person && <div className="text-danger" style={{ fontSize: '14px' }}>{errorsForm.contact_person.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">
                                        Designation <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...registerForm('designation', {
                                            required: 'Designation is required',
                                            pattern: {
                                                value: /^\S.*/,
                                                message: 'Designation should not start with a space',
                                            },
                                        })}
                                        name="designation"
                                        onKeyUp={() => triggerForm('designation')}
                                    />
                                    {errorsForm.designation && (
                                        <div className="text-danger" style={{ fontSize: '14px' }}>
                                            {errorsForm.designation.message}
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Lead generated from <span className="text-danger">*</span></label>
                                    <select className="form-select"  {...registerForm('lead_generated_from', {
                                        required: 'This field  is required'
                                    })}>
                                        <option value="">--Select--</option>
                                        <option value="Linkedin">LinkedIn</option>
                                        <option value="Socialmedia">Social Media</option>
                                        <option value="Word of mouth">Word of mouth</option>
                                        <option value="Referral">Referral</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {errorsForm.lead_generated_from && <div className="text-danger" style={{ fontSize: '14px' }}>{errorsForm.lead_generated_from.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Location(City) <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('location', {
                                        required: 'location is required', pattern: {
                                            value: /^(\S.*)[A-Za-z\s]+$/,
                                            message: 'Please enter only alphabets',
                                        }
                                    })}
                                        name="location"
                                        onKeyUp={() => triggerForm('location')}
                                    />
                                    {errorsForm.location && <div className="text-danger" style={{ fontSize: '14px' }}>{errorsForm.location.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label htmlFor='reg_organizationSize' className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >  Company Size <span className="font_14 text-danger">*</span> </label>
                                    <select className='form-select' name='reg_organizationSize' id='reg_organizationSize'
                                        {...registerForm("company_size", { required: "Please select company size..!", })} >
                                        <option value=''>Company Size</option>
                                        <option value='1-10'>1-10</option>
                                        <option value='11-20'>11-20</option>
                                        <option value='21-50'>21-50</option>
                                        <option value='51-100'>51-100</option>
                                        <option value='101-200'>101-200</option>
                                        <option value='201-500'>201-500</option>
                                        <option value='500+'>500+</option>
                                    </select>
                                    {errorsForm.company_size && (
                                        <small className='text-danger font-bold' style={{ fontSize: '14px' }}>
                                            {errorsForm.company_size.message}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Category<span className="text-danger">*</span></label>
                                    <select type="text" className="form-select" {...registerForm('category', {
                                        required: 'Please select category',
                                    })} name="category" >
                                        <option value="">Select category</option>
                                        <option value="Trail">Trail</option>
                                    </select>
                                    {errorsForm.category && <div className="text-danger" style={{ fontSize: '14px' }}>{errorsForm.category.message}</div>}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div class="mb-3">
                                    <label class="form-label" for="formrow-PhoneNumber-input" > User type  <span style={{ color: "red" }}>*</span> </label>
                                    <select className="form-select form-control" {...registerForm("user_type", { required: "This field is required." })} >
                                        <option value="">--Please choose--</option>
                                        <option value="BUSSINESS"> BUSSINESS</option>
                                        <option value="INDIVIDUAL">INDIVIDUAL</option>
                                    </select>
                                    {errorsForm.user_type && (<div className="text-danger" style={{ fontSize: '14px' }}> {errorsForm.user_type.message} </div>)}
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label class="form-label" for="formrow-PhoneNumber-input" > Subscription Plan  <span style={{ color: "red" }}>*</span> </label>
                                    <select className="form-select form-control" {...registerForm("subscription", { required: "This field is required." })} >
                                        <option value="">-- Please choose --</option>
                                        {subscriptionPlans.map(plan => (
                                            <option key={plan.id} value={plan.id}>{plan.plan_name}</option>
                                        ))}
                                    </select>
                                    {errorsForm.subscription && (<div className="text-danger" style={{ fontSize: '14px' }}>  {errorsForm.subscription.message} </div>
                                    )}
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label htmlFor="SignUpPasswordInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Password  <span className="font_14 text-danger">*</span></label>
                                    <input type="password" className="form-control FormInput" id="SignUpPasswordInput" placeholder="Password"
                                        // value={signUpPassword} onChange={(e) => {setSignUpPassword(e.target.value)}} autoComplete="false" 
                                        {...registerForm("password", {
                                            required: "This field is required",
                                            pattern: {
                                                value:
                                                    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                //  (?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])at least one special chareacter
                                                message:
                                                    "Password should contain at least one digit & one lower case & one upper case & length will be minimum 8 characters ",
                                            },
                                        })}
                                        name="password"
                                    />
                                    {errorsForm.password && (
                                        <small className='text-danger font-bold' style={{ fontSize: '14px' }}>
                                            {errorsForm.password.message}
                                        </small>
                                    )}
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label htmlFor="SignUpConfirmPasswordInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Confirm Password  <span className="font_14 text-danger">*</span></label>
                                    <input type="password" className="form-control FormInput" id="SignUpConfirmPasswordInput" name="confirm_password" placeholder="Confirm Password"
                                        {...registerForm("confirm_password", {
                                            required: "This field is required",
                                            pattern: {
                                                value:
                                                    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                //  (?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])at least one special chareacter
                                                message:
                                                    "Password should contain at least one digit & one lower case & one upper case & length will be minimum 8 characters ",
                                            },
                                        })}
                                        onKeyUp={() => 'confirm_password'}

                                    // value={signUpConfPassword} onChange={(e) => setSignUpConfPassword(e.target.value)} 
                                    />
                                    {errorsForm.confirm_password && (
                                        <small className='text-danger font-bold' style={{ fontSize: '14px' }}>
                                            {errorsForm.confirm_password.message}
                                        </small>
                                    )}
                                    {/* <small id="SignUpConfirmPasswordInputErr" className="font_12 text-danger">{errorforPasswordmatch}</small> */}
                                </div>
                            </div>


                            <div className="col-md-12">
                                <hr />
                                <h4>Billing Address</h4>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Address Line 1 <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...registerForm('address_line_1', {
                                            required: 'Address line 1 is required',
                                            pattern: {
                                                value: /^[A-Za-z0-9\s.,\-_/&()]+$/,
                                                message: 'Please enter only alphabets, numbers, spaces, ".", ",", "-", "/", "&", "(", or ")"',
                                            },
                                        })}
                                        name="address_line_1"
                                        onKeyUp={() => triggerForm('address_line_1')}
                                    />
                                    {errorsForm.address_line_1 && (
                                        <div className="text-danger" style={{ fontSize: '14px' }}>
                                            {errorsForm.address_line_1.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Address Line 2 <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('address_line_2', {
                                        required: 'Address line 2 is required', pattern: {
                                            value: /^[A-Za-z0-9\s.,\-_/&()]+$/,
                                            message: 'Please enter only alphabets, numbers, spaces, ".", ",", "-", "/", "&", "(", or ")"',
                                        }
                                    })}
                                        name="address_line_2"
                                        onKeyUp={() => triggerForm('address_line_2')}
                                    />
                                    {errorsForm.address_line_2 && <div className="text-danger" style={{ fontSize: '14px' }}>{errorsForm.address_line_2.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">City <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('city', {
                                        required: 'city is required', pattern: {
                                            value: /^(\S.*)[A-Za-z\s]+$/,
                                            message: 'Please enter only alphabets',
                                        }
                                    })}
                                        name="city"
                                        onKeyUp={() => triggerForm('city')}

                                    />
                                    {errorsForm.city && <div className="text-danger" style={{ fontSize: '14px' }}>{errorsForm.city.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">State <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('state', {
                                        required: 'state is required', pattern: {
                                            value: /^(\S.*)[A-Za-z\s]+$/,
                                            message: 'Please enter only alphabets',
                                        }
                                    })}
                                        name="state"
                                        onKeyUp={() => triggerForm('state')}
                                    />
                                    {errorsForm.state && <div className="text-danger" style={{ fontSize: '14px' }}>{errorsForm.state.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">country <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('country', {
                                        required: 'country is required', pattern: {
                                            value: /^(\S.*)[A-Za-z\s]+$/,
                                            message: 'Please enter only alphabets',
                                        }
                                    })}
                                        name="country"
                                        onKeyUp={() => triggerForm('country')}
                                    />
                                    {errorsForm.country && <div className="text-danger" style={{ fontSize: '14px' }}>{errorsForm.country.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Pincode <span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" {...registerForm('postal_code', {
                                        required: 'Pincode is required',
                                        pattern: {
                                            value: /^(\S.*)/,
                                            message: 'Pincode should not start with a space'
                                        }
                                    })}
                                        name="postal_code"
                                        onKeyUp={() => triggerForm('postal_code')}
                                    />
                                    {errorsForm.postal_code && <div className="text-danger" style={{ fontSize: '14px' }}>{errorsForm.postal_code.message}</div>}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <Modal.Footer>
                        <div className="py-2 mb-3">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setCreatenewUser(false); }} > Cancel </button>
                                    <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn"  > Save </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </form>
            </Modal>

            <Modal size='lg' show={EditnewUser} onHide={() => setEditnewUser(false)} className='  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton onClick={() => resetEdit('')}> <h1 className="modal-title fs-5 ">{textbase} Customer  </h1> </Modal.Header>

                <form onSubmit={handleSubmitEdit(onSubmitEdit)}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Company Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('cmp_name', { required: 'Company Name is required' })} name="cmp_name" onKeyUp={() => triggerEdit("cmp_name")} />
                                    {errorsEdit.cmp_name && <div className="text-danger">{errorsEdit.cmp_name.message}</div>}
                                </div>
                            </div>

                            {/* <div className="col-md-6">
                                <div className="mb-3">
                                    <label  class="form-label" for="formrow-Email-input" > Company Email <span style={{ color: "red" }}>*</span> </label>     
                                    <input type="email" name="cont_person_email" id="user" class="form-control" placeholder="sample@gmail.com"
                                      {...registerForm("cont_person_email", { required: "Email is required",
                                        pattern: {
                                           value: /^(?!.*\s)[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                          message: "Invalid Email Id format",
                                        },
                                      })}
                                    />
                                    
                                    {errorsForm.cont_person_email && ( <div className="text-danger"> {errorsForm.cont_person_email.message}  </div> )}
                                </div>
                            </div> */}
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-cpassword-input" className="form-label">Mobile <span className="text-danger">*</span></label>
                                    <input type="number" id='formrow-cpassword-input' className="form-control" {...registerEdit('phone', {
                                        required: 'Mobile is required', pattern: { value: /^[0-9]+$/, message: "Please enter min 10 digit Phone Number" },
                                        minLength: { value: 10, message: "Please enter 10 digit phone number" },
                                        maxLength: { value: 10, message: "Please enter 10 digit phone number" },
                                    })} name="phone" onKeyUp={() => triggerEdit("phone")} />
                                    {errorsEdit.phone && <div className="text-danger">{errorsEdit.phone.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Contact Person Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('contact_person', {
                                        required: 'Contact person is required', pattern: {
                                            value: /^[A-Za-z0-9]+$/,
                                            message: "This field allows only alphabets and numbers. No spaces or special characters are allowed.",
                                        }
                                    })} name="contact_person" onKeyUp={() => triggerEdit("contact_person")} />
                                    {errorsEdit.contact_person && <div className="text-danger">{errorsEdit.contact_person.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Designation<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('designation', {
                                        required: 'designation is required',
                                    })} name="designation" />
                                    {errorsEdit.designation && <div className="text-danger">{errorsEdit.designation.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Lead generated from <span className="font_14 text-danger">*</span>
                                    </label>
                                    <select
                                        className="form-select"
                                        {...registerEdit("lead_generated_from", {
                                            required: "This field is required",
                                        })}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="Linkedin">LinkedIn</option>
                                        <option value="Socialmedia">Social Media</option>
                                        <option value="Word of mouth">Word of mouth</option>
                                        <option value="Referral">Referral</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {errorsEdit.lead_generated_from && (
                                        <div className="text-danger">{errorsEdit.lead_generated_from.message}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Location(City) <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('location', {
                                        required: 'location is required', pattern: {
                                            value: /^[A-Za-z\s]+$/,
                                            message: 'Please enter only alphabets',
                                        }
                                    })} name="location" />
                                    {errorsEdit.location && <div className="text-danger">{errorsEdit.location.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label htmlFor='reg_organizationSize' className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >  Company Size <span className="font_14 text-danger">*</span> </label>
                                    <select className='form-select' name='reg_organizationSize' id='reg_organizationSize'
                                        {...registerEdit("company_size", { required: "Please select company size..!", })} >
                                        <option value=''>Company Size</option>
                                        <option value='1-10'>1-10</option>
                                        <option value='11-20'>11-20</option>
                                        <option value='21-50'>21-50</option>
                                        <option value='51-100'>51-100</option>
                                        <option value='101-200'>101-200</option>
                                        <option value='201-500'>201-500</option>
                                        <option value='500+'>500+</option>
                                    </select>
                                    {errorsEdit.company_size && (
                                        <small className='text-danger font-bold'>
                                            {errorsEdit.company_size.message}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Category<span className="text-danger">*</span></label>
                                    <select type="text" className="form-select" {...registerEdit('category', {
                                        required: 'Please select category',
                                    })} name="category" >
                                        <option value="">Select category</option>
                                        <option value="Trail">Trail</option>
                                        <option value="Paid">Paid</option>
                                    </select>
                                    {errorsEdit.category && <div className="text-danger">{errorsEdit.category.message}</div>}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div class="mb-3">
                                    <label class="form-label" for="formrow-PhoneNumber-input" > User type  <span style={{ color: "red" }}>*</span> </label>
                                    <select className="form-select form-control" {...registerEdit("user_type", { required: "This field is required." })} name="user_type" >
                                        <option value="">--Please choose--</option>
                                        <option value="BUSSINESS">BUSSINESS</option>
                                        <option value="INDIVIDUAL">INDIVIDUAL</option>
                                    </select>
                                    {errorsEdit.user_type && (<div className="text-danger"> {errorsEdit.user_type.message} </div>)}
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label class="form-label" for="formrow-PhoneNumber-input" > Subscription Plan  <span style={{ color: "red" }}>*</span> </label>
                                    <select className="form-select form-control" {...registerEdit("subscription", { required: "This field is required." })} disabled={true}>
                                        <option value="">-- Please choose --</option>
                                        {subscriptionPlans.map(plan => (
                                            <option key={plan.id} value={plan.id}>{plan.plan_name}</option>
                                        ))}
                                    </select>

                                    {errorsEdit.subscription && (<div className="text-danger">  {errorsEdit.subscription.message} </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <hr />
                                <h4>Billing Address</h4>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Address Line 1<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('address_line_1', {
                                        required: 'address_line_1 is required', pattern: {
                                            value: /^[A-Za-z0-9\s.,\-_/&()]+$/,
                                            message: 'Please enter only alphabets, numbers, spaces, ".", ",", "-", "/", "&", "(", or ")"',
                                        }
                                    })} name="address_line_1" />
                                    {errorsEdit.address_line_1 && <div className="text-danger">{errorsEdit.address_line_1.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Address Line 2 <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('address_line_2', {
                                        required: 'address_line_2 is required', pattern: {
                                            value: /^[A-Za-z0-9\s.,\-_/&()]+$/,
                                            message: 'Please enter only alphabets, numbers, spaces, ".", ",", "-", "/", "&", "(", or ")"',
                                        }
                                    })} name="address_line_2" />
                                    {errorsEdit.address_line_2 && <div className="text-danger">{errorsEdit.address_line_2.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">City <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('city', {
                                        required: 'city is required', pattern: {
                                            value: /^[A-Za-z\s]+$/,
                                            message: 'Please enter only alphabets',
                                        }
                                    })} name="city" />
                                    {errorsEdit.city && <div className="text-danger">{errorsEdit.city.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">State <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('state', {
                                        required: 'state is required', pattern: {
                                            value: /^[A-Za-z\s]+$/,
                                            message: 'Please enter only alphabets',
                                        }
                                    })} name="state" />
                                    {errorsEdit.state && <div className="text-danger">{errorsEdit.state.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">country <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('country', {
                                        required: 'country is required', pattern: {
                                            value: /^[A-Za-z\s]+$/,
                                            message: 'Please enter only alphabets',
                                        }
                                    })} name="country" />
                                    {errorsEdit.country && <div className="text-danger">{errorsEdit.country.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Pincode <span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" {...registerEdit('postal_code', {
                                        required: 'postal_code is required',
                                    })} name="postal_code" />
                                    {errorsEdit.postal_code && <div className="text-danger">{errorsEdit.postal_code.message}</div>}
                                </div>
                            </div>

                        </div>
                    </ModalBody>
                    <Modal.Footer>
                        <div className="py-2 mb-3">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setEditnewUser(false); resetEdit('') }} > Cancel </button>
                                    <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn"  > Save </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </form>
            </Modal>


            <Modal size='md' show={SuccessAlert} onHide={() => setSuccessAlert(false)} className='text-center  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">{textbase} Product  </h1> </Modal.Header>
                <ModalBody>
                    <h4>Product {textbase} Successfully</h4>
                </ModalBody>
                <Modal.Footer>
                    <div className="py-2 mb-3">
                        <div className="row">
                            <div className="col-12 text-center">
                                <button className='btn btn-primary w-md btn-md ' id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => setSuccessAlert(false)}  > Ok </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )

}
