import React, { useEffect, useState } from "react";
import Header from '../../../common/Header'
import Sidebar from '../../../common/Sidebar'
import Footer from '../../../common/Footer'
import PageHeader from '../../PageHeader'
import axios from "axios";
import { Controller, useForm } from 'react-hook-form';
import Loader from '../../../common/Loader'
import AutoSearchSelect from '../../CommonSelectDynamic'
import { Table } from 'react-bootstrap'
import { triggerAlert } from "../../../utils";
export default function MenuPermission2() {
    document.title = "Talent scan - Access Privileges"
    const mainHeading = "Roles Permission";
    const heading = "Admin / Roles Permission";
    const [isLoading, setIsLoading] = useState();
    const [menuData, setMenuData] = useState([]);
    const [adminUserData, setAdminUserData] = useState([]);
    const [editId, setEditId] = useState("");
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, control: controlForm, getValues: getValuesForm, watch } = useForm({
        defaultValues: { is_manager: "N" },
    });
    const fetchMenuDetails = (event) => {
        const selectedOption = event.target.value;
        console.log('Selected Option:', selectedOption);
        setValueForm('role_id', selectedOption);
        if (selectedOption) {
            getModulePermissions(selectedOption);
        } else {
            setAdminUserData([]);
        }
    };
    const getModulePermissions = async (id) => {
        const response = await axios.get(`apiV1/module-mappings/?role_id=${id}`);
        const response_data = response.data.results
        setAdminUserData(response_data)
    }
    const onchangemodulepermissions = (value, number, module_id) => {
        let data = adminUserData
        data.map((module) => {
            if (module.module_info.name == module_id) {
                if (value) {
                    module.module_info.permissions.push(number)
                } else {
                    let index = module.module_info.permissions.indexOf(number);
                    if (index !== -1) {
                        module.module_info.permissions.splice(index, 1);
                    }
                    module.sub_module_info.map((sub, k) => {
                        let index = sub.permissions.indexOf(number);
                        if (index !== -1) {
                            sub.permissions.splice(index, 1);
                        }


                        return sub;
                    })

                }
            }
            return module
        })
        setAdminUserData([...data])
    }
    const onchangesubmodulepermissions = (value, number, module_id, sub_module_id) => {
        let data2 = adminUserData
        let data = adminUserData[module_id]


        data.sub_module_info.map((module) => {
            if (module.id == sub_module_id) {
                if (value) {
                    module.permissions.push(number)
                } else {
                    let index = module.permissions.indexOf(number);
                    if (index !== -1) {
                        module.permissions.splice(index, 1);
                    }
                }
            }
            return module
        })
        data2.map((item, i) => {
            if (item.module_info.id == module_id) {
                item = data
            }
            return item
        })

        setAdminUserData([...data2])
    }
    const saveRolesaspertheuser = async () => {
        console.log(adminUserData)
        const accessData = adminUserData.map((module) => {
            const modulePermissions = (module.module_info.permissions || []).filter(permission => permission !== null);
            const subModules = (module.sub_module_info || []).map((subModule) => {
                const subModulePermissions = (subModule.permissions || []).filter(permission => permission !== null);
                return {
                    module_id: subModule.id || null,
                    module_name: subModule.name || '',
                    permissions: subModulePermissions,
                };
            });

            return {
                module_id: module.module_info.id || null,
                module_name: module.module_info.name || '',
                permissions: modulePermissions,
                sub_modules: subModules,
            };
        });

        const dataToSend = {
            role: getValuesForm('role_id'),
            access_data: accessData,
        };
        console.log(dataToSend)
        try {
            await axios.post('/apiV1/access-previliges/', dataToSend);
            getModulePermissions(getValuesForm('role_id'));
            triggerAlert('success', 'success', 'Roles updated successfully');
        } catch (error) {
            console.error('Error posting access privileges:', error);
            console.log('Response data:', error.response.data);
            console.log('Response status:', error.response.status);
        }
    }
    const [departmentList, setDepartmentList] = useState(null);

    const fetchRolesList = async () => {
        try {
            const response = await axios.get('/apiV1/role-dropdown/');
            const response_data = response.data;
            if (response.status === 200) {
                const itemsArray = response_data;
                setDepartmentList(itemsArray);
            } else if (response.status === 204) {
                setDepartmentList([]);
            } else {
                triggerAlert("error", "Oops...", "Couldn't get the department list.");
            }
        } catch (error) {
            console.log(error);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    };
    useEffect(() => {
        fetchRolesList()
    }, [])
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                {isLoading &&
                    <div className='loader-overlay text-white'>
                        <Loader />
                    </div>
                }
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            <>
                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="row">
                                                    <div class="col-md-3 ">
                                                        <div class="mb-3">
                                                            <label for="admin_id" class="form-label">Role<span className="text-danger">*</span></label>
                                                            <select
                                                                className="form-select"
                                                                aria-label="Default select example"
                                                                {...registerForm('level_id')}
                                                                onChange={fetchMenuDetails}
                                                            >
                                                                <option value="">Select Role</option>
                                                                {departmentList &&
                                                                    departmentList.map((department, index) => (
                                                                        <option key={index} value={department.id}>
                                                                            {department.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                            {errorsForm.admin_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.admin_id.message}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="  col-md-1 offset-md-8  ml-10 mt-4 text">
                                                        <label for="admin_id" class="form-label d-none">User Name<span className="text-danger">*</span></label>
                                                        {adminUserData.length !== 0 ? <button className="btn btn-primary addBtn waves-effect waves-light w-md font-size-14 ms-2" type='button' onClick={() => saveRolesaspertheuser()}> Update<i className="align-middle font-size-16 ms-2"></i></button> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        {adminUserData.length !== 0 ?
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
                                                                            <Table style={{ width: '100%' }} className='table dataTable table-responsive table-bordered'>
                                                                                <thead >
                                                                                    <tr>
                                                                                        <th>Name</th>
                                                                                        <th>View</th>
                                                                                        <th>Add</th>
                                                                                        <th> Edit</th>
                                                                                        <th> Delete</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {adminUserData.map((module, i) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr key={module.module_info.id + 1}>
                                                                                                    <td className="text-start "><b>{module.module_info.name}</b></td>
                                                                                                    <td><input className="form-check-input form_check_input_permission" type="checkbox" checked={module.module_info.permissions.indexOf(1) > -1 ? true : false} onChange={(e) => onchangemodulepermissions(e.target.checked, 1, module.module_info.name)} /></td>
                                                                                                    <td><input className="form-check-input form_check_input_permission" type="checkbox" checked={module.module_info.permissions.indexOf(2) > -1 ? true : false} onChange={(e) => onchangemodulepermissions(e.target.checked, 2, module.module_info.name)} /></td>
                                                                                                    <td><input className="form-check-input form_check_input_permission" type="checkbox" checked={module.module_info.permissions.indexOf(3) > -1 ? true : false} onChange={(e) => onchangemodulepermissions(e.target.checked, 3, module.module_info.name)} /></td>
                                                                                                    <td><input className="form-check-input form_check_input_permission" type="checkbox" checked={module.module_info.permissions.indexOf(4) > -1 ? true : false} onChange={(e) => onchangemodulepermissions(e.target.checked, 4, module.module_info.name)} /></td>
                                                                                                </tr>
                                                                                                {module.sub_module_info.map((submodule, k) => {
                                                                                                    return (
                                                                                                        <tr key={submodule.id + 1000}>
                                                                                                            <td className="text-start px-5 ">{submodule.name}</td>
                                                                                                            <td><input className="form-check-input form_check_input_permission" type="checkbox" disabled={module.module_info.permissions.indexOf(1) > -1 ? false : true} checked={submodule.permissions.indexOf(1) > -1 ? true : false} onChange={(e) => onchangesubmodulepermissions(e.target.checked, 1, i, submodule.id)} /></td>
                                                                                                            <td><input className="form-check-input form_check_input_permission" type="checkbox" disabled={module.module_info.permissions.indexOf(2) > -1 ? false : true} checked={submodule.permissions.indexOf(2) > -1 ? true : false} onChange={(e) => onchangesubmodulepermissions(e.target.checked, 2, i, submodule.id)} /></td>
                                                                                                            <td><input className="form-check-input form_check_input_permission" type="checkbox" disabled={module.module_info.permissions.indexOf(3) > -1 ? false : true} checked={submodule.permissions.indexOf(3) > -1 ? true : false} onChange={(e) => onchangesubmodulepermissions(e.target.checked, 3, i, submodule.id)} /></td>
                                                                                                            <td><input className="form-check-input form_check_input_permission" type="checkbox" disabled={module.module_info.permissions.indexOf(4) > -1 ? false : true} checked={submodule.permissions.indexOf(4) > -1 ? true : false} onChange={(e) => onchangesubmodulepermissions(e.target.checked, 4, i, submodule.id)} /></td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })}
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </tbody>

                                                                            </Table>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div> : ""}

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    )
}
