import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import Footer from "../../common/Footer";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import {
  downloadDataInCsv,
  triggerAlert,
  pageReload,
  getToken,
  handleTableRowClick,
  formattedDateTime,
  ConfirmationAlert,
  getBase64,
  formatDate,
  transformText,
  get_user_menu_permission,
  downloadFile
} from "../../utils";
import Loader from "../../common/Loader";
import { Table, Overlay, Popover } from "react-bootstrap";
import CountUp from "react-countup";
import Swal from "sweetalert2";
import AutoCompanyUserSearch from "../AutoCompanyUserSearch";
import Pagination from "../PaginationComponent";
import AutoUserSearch from "../AutoUserSearch";
import DatePicker from "react-datepicker";
import {
  onlyAlphaNumericAndSpaces,
  MaxLengthValidation,
  MinLengthValidation,
  createPattern,
  onlyAlphabetsandSpaces,
  onlyNumbers,
  menuIdData,
} from "../../utils/constants";

function CustomerList() {
  document.title = "Talent scan - CustomerList"
  const api_url = process.env.REACT_APP_API_BASE_URL;
  const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
  const token = getToken();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState([]);
  const [isLoadingBlc, setIsLoadingBlc] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showInput, setShowInput] = useState(false);

  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [selectCustCntryCode, setSelectCustCntryCode] = useState(null);
  const [stateData, setStatesData] = useState([]);
  const [custStateData, setCustStatesData] = useState([]);

  const [selectedValue, setSelectedValue] = useState("");
  const [custStSelectValue, setCustStSelectValue] = useState("");
  const [countryData, setCountriesData] = useState([]);
  const [custCountData, setCustCountData] = useState(null);
  const [totalCust, setTotalCust] = useState([]);
  const [itserveCust, setItserveCust] = useState([]);
  const [defaulterCust, setDefaulterCust] = useState([]);

  const [customerId, setcustomerId] = useState(null);
  const [userIdBalance, setUserIdBalance] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("active");
  const [Imgname, setImgName] = useState(null);
  const [signName, setSignName] = useState(null);
  // const [feildsReadOnly, setFieldsReadonly] = useState(null);
  const [salesPersons, setSalesPersons] = useState([]);

  const [base64Img, setbase64Img] = useState(null);
  const [base64Imgsign, setbase64Imgsign] = useState(null);
  const [base64Imgw9, setbase64Imgw9] = useState(null);
  const [fileNameDl, setFileNameDl] = useState(null);
  const [fileNameSign, setFileNameSign] = useState(null);
  const [fileNameW9, setFileNameW9] = useState(null);

  const [minDate, setMinDate] = useState(new Date());

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimitSelected, setPageLimitSelected] = useState(show50Entries);
  const [perPageLimit, setPerPageLimit] = useState(show50Entries);
  const [pageSlNo, setPageSlNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [sectionVisible, setSectionVisible] = useState({
    sectionA: true,
    sectionB: false,
    sectionC: false,
  });

  const [sameAddress, setSameAddress] = useState(false);

  // Menu Permissions
  const [addPermission, setAddPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [exportPermission, setExportPermission] = useState(false);

  const toggleSection = (section) => {
    setSectionVisible((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    unregister,
    getValues,
  } = useForm();
  const {
    control: control2,
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    reset: reset2,
    unregister: unregister2,
    getValues: getValues2,
    setError: setError2,
    clearErrors: clearErrors2,
    trigger: trigger2,
    watch: watch2,
  } = useForm();

  const filteredStates = stateData.filter(
    (state) => state.country_code_char2 === selectedCountryCode
  );
  const filteredCustStates = custStateData.filter(
    (state) => state.country_code_char2 === selectCustCntryCode
  );

  let params1 = {
    title: "driver_license",
  };

  let params2 = {
    title: "Signed address proof of signing",
  };

  let params3 = {
    title: "W9 Form",
  };

  // console.log("Customer List", sectionVisible.sectionA);
  // console.log("Customer Create", sectionVisible.sectionB);

  {
    /* Customer Creation */
  }
  const customerSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        api_url + "customer/create_customer",
        data,
        token
      );
      const response_data = response.data;
      if (response_data.error_code == "201") {
        setIsLoading(false);
        triggerAlert("success", "success", "Data got submitted successfully");
        pageReload();
      } else {
        setIsLoading(false);
        triggerAlert("error", "Oops...", "Error Submitting data");
      }
    } catch (error) {
      if (error?.response?.status == "400") {
        setIsLoading(false);
        triggerAlert("error", "Oops...", error.response.data.message);
      } else {
        setIsLoading(false);
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    }
  };
  {
    /* Customer Creation */
  }

  const removeBalTool = (e) => {
    //console.log("tag",e.target);

    const tagName = e.target.tagName;

    if (tagName != "I") {
      handleCancelClick();
    }
  };

  {
    /* Customer Updation */
  }
  const editRequest = () => {
    // console.log(selectedRow);
    if (selectedRowId) {
      // console.log(selectedRow);
      setbase64Img(selectedRow.drivers_license);
      setbase64Imgsign(selectedRow.address_proof);
      setbase64Imgw9(selectedRow.w9_form);
      setValue2("user_name", selectedRow.user_name);
      setValue2("first_name", selectedRow.first_name);
      setValue2("last_name", selectedRow.last_name);
      setValue2("password", selectedRow.watch_word);
      setValue2("company_name", selectedRow.company_name);
      setValue2("email", selectedRow.email);
      setValue2("account_email", selectedRow.account_email);
      setValue2("website", selectedRow.website);
      setValue2("status", selectedRow.status);
      setValue2("country", selectedRow.country);
      setValue2("state", selectedRow.state);
      setValue2("city", selectedRow.city);
      setValue2("address", selectedRow.address);
      setValue2("zipcode", selectedRow.zipcode);
      setValue2("phone", selectedRow.phone);
      setValue2("cust_country", selectedRow.cust_country);
      setValue2("cust_state", selectedRow.cust_state);
      setValue2("cust_city", selectedRow.cust_city);
      setValue2("cust_address", selectedRow.cust_address);
      setValue2("cust_zipcode", selectedRow.cust_zipcode);
      setValue2("plan_id", selectedRow.plan_id);
      // setValue2('india_calling', selectedRow.india_calling);
      setValue2("customer_status", selectedRow.customer_status);
      setValue2("no_of_lines_msa", selectedRow.no_of_lines_msa);
      setValue2("no_of_lines", selectedRow.no_of_lines);
      setValue2("lines_cost", selectedRow.lines_cost);
      setValue2("num_phones_rental", selectedRow.num_phones_rental);
      setValue2("cost_phone_rental", selectedRow.cost_phone_rental);
      setValue2("incoming_lines", selectedRow.incoming_lines);
      setValue2("incoming_lines_cost", selectedRow.incoming_lines_cost);
      setValue2("paid_dids", selectedRow.paid_dids);
      setValue2("dids_cost", selectedRow.dids_cost);
      setValue2("plan_tax", selectedRow.plan_tax);
      setValue2(
        "no_of_international_dids",
        selectedRow.no_of_international_dids
      );
      setValue2("bill_date", selectedRow.bill_date);
      // console.log("selectedRow.bill_date", selectedRow.bill_date);
      const currentDate = formatDate(new Date(), "yyyy-mm-dd");
      // console.log("currentDate", currentDate);

      setMinDate(new Date(currentDate));
      setValue2("sub_user", selectedRow.sub_user);
      setValue2("group_status", selectedRow.group_status);
      setValue2("group_parent_name", selectedRow.group_parent_name);
      setValue2("contract_status", selectedRow.contract_status);
      setValue2("about_vitel", selectedRow.about_vitel);
      setValue2("device_user", selectedRow.device_user);
      setValue2("user_type", selectedRow.user_type);
      setValue2("customer_plan", selectedRow.customer_plan);
      setValue2("reseller", selectedRow.reseller);
      setValue2("sales_person", selectedRow.sales_person);
      setValue2("billing_months", selectedRow.billing_months);
      setValue2("itserve_customer", selectedRow.itserve_customer);
      setValue2("type_of_invoice", selectedRow.type_of_invoice);
      setValue2("itserve_chapter", selectedRow.itserve_chapter);
      setValue2("msa_status", selectedRow.msa_status);
      setValue2("defaulter_status", selectedRow.defaulter_status);
      setValue2("customer_profile_id", selectedRow.customer_profile_id);
      setValue2("payment_profile_id", selectedRow.payment_profile_id);
      setValue2("type", selectedRow.type);
      setValue2("customer_address_id", selectedRow.customer_address_id);
      setValue2("subscription_id", selectedRow.subscription_id);

      setValue2("m4_account_id", selectedRow.m4_account_id);
      setValue2("mor_account_id", selectedRow.mor_account_id);
      setValue2("ceo", selectedRow.ceo);
      setValue2("ceo_mail", selectedRow.ceo_mail);
      setValue2("ceo_phone", selectedRow.ceo_phone);
      setValue2("federal_id", selectedRow.federal_id);
      setValue2("drivers_license", selectedRow.drivers_license);
      setValue2("address_proof", selectedRow.address_proof);
      setValue2("w9_form", selectedRow.w9_form);
      setValue2("drivers_license", selectedRow.drivers_license);
      setValue2("address_proof", selectedRow.address_proof);
      setValue2("w9_form", selectedRow.w9_form);
      setbase64Img(selectedRow.drivers_license);
      setbase64Imgsign(selectedRow.address_proof);
      setbase64Imgw9(selectedRow.w9_form);
      const partsDl = selectedRow?.drivers_license?.split('/');
      const partsSign = selectedRow?.address_proof?.split('/');
      const partsW9 = selectedRow?.w9_form?.split('/');
      setFileNameDl(partsDl ? partsDl[partsDl?.length - 1] : null)
      setFileNameSign(partsSign ? partsSign[partsSign?.length - 1] : null)
      setFileNameW9(partsW9 ? partsW9[partsW9?.length - 1] : null)

      setSameAddress(selectedRow.billed_address);

      toggleSection("sectionA");
      toggleSection("sectionC");
      setSelectedCountryCode(selectedRow.country);
      setSelectCustCntryCode(selectedRow.cust_country);
      fetchCustStatesData(selectedRow.cust_country);
      fetchStatesData(selectedRow.country);
      setSelectedValue(selectedRow.state);
      setCustStSelectValue(selectedRow.cust_state);
    } else {
      triggerAlert("error", "Oops...", "Please select a row");
    }
  };

  const customerEditSubmit = async (data) => {
    data.drivers_license = base64Img;
    data.address_proof = base64Imgsign;
    data.w9_form = base64Imgw9;
    console.log("data.billdate", data.billdate);
    data.bill_date = formatDate(data.bill_date, "yyyy-mm-dd");
    data.billed_address = sameAddress;
    //data.w9_form=base64Imgw9;
    // console.log("data", data);
    //console.log(selectedRowId);
    try {
      var id = selectedRowId;
      const response = await axios.put(
        api_url + "customer/update_customer_data/" + id + "/update",
        data,
        token
      );
      const response_data = response.data;
      if (response?.status) {
        triggerAlert("success", "success", "Data got updated successfully");
        pageReload();
      } else {
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      // console.log(error);
      if (error?.response?.status == "400") {
        triggerAlert("error", "Oops...", error.response.data.message);
      } else {
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    }
  };

  {
    /* Customer Updation */
  }

  const handleUserSelect = (userData) => {
    // console.log(userData);
    setcustomerId(userData.value);
    setPageCount(0);
    setCurrentPage(0);
    setPerPageLimit(show50Entries);
    setPageLimitSelected(show50Entries);
    handleChange(userData.user);
  };

  {
    /* Customer Deletion */
  }
  const deleteCustomer = () => {
    //alert(selectedRowId);
    if (selectedRowId) {
      ////////////////////////////////////////
      // console.log(selectedRowId);
      Swal.fire({
        icon: "warning",
        title: "Do you want to delete data?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          var id = selectedRowId;
          var data = { status: "inactive" };
          try {
            const response = await axios.put(
              api_url + "customer/update_customer_data/" + id + "/update",
              data,
              token
            );
            const response_data = response.data;
            //console.log(message);
            if (response_data.error_code == "200") {
              triggerAlert("success", "success", response_data.message);
            } else {
              triggerAlert("error", "Oops...", "Something went wrong..");
            }
            pageReload();
          } catch (error) {
            triggerAlert("error", "Oops...", "Something went wrong..");
          }
        }
      });
    } else {
      triggerAlert("error", "Oops...", "Please select a row");
    }
  };

  const exportToExcel = () => {
    // const selectedColumns = ['firstName', 'age'];
    const customHeaders = {
      user_name: "user_name",
      company_name: "company_name",
      no_of_dids: "no_of_dids",
      dids_cost: "dids_cost",
      vfax_dids_cost: "vfax_dids_cost",
      phone: "phone",
      email: "email",
      address: "address",
      state: "state",
      country: "country",
      status: "status",

      // ... add more custom headers as needed
    };
    downloadDataInCsv(customerData, "customerlist.csv", customHeaders);
  };

  // Default
  const fetchSalesData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        api_url + "customer/sales_person_data",
        token
      );
      const respdata = response.data.results;
      setSalesPersons(respdata);

      setIsLoading(false);
    } catch (error) {
      //triggerAlert('error','','No data available');
    }
  };
  const fetchCountriesData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(api_url + "customer/country_list");
      const respdata = response.data.results.data;
      setCountriesData(respdata);

      setIsLoading(false);
    } catch (error) {
      //triggerAlert('error','','No data available');
    }
  };

  const fetchStatesData = async (countryCode) => {
    setIsLoading(true);
    try {
      if (countryCode.length !== 0) {
        const response = await axios.get(
          api_url + "customer/state_list/" + countryCode
        );
        const respdata = response.data.results.data;
        setStatesData(respdata);
        setIsLoading(false);
      }
    } catch (error) {
      //triggerAlert('error','','No data available');
    }
  };

  const fetchCustStatesData = async (countryCode) => {
    setIsLoading(true);
    try {
      if (countryCode.length !== 0) {
        const response = await axios.get(
          api_url + "customer/state_list/" + countryCode
        );
        const respdata = response.data.results.data;
        setCustStatesData(respdata);
        setIsLoading(false);
      }
    } catch (error) {
      //triggerAlert('error','','No data available');
    }
  };

  const fetchCustomerData = async (data, page, searchkey) => {
    // console.log(page);
    const status = data;
    setStatus(status);
    const per_page = perPageLimit;
    const searchQuery = searchkey;
    setIsLoading(true);
    try {
      setPageSlNo((page - 1) * per_page);
      let apiUrl = `${api_url}customer/get_overall_customer`;
      let params = `?page_size=${per_page || 10}&page_number=${page || 1}`;
      if (status) params += `&status=${status || "active"}`;
      if (searchQuery) params += `&keyword=${searchQuery}`;
      // console.log(apiUrl + params);

      const response = await axios.get(apiUrl + params, token);
      const response_data = response.data.results.data;
      //console.log(response_data);
      const total_pages = response.data.results.pagination.total_pages;
      setCustomerData(response_data);
      setPageCount(total_pages);
      setIsLoading(false);
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        api_url + "customer/get_customer_counts",
        token
      );
      const respdata = response.data.results.data;
      const total = response.data.results.data.total_customer;
      const itserve = response.data.results.data.itserve_customer;
      const defaultcust = response.data.results.data.defaulter_status;
      setTotalCust(total);
      setItserveCust(itserve);
      setDefaulterCust(defaultcust);
      setCustCountData(respdata);
      setIsLoading(false);
    } catch (error) {
      triggerAlert("error", "", "No data available");
    }
  };

  const handleChange = (value) => {
    setSearchQuery(value);
    const searchkey = value;
    fetchCustomerData(status, currentPage + 1, searchkey);
  };

  useEffect(() => {
    setCurrentPage(0);
    fetchCountriesData();
    fetchSalesData();
    fetchStatesData();
    fetchCustStatesData();
    fetchCustomerData(status, currentPage + 1);
    fetchData();
    // $('[data-toggle="tooltip"]').tooltip();
  }, [perPageLimit]);

  //pagination
  const handlePageClick = (selected) => {
    const selectedPage = selected.selected;
    setCurrentPage(selectedPage);
    fetchCustomerData(status, selectedPage + 1);
  };

  let props = {
    pageCount: pageCount,
    handlePageClick: handlePageClick,
    selectedPage: currentPage,
  };

  const [showPopper, setShowPopper] = useState(null);
  const [balance, setBalance] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [myValue, setMyValue] = useState("");

  const fetchBalance = async (id) => {
    // console.log("id", id)
    setUserIdBalance(id);
    setIsLoadingBlc(true);
    try {
      const response = await axios.get(
        api_url + `customer/get_customer_vendor_balance?customer_id=${id}`,
        token
      );

      const respdata = response.data;
      const balance = respdata.results.m4_balance;
      // console.log("balance", balance)
      setBalance(balance);
      setIsLoadingBlc(false);
    } catch (error) {
      // console.log(error)
      setIsLoadingBlc(false);
      setBalance(null);
      // setUserIdBalance(null)
      // triggerAlert('error', '', 'No balance to view');
    }
  };

  // Function to handle click on the button
  const handleEditClick = (event, id) => {
    setBalance(null);
    setShowInput(false);
    setShowPopper(event.target);
    fetchBalance(id);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    //console.log("indise", showPopper);
  };
  ///console.log('userIDOUT', userIdBalance)

  const handleSaveClick = () => {
    // console.log('userID', userIdBalance)
    setShowPopper(null);
    setBalance(null);
    setShowInput(false);
    ConfirmationAlert(`Would you like to continue!!`, "Continue", async () => {
      const updated_balance = Number(inputValue);
      try {
        const api_input = {
          balance: updated_balance,
          customer_id: userIdBalance,
        };
        const response = await axios.post(
          api_url + `customer/update_vendor_balance`,
          api_input,
          token
        );
        setIsLoading(false);
        const response_data = response.data;
        const res_message = response_data.message;
        if (response_data.error_code === 200) {
          triggerAlert("success", "Success", res_message);
          setInputValue(null);
          setUserIdBalance(null);
          setShowInput(false);
        } else if (response.status === 204) {
          setInputValue(null);
          setUserIdBalance(null);
          setIsLoading(false);
          setShowInput(false);
          triggerAlert("error", "Oops...", res_message);
        } else if (response.status === 400) {
          setInputValue(null);
          setUserIdBalance(null);
          setIsLoading(false);
          setShowInput(false);
          triggerAlert("error", "Oops...", res_message);
        } else {
          setInputValue(null);
          setUserIdBalance(null);
          setShowInput(false);
          triggerAlert("error", "Oops...", res_message);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        setIsLoading(false);
        setInputValue(null);
        setUserIdBalance(null);
        setShowInput(false);
        //console.log(error);
        triggerAlert("error", "Oops...", message);
      }
    });
  };

  const handleCancelClick = () => {
    // Close the popover
    setShowPopper(null);
    setBalance(null);
  };

  const handleSameAddressChange = (e) => {
    const isChecked = e.target.checked;
    // console.log('setSameAddress', isChecked);
    setSameAddress(isChecked);

    if (isChecked) {
      // If the checkbox is checked, set presentAddress to currentAddress
      const custAddressValue = getValues2("cust_address");
      const custCityValue = getValues2("cust_city");
      const custCountryValue = getValues2("cust_country");
      const custStateValue = getValues2("cust_state");
      const custZipValue = getValues2("cust_zipcode");

      setValue2("address", custAddressValue);
      setValue2("city", custCityValue);
      setValue2("country", custCountryValue);
      setValue2("state", custStateValue);
      setValue2("zipcode", custZipValue);

      // Set fields as readonly
      // setFieldsReadonly(true);
    } else {
      // If unchecked, clear presentAddress
      setValue2("address", "");
      setValue2("city", "");
      setValue2("country", "");
      setValue2("state", "");
      setValue2("zipcode", "");
      setSelectedValue("");
      // Set fields as editable
      // setFieldsReadonly(false);
    }
  };

  const handleFileChange = async (event) => {

    const file = event.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxFileSize = 1048576; // 1 MB

      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {

        setError2('driver_licence', {
          type: 'manual',
          message: "Invalid file type. Please choose a JPEG or PNG file."
        });
        setbase64Img(null);
        setFileNameDl(null);
        return;
      }

      // Check if the file size is within the limit
      if (file.size > maxFileSize) {
        setError2('driver_licence', {
          type: 'manual',
          message: "File size exceeds the limit. Please choose a file within 1 MB."
        });
        setbase64Img(null);
        setFileNameDl(null);
        return;
      }

      // If file passes validation, proceed with other actions
      const base64imgval = await getBase64(file);
      const base64WithoutPrefix = base64imgval.substring(base64imgval.indexOf(",") + 1);
      setbase64Img(base64imgval);
      setFileNameDl(file.name);
      // Clear any previous errors
      clearErrors2("driver_licence");
    }
  };

  const handleFileChange1 = async (event) => {

    const file = event.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxFileSize = 1048576; // 1 MB

      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {

        setError2('address_proof', {
          type: 'manual',
          message: "Invalid file type. Please choose a JPEG or PNG file."
        });
        setbase64Imgsign(null);
        setFileNameSign(null);
        return;
      }

      // Check if the file size is within the limit
      if (file.size > maxFileSize) {
        setError2('address_proof', {
          type: 'manual',
          message: "File size exceeds the limit. Please choose a file within 1 MB."
        });
        setbase64Imgsign(null);
        setFileNameSign(null);
        return;
      }

      // If file passes validation, proceed with other actions
      const base64imgval = await getBase64(file);
      const base64WithoutPrefix = base64imgval.substring(base64imgval.indexOf(",") + 1);
      setbase64Imgsign(base64imgval);
      setFileNameSign(file.name);
      // Clear any previous errors
      clearErrors2("address_proof");
    }
  };

  const handleFileChange2 = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      const maxFileSize = 1048576; // 1 MB

      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {

        setError2('w9_form', {
          type: 'manual',
          message: "Invalid file type. Please choose a JPEG, PNG and PDF file."
        });
        setbase64Imgw9(null);
        setFileNameW9(null);
        return;
      }

      // Check if the file size is within the limit
      if (file.size > maxFileSize) {
        setError2('w9_form', {
          type: 'manual',
          message: "File size exceeds the limit. Please choose a file within 1 MB."
        });
        setbase64Imgw9(null);
        setFileNameW9(null);
        return;
      }

      // If file passes validation, proceed with other actions
      const base64imgval = await getBase64(file);
      const base64WithoutPrefix = base64imgval.substring(base64imgval.indexOf(",") + 1);
      setbase64Imgw9(base64imgval);

      setFileNameW9(file.name);
      clearErrors2("w9_form");
    }
  };
  useEffect(() => {
    const menu_id = menuIdData?.customer_list;
    setAddPermission(get_user_menu_permission(menu_id, 'add'));
    setEditPermission(get_user_menu_permission(menu_id, 'edit'));
    setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
    setExportPermission(get_user_menu_permission(menu_id, 'export'));
  }, []);

  const handleDownload = (url) => {
    const filename = url.substring(url.lastIndexOf('/') + 1);
    downloadFile(url, filename);
  };
  return (
    <>
      <div id="layout-wrapper" onClick={removeBalTool}>
        <Header />
        <Sidebar />
        {/* ============================================================== */}
        {/* Start right Content here */}
        {/* ============================================================== */}
        <div className="main-content">
          <div className="page-content">
            {/* Customers Listing */}
            <div hidden={!sectionVisible.sectionA} className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Customer List</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item ">
                          <a href="#">Customers</a>
                        </li>
                        <li className="breadcrumb-item active">
                          Customer List
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <div className="row my-5 justify-content-evenly">
                <div
                  className="info-tiles col-md-2"
                  style={{ backgroundColor: " #005F73" }}
                  onClick={() => fetchCustomerData("", 1)}
                >
                  <div
                    className="tiles-heading"
                    data-toggle="tooltip"
                    title="Click here"
                  >
                    Total Customers
                  </div>
                  <div className="tiles-body">
                    {/* <CountUp start={0} end= */}
                    {totalCust}
                    {/* duration={5} /> */}
                  </div>
                </div>

                <div
                  className="info-tiles col-md-2"
                  style={{ backgroundColor: "#0A9396" }}
                  onClick={() => fetchCustomerData("new", 1)}
                >
                  <div
                    className="tiles-heading"
                    data-toggle="tooltip"
                    title="Click here"
                  >
                    New Customers
                  </div>
                  <div className="tiles-body">
                    {/* <CountUp start={0} end= */}
                    {custCountData && custCountData.customer_count.length !== 0
                      ? custCountData.customer_count.find(
                        (item) => item.status == "New"
                      )
                        ? custCountData.customer_count.find(
                          (item) => item.status == "New"
                        ).count
                        : 0
                      : 0}
                    {/* //  duration={5} /> */}
                  </div>
                </div>
                {/* <div
                  className="info-tiles col-md-1"
                  style={{ backgroundColor: "#EE9B00" }}
                  onClick={() => fetchCustomerData("itserve_customer", 1)}
                >
                  <div
                    className="tiles-heading"
                    data-toggle="tooltip"
                    title="Click here"
                  >
                    IT Serve Customers
                  </div>
                  <div className="tiles-body">
                     
                    {itserveCust}
                     
                  </div>
                </div> */}
                <div
                  className="info-tiles col-md-2"
                  style={{ backgroundColor: "#CA6702" }}
                  onClick={() => fetchCustomerData("active", 1)}
                >
                  <div
                    className="tiles-heading"
                    data-toggle="tooltip"
                    title="Click here"
                  >
                    Active Customers
                  </div>
                  <div className="tiles-body">
                    {/* <CountUp start={0} end= */}
                    {custCountData && custCountData.customer_count.length !== 0
                      ? custCountData.customer_count.find(
                        (item) => item.status === "active"
                      )
                        ? custCountData.customer_count.find(
                          (item) => item.status === "active"
                        ).count
                        : 0
                      : 0}
                    {/* duration={5} /> */}
                  </div>
                </div>

                <div
                  className="info-tiles col-md-2"
                  style={{ backgroundColor: "#BB3E03" }}
                  onClick={() => fetchCustomerData("inactive", 1)}
                >
                  <div
                    className="tiles-heading"
                    data-toggle="tooltip"
                    title="Click here"
                  >
                    Inactive Customers
                  </div>
                  <div className="tiles-body">
                    {/* <CountUp start={0} end= */}
                    {custCountData && custCountData.customer_count.length !== 0
                      ? custCountData.customer_count.find(
                        (item) => item.status === "inactive"
                      )
                        ? custCountData.customer_count.find(
                          (item) => item.status === "inactive"
                        ).count
                        : 0
                      : 0}
                    {/* // duration={5} /> */}
                  </div>
                </div>

                {/* <div
                  className="info-tiles col-md-1"
                  style={{ backgroundColor: "#AE2012" }}
                  onClick={() => fetchCustomerData("blocked", 1)}
                >
                  <div
                    className="tiles-heading"
                    data-toggle="tooltip"
                    title="Click here"
                  >
                    Blocked Customers
                  </div>
                  <div className="tiles-body">
                     
                    {custCountData && custCountData.customer_count.length !== 0
                      ? custCountData.customer_count.find(
                        (item) => item.status === "blocked"
                      )
                        ? custCountData.customer_count.find(
                          (item) => item.status === "blocked"
                        ).count
                        : 0
                      : 0}
                     
                  </div>
                </div> */}

                {/* <div
                  className="info-tiles col-md-1"
                  style={{ backgroundColor: "#9B2226" }}
                  onClick={() => fetchCustomerData("defaulter", 1)}
                >
                  <div
                    className="tiles-heading"
                    data-toggle="tooltip"
                    title="Click here"
                  >
                    Defaulter
                  </div>
                  <div className="tiles-body">
                     
                    {defaulterCust}
                     
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-sm-12 col-md-4"></div>
                        <div className="float-start col-md-4">
                          <AutoUserSearch
                            onSelect={handleUserSelect}
                            status={status}
                            key={status}
                            searchKey={searchQuery}
                          />
                        </div>

                        <div className="float-start col-sm-3">
                          {exportPermission &&
                            <button
                              type="button"
                              class="btn btn-success w-md btn-md dropdown-toggle"
                              onClick={exportToExcel}
                            >
                              <i className="mdi mdi-file-export-outline"></i>{" "}
                              Export to Excel
                            </button>
                          }
                        </div>

                        {(addPermission || editPermission || deletePermission) &&
                          <div class="btn-group   mb-3 float-end col-md-1">
                            <button
                              type="button"
                              class="btn btn-primary w-md btn-md dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Action <i class="mdi mdi-chevron-down"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end py-2">
                              {addPermission &&
                                <a
                                  class="dropdown-item"
                                  onClick={() => {
                                    toggleSection("sectionA");
                                    toggleSection("sectionB");
                                  }}
                                >

                                  <i class="bx bx-user"></i> Create Customer
                                </a>
                              }
                              {editPermission &&
                                <a class="dropdown-item" onClick={editRequest}>
                                  <i class="far fa-edit font-size-14"></i> Edit
                                </a>
                              }
                              {deletePermission &&
                                <a class="dropdown-item" onClick={deleteCustomer}>
                                  <i class="bx bx-trash"></i> Delete
                                </a>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>

                    <div className="card-body">
                      <Table
                        id="datatable"
                        className="nowrap w-100 dt-responsive table-responsive table-bordered"
                      >
                        <thead style={{ backgroundColor: "#ededed" }}>
                          <tr>
                            <th>#</th>
                            <th>Username</th>
                            <th>Company Name</th>
                            <th>Billing Info</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Registered Date</th>
                            {/*<th>Plan Name</th>*/}
                            <th>No of DIDs</th>

                            <th>MOR Status</th>
                            <th>M4 Status</th>
                            <th>Status</th>
                            <th>Balance</th>
                          </tr>
                        </thead>
                        {isLoading ? (
                          <div className="loader-overlay text-white">
                            <Loader />
                          </div>
                        ) : (
                          <tbody>
                            {customerData.length > 0 ? (
                              customerData.map((item, index) => (
                                <tr
                                  onClick={() =>
                                    handleTableRowClick(
                                      item,
                                      selectedRow,
                                      setSelectedRow,
                                      selectedRowId,
                                      setSelectedRowId,
                                      item.id
                                    )
                                  }
                                  className={
                                    selectedRowId === item.id
                                      ? "row_selected"
                                      : ""
                                  }
                                >
                                  <th>{pageSlNo + index + 1}</th>
                                  <td>{item.user_name}</td>
                                  <td>{item.company_name}</td>
                                  <td>
                                    {item.cust_address}, {item.cust_state},{" "}
                                    {item.cust_country}
                                  </td>

                                  <td>{item.phone}</td>
                                  <td>{item.email}</td>
                                  <td>
                                    {formattedDateTime(item.register_date)}
                                  </td>

                                  <td>{item.no_of_dids}</td>
                                  <td
                                    className={`font-size-14 fw-semibold ${item.mor_account_id
                                      ? "text-success"
                                      : "text-danger"
                                      }`}
                                  >
                                    {item.mor_account_id
                                      ? "Account created"
                                      : "Account not created"}
                                  </td>
                                  <td
                                    className={`font-size-14 fw-semibold ${item.m4_account_id
                                      ? "text-success"
                                      : "text-danger"
                                      }`}
                                  >
                                    {item.m4_account_id
                                      ? "Account created"
                                      : "Account not created"}
                                  </td>

                                  <td
                                    className={`badge badge-pill p-2 m-2 font-size-12
    																	${transformText(
                                      item.status,
                                      "lowercase"
                                    ) === "active"
                                        ? "bg-success"
                                        : transformText(
                                          item.status,
                                          "lowercase"
                                        ) === "pending"
                                          ? "bg-info"
                                          : transformText(
                                            item.status,
                                            "lowercase"
                                          ) === "inactive"
                                            ? "bg-warning"
                                            : transformText(
                                              item.status,
                                              "lowercase"
                                            ) === "blocked"
                                              ? "bg-danger"
                                              : "bg-primary"
                                      }`}
                                  >
                                    {transformText(item.status, "capitalize")}
                                  </td>
                                  <td
                                    className={`font-size-14 fw-semibold ${item.wallet_amount > 10
                                      ? "text-success"
                                      : "text-danger"
                                      }`}
                                  >
                                    {item.wallet_amount
                                      ? "$" + item.wallet_amount
                                      : "$ 0.000"}
                                  </td>
                                  {/* <td><button className="btn btn-sm btn-default p-0 mx-1" title="View Balance" onClick={(e) => handleEditClick(e, item.id)}><i className='fas fa-solid fa-eye'></i>edit</button></td> */}

                                  {/* Bootstrap Popover*/}
                                  {/* {popoverVisible && (
																		<div className="popover">
																			<div className="popover-header">Edit Balance</div>
																			<div className="popover-body">
																				<p>Current Balance: {balance}</p>
																				<input type="text" value={inputValue} onChange={handleInputChange} />
																				<button onClick={handleSaveClick}>Save</button>
																				<button onClick={handleCancelClick}>Cancel</button>
																			</div>
																		</div>
																	)} */}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="15" className="text-center">
                                  No data available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        )}
                      </Table>

                      {/* Bootstrap Popover */}
                      {/* Bootstrap Popover */}
                      {/* Bootstrap Popover */}
                      <Overlay
                        show={Boolean(showPopper)}
                        target={showPopper}
                        placement="left"
                      >
                        {(props) => (
                          <Popover id="popover-contained" {...props}>
                            <Popover.Header
                              as="div"
                              className="d-flex justify-content-between align-items-center"
                              style={{ backgroundColor: "#f4f4ff" }}
                            >
                              <h5>Update Balance</h5>
                              <i
                                className="bx bx-x"
                                onClick={handleCancelClick}
                                style={{
                                  color: "#001a53",
                                  fontSize: "large",
                                  cursor: "pointer",
                                }}
                              ></i>
                            </Popover.Header>
                            <Popover.Body>
                              <div className="d-flex justify-content-between">
                                {isLoadingBlc ? (
                                  <div className="d-flex">
                                    <Loader />
                                  </div>
                                ) : (
                                  <>
                                    <h6>
                                      Balance:{" "}
                                      {balance ? (
                                        <span className="text-success">
                                          {balance}
                                        </span>
                                      ) : (
                                        <span className="text-danger">
                                          {" "}
                                          Customer does not have wallet balance
                                        </span>
                                      )}
                                    </h6>
                                    {balance && (
                                      <i
                                        className="bx bx-edit mb-4 ms-4"
                                        onClick={() => setShowInput(true)}
                                        style={{
                                          color: "#001a53",
                                          fontSize: "x-large",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    )}
                                  </>
                                )}
                              </div>
                              {showInput && (
                                <div className="d-flex justify-content-between align-items-center">
                                  {/* <input type="text" min={0} max={5555} value={inputValue} onChange={handleInputChange} placeholder="Enter Balance" /> */}
                                  <input
                                    type="text"
                                    minLength={1}
                                    maxLength={4}
                                    value={inputValue}
                                    onChange={(e) => handleInputChange(e)}
                                    onClick={(e) => e.stopPropagation()}
                                    placeholder="Enter Balance"
                                    className="form-control"
                                  />
                                  {inputValue && (
                                    <>
                                      <i
                                        className="bx bx-check"
                                        onClick={handleSaveClick}
                                        style={{
                                          color: "#2ab57d",
                                          fontSize: "x-large",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                      <i
                                        className="bx bx-x ms-2"
                                        onClick={() => setShowInput(false)}
                                        style={{
                                          color: "#fd625e",
                                          fontSize: "x-large",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </>
                                  )}
                                </div>
                              )}
                            </Popover.Body>
                          </Popover>
                        )}
                      </Overlay>
                      <hr />
                      <Pagination {...props} />
                    </div>
                  </div>
                </div>{" "}
                {/* end col */}
              </div>{" "}
              {/* end row */}
            </div>{" "}
            {/* container-fluid */}
            {/* Customers Listing */}
            {/* Customer Creation */}
            <div hidden={!sectionVisible.sectionB} class="container-fluid">
              {/* <!-- start page title --> */}
              <div class="row">
                <div class="col-12">
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">Create Customer</h4>
                    <div class="page-title-right">
                      <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item">
                          <a href="#">Customers</a>
                        </li>
                        <li class="breadcrumb-item">
                          <a href="#">Customer List</a>
                        </li>
                        <li class="breadcrumb-item active">Create Customer</li>
                      </ol>
                    </div>
                  </div>
                  <div className="float-end">
                    <button
                      class="btn btn-warning w-md"
                      type="button"
                      onClick={() => {
                        toggleSection("sectionA");
                        toggleSection("sectionB");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>

              {/* <!-- end page title --> */}
              <div class="row mt-4">
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body p-4">
                      <div class="row">
                        <div class="col-lg-12">
                          {isLoading ? <div className="loader-overlay text-white">
                            <Loader />
                          </div> : null}
                          <div>
                            <form
                              id="creditCardForm"
                              key={2}
                              novalidate="novalidate"
                              enctype="multipart/form-data"
                              onSubmit={handleSubmit(customerSubmit)}
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-Username-input"
                                    >
                                      User Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="user_name"
                                      id="formrow-Username-input"
                                      class="form-control"
                                      placeholder="Enter your User Name"
                                      {...register("user_name", {
                                        required: "Username is required",
                                        // pattern: {
                                        // 	value: /^[a-zA-Z0-9]+$/,
                                        // 	message: 'Username should have only alphabets and numbers',
                                        // },
                                        pattern: {
                                          value:
                                            /^[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"|,.<>/?]+$/, // Adjust the regex pattern to include desired special characters
                                          message:
                                            "Username should have only alphabets, numbers, and special characters.",
                                        },

                                        minLength: MinLengthValidation(8),
                                        maxLength: MaxLengthValidation(25),
                                      })}
                                    />
                                    {errors.user_name && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.user_name.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-Password-input"
                                    >
                                      Password{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Controller
                                      name="password"
                                      control={control}
                                      render={({ field }) => (
                                        <input
                                          {...field}
                                          type="password"
                                          id="formrow-Password-input"
                                          class="form-control"
                                          placeholder="Enter password"
                                          {...register("password", {
                                            required: "password is required",
                                            maxLength: MaxLengthValidation(15),
                                            minLength: MinLengthValidation(6),
                                            pattern: {
                                              value:
                                                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}$/,
                                              message:
                                                "Password must contain at least 6 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.",
                                            },
                                          })}
                                        />
                                      )}
                                    />
                                    {errors.password && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.password.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-CompanyName-input"
                                    >
                                      Company Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="company_name"
                                      id="formrow-CompanyName-input"
                                      class="form-control"
                                      placeholder="Enter your  Company Name"
                                      {...register("company_name", {
                                        required: "Company Name is required",
                                        pattern: {
                                          value: /^[a-zA-Z0-9\s\-.]+$/,
                                          message: "Invalid Company Name format",
                                        }

                                      })}
                                    />
                                    {errors.company_name && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.company_name.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-Email-input"
                                    >
                                      Email{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="email"
                                      name="email"
                                      id="user"
                                      class="form-control"
                                      placeholder="sample@gmail.com"
                                      {...register("email", {
                                        required: "Email is required",
                                        pattern: {
                                          // value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                          value:
                                            /^(?!.*\s)[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                          message: "Invalid Email Id format",
                                        },
                                      })}
                                    />
                                    {errors.email && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.email.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-PhoneNumber-input"
                                    >
                                      {" "}
                                      Phone No.{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="phone"
                                      id="phone"
                                      maxLength={12}
                                      class="form-control"
                                      placeholder="8888888888"
                                      {...register("phone", {
                                        required: "Phone is required",
                                        pattern: {
                                          value: /^[0-9]+$/,
                                          message: "Invalid Phone Number",
                                        },
                                        maxLength: MaxLengthValidation(12),
                                        minLength: MinLengthValidation(10),
                                      })}
                                    />
                                    {errors.phone && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.phone.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-name-input"
                                    >
                                      First Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="first_name"
                                      id="formrow-name-input"
                                      class="form-control"
                                      placeholder="Enter your First Name"
                                      {...register("first_name", {
                                        required: "First Name is required",
                                        pattern: onlyAlphabetsandSpaces,
                                      })}
                                    />
                                    {errors.first_name && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.first_name.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-lname-input"
                                    >
                                      Last Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="last_name"
                                      id="formrow-lname-input"
                                      class="form-control"
                                      placeholder="Enter your Last Name"
                                      {...register("last_name", {
                                        required: "Last Name is required",
                                        pattern: {
                                          value: /^[a-zA-z]{2,30}$/,
                                          message:
                                            "Last Name should have only alphabets",
                                        },
                                      })}
                                    />
                                    {errors.last_name && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.last_name.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-Address-input"
                                    >
                                      Address{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="cust_address"
                                      id="formrow-Address-input"
                                      class="form-control"
                                      placeholder="Enter your  Address"
                                      {...register("cust_address", {
                                        required: "Address is required",
                                        pattern: {
                                          value:
                                            /^([a-zA-z0-9/\\''(),-\s]{2,500})$/,
                                          message:
                                            "Address is not in Correct Format",
                                        },
                                      })}
                                    />
                                    {errors.cust_address && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.cust_address.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-City-input"
                                    >
                                      City{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="cust_city"
                                      id="formrow-City-input"
                                      class="form-control"
                                      placeholder="Enter your City"
                                      {...register("cust_city", {
                                        required: "City is required",
                                        pattern: {
                                          value: /^[a-zA-Z\s]{2,30}$/,
                                          message:
                                            "City should have only alphabets",
                                        },
                                      })}
                                    />
                                    {errors.cust_city && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.cust_city.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-PhoneNumber-input"
                                    >
                                      Country{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Controller
                                      control={control}
                                      name="cust_country"
                                      rules={{
                                        required: "Country is required",
                                      }}
                                      render={({ field }) => (
                                        <select
                                          class="form-select"
                                          name="cust_country"
                                          {...register("cust_country")}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            // console.log(e.target.value);
                                            setSelectedCountryCode(
                                              e.target.value
                                            );
                                            fetchStatesData(e.target.value);
                                          }}
                                          value={field.value}
                                        >
                                          <option value="">Select</option>
                                          {countryData.map((item, index) => (
                                            <option
                                              value={item.country_code_char2}
                                            >
                                              {item.country_name}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                    />
                                    {errors.cust_country && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.cust_country.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-PhoneNumber-input"
                                    >
                                      State{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Controller
                                      control={control}
                                      name="cust_state"
                                      rules={{
                                        required: "State is required",
                                      }}
                                      render={({ field }) => (
                                        <select
                                          class="form-select"
                                          name="cust_state"
                                          {...register("cust_state")}
                                          onChange={(e) => {
                                            setSelectedValue(e.target.value);
                                            unregister("cust_state");
                                          }}
                                          value={selectedValue}
                                        >
                                          <option value="">Select</option>
                                          {filteredStates.map((item, index) => (
                                            <option
                                              selected
                                              value={
                                                item.state_subdivision_name
                                              }
                                            >
                                              {item.state_subdivision_name}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                    />
                                    {errors.cust_state && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.cust_state.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-Zip-input"
                                    >
                                      Zip Code{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="cust_zipcode"
                                      id="formrow-Zip-input"
                                      maxLength={7}
                                      class="form-control"
                                      placeholder="Enter your Zip Code"
                                      {...register("cust_zipcode", {
                                        required: "Zip is required",
                                        pattern: onlyNumbers,
                                        maxLength: MaxLengthValidation(7),
                                        minLength: MinLengthValidation(5),
                                      })}
                                    />
                                    {errors.cust_zipcode && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.cust_zipcode.message}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <input
                                  type="hidden"
                                  name="status"
                                  id="formrow-Zip-input"
                                  class="form-control"
                                  value="active"
                                />

                                {/* <div class="col-md-4">
											<div class="mb-3">
											<label class="form-label" for="formrow-Address-input">  Signed Contract </label>
											<PickAvatarControl name="signed_contract" id="formFile" errors={errors} control={control} params={params1}/>
											{errors.signed_contract && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.signed_contract.message}</div>}
											</div>
										</div>
										<div class="col-md-4">
											<div class="mb-3">
											<label class="form-label" for="formrow-Profile-input">  Profile Id </label>
											<input type="text" name="profile_id" id="formrow-Profile-input" class="form-control" placeholder="Enter your Profile Id" {...register('profile_id', {
												pattern: {
													value: /^[A-Za-z0-9]+$/,
													message: 'Profile Id should have only alphabets',
												},
											})} />
											{errors.profile_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.profile_id.message}</div>}
											</div>
										</div> */}
                              </div>
                              <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                <button
                                  class="btn btn-warning w-md"
                                  type="button"
                                  onClick={() => {
                                    toggleSection("sectionA");
                                    toggleSection("sectionB");
                                    reset();
                                    setSelectedValue("");
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  class="btn btn-primary w-md me-md-2"
                                  type="submit"
                                >
                                  Create
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Customer Creation */}
            {/* Customer Updation */}
            <div hidden={!sectionVisible.sectionC} class="container-fluid">
              {/* <!-- start page title --> */}
              <div class="row">
                <div class="col-12">
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">Edit Customer</h4>
                    <div class="page-title-right">
                      <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item">
                          <a href="#">Customers</a>
                        </li>
                        <li className="breadcrumb-item ">
                          <a href="#">Customer List</a>
                        </li>
                        <li class="breadcrumb-item active">Edit Customer</li>
                      </ol>
                    </div>
                  </div>
                  <div className="float-end">
                    <button
                      class="btn btn-warning w-md"
                      type="button"
                      onClick={() => {
                        toggleSection("sectionA");
                        toggleSection("sectionC");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>

              {/* <!-- end page title --> */}
              <div class="row mt-4">
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body p-4">
                      <div class="row">
                        <div class="col-lg-12">
                          <div>
                            <form
                              id="creditCardForm6"
                              class="row g-3 fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container"
                              novalidate="novalidate"
                              onSubmit={handleSubmit2(customerEditSubmit)}
                            >
                              <div class="row">
                                <h5 className="my-3">Customer Information</h5>
                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-CompanyName-input"
                                  >
                                    Company Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="company_name"
                                    id="formrow-CompanyName-input"
                                    class="form-control"
                                    placeholder="Enter Company Name"
                                    {...register2("company_name", {
                                      required: "Company Name is required",
                                      pattern: {
                                        value: /^[a-zA-Z0-9\s\-.]+$/,
                                        message: "Invalid Company Name format",
                                      },
                                    })}
                                  />
                                  {errors2.company_name && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.company_name.message}
                                    </div>
                                  )}
                                </div>
                                {/*<div class="col-6 col-md-8"></div>*/}
                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-Username-input"
                                  >
                                    User Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="user_name"
                                    id="formrow-Username-input"
                                    disabled={true}
                                    class="form-control"
                                    {...register2("user_name")}
                                    placeholder="Enter User Name"
                                  />
                                  {/* {...register2('user_name', { required: 'Username is required',
																			pattern: {
																				value: /^[A-Za-z0-9_]+$/,
																				message: 'Username should have only alphabets',
																			},
																		})}  */}
                                  {errors2.user_name && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.user_name.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-Password-input"
                                  >
                                    Password{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Controller
                                    name="password"
                                    control={control2}
                                    render={({ field }) => (
                                      <input
                                        {...field}
                                        type="password"
                                        id="formrow-Password-input"
                                        class="form-control"
                                        placeholder="Enter password"
                                        {...register2("password", {
                                          required: "password is required",
                                          maxLength: MaxLengthValidation(15),
                                          minLength: MinLengthValidation(6),
                                          pattern: {
                                            value:
                                              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}$/,
                                            message:
                                              "Password must contain at least 6 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.",
                                          },
                                        })}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          setValue2(
                                            "watch_word",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    )}
                                  />
                                  {errors2.password && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.password.message}
                                    </div>
                                  )}
                                </div>

                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-Email-input"
                                  >
                                    Email{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="email"
                                    name="email"
                                    id="user"
                                    class="form-control"
                                    placeholder="sample@gmail.com"
                                    {...register2("email", {
                                      required: "Email is required",
                                      pattern: {
                                        value:
                                          /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        message: "Invalid Email Id format",
                                      },
                                    })}
                                  />
                                  {errors2.email && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.email.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-PhoneNumber-input"
                                  >
                                    Phone No.{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    maxLength={12}
                                    class="form-control"
                                    placeholder="8888888888"
                                    {...register2("phone", {
                                      required: "Phone is required",
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Invalid Phone Number",
                                      },
                                      maxLength: MaxLengthValidation(12),
                                      minLength: MinLengthValidation(10),
                                    })}
                                  />
                                  {errors2.phone && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.phone.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-name-input"
                                  >
                                    First Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="first_name"
                                    id="formrow-name-input"
                                    class="form-control"
                                    placeholder="Enter your First Name"
                                    {...register2("first_name", {
                                      required: "First Name is required",
                                      pattern: {
                                        value: /^[a-zA-Z]*[\w -]*[a-zA-Z]$/,
                                        message:
                                          "First Name should have only alphabets",
                                      },
                                    })}
                                  />
                                  {errors2.first_name && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.first_name.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-lname-input"
                                  >
                                    Last Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="last_name"
                                    id="formrow-lname-input"
                                    class="form-control"
                                    placeholder="Enter your Last Name"
                                    {...register2("last_name", {
                                      required: "Last Name is required",
                                      pattern: {
                                        value: /^[a-zA-Z]*[\w -]*[a-zA-Z]$/,
                                        message:
                                          "Last Name should have only alphabets",
                                      },
                                    })}
                                  />
                                  {errors2.last_name && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.last_name.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-lname-input"
                                  >
                                    Website{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="website"
                                    id="formrow-lname-input"
                                    class="form-control"
                                    placeholder="Enter Your Website Name"
                                    {...register2("website", {
                                      required: "Website is required",
                                      pattern: {
                                        value:
                                          /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                        message: "Please Enter Valid Website",
                                      },
                                    })}
                                  />
                                  {errors2.website && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.website.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Email-input"
                                  >
                                    Account Email{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="email"
                                    name="account_email"
                                    id="user"
                                    class="form-control"
                                    placeholder="sample@gmail.com"
                                    {...register2("account_email", {
                                      required: "Account Email is required",
                                      pattern: {
                                        value:
                                          /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        message: "Invalid Account Email format",
                                      },
                                    })}
                                  />
                                  {errors2.account_email && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.account_email.message}
                                    </div>
                                  )}
                                </div>
                                <h5 className="my-3">Customer Address</h5>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Address-input"
                                  >
                                    Address{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="cust_address"
                                    id="formrow-Address-input"
                                    class="form-control"
                                    placeholder="Enter your  Address"
                                    {...register2("cust_address", {
                                      required: "Address is required",
                                      pattern: {
                                        value: /^[a-zA-z0-9/. ,-]{2,300}$/,
                                        message:
                                          "Address is not in Correct Format",
                                      },
                                    })}
                                    onChange={() => setSameAddress(false)}
                                  />
                                  {errors2.cust_address && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.cust_address.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-City-input"
                                  >
                                    City <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="cust_city"
                                    id="formrow-City-input"
                                    class="form-control"
                                    placeholder="Enter your City"
                                    {...register2("cust_city", {
                                      required: "City is required",
                                      pattern: {
                                        value: /^[a-zA-Z]*[\w -]*[a-zA-Z]$/,
                                        message:
                                          "City should have only alphabets",
                                      },
                                    })}
                                    onChange={() => setSameAddress(false)}
                                  />
                                  {errors2.cust_city && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.cust_city.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-PhoneNumber-input"
                                  >
                                    Country{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Controller
                                    control={control2}
                                    name="cust_country"
                                    rules={{
                                      required: "Country is required",
                                    }}
                                    render={({ field }) => (
                                      <select
                                        class="form-select"
                                        name="cust_country"
                                        {...register2("cust_country")}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          setSelectCustCntryCode(
                                            e.target.value
                                          );
                                          fetchCustStatesData(e.target.value);
                                          setSameAddress(false); // unchecking the checkbox
                                        }}
                                        value={field.value}
                                      >
                                        <option value="">Select</option>
                                        {countryData.map((item, index) => (
                                          <option
                                            value={item.country_code_char2}
                                          >
                                            {item.country_name}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  />
                                  {errors2.cust_country && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.cust_country.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-PhoneNumber-input"
                                  >
                                    State{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Controller
                                    control={control2}
                                    name="cust_state"
                                    rules={{
                                      required: "State is required",
                                    }}
                                    render={({ field }) => (
                                      <select
                                        class="form-select"
                                        name="cust_state"
                                        {...register2("cust_state")}
                                        onChange={(e) => {
                                          setCustStSelectValue(e.target.value);
                                          unregister2("cust_state");
                                          setSameAddress(false); // unchecking the checkbox
                                        }}
                                        value={custStSelectValue}
                                      >
                                        <option value="">Select</option>
                                        {filteredCustStates.map(
                                          (item, index) => (
                                            <option
                                              selected
                                              value={
                                                item.state_subdivision_name
                                              }
                                            >
                                              {item.state_subdivision_name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    )}
                                  />
                                  {errors2.cust_state && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.cust_state.message}
                                    </div>
                                  )}
                                </div>

                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Zip Code{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="cust_zipcode"
                                    id="formrow-Zip-input"
                                    maxLength={7}
                                    class="form-control"
                                    placeholder="Enter your Zip Code"
                                    {...register2("cust_zipcode", {
                                      required: "Zip is required",
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message: "zip should have only numbers",
                                      },
                                      maxLength: MaxLengthValidation(7),
                                      minLength: MinLengthValidation(5),
                                    })}
                                    onChange={() => setSameAddress(false)}
                                  />
                                  {errors2.cust_zipcode && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.cust_zipcode.message}
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex align-items-center my-3">
                                  <h5> Billing Address</h5>
                                  <p className="d-flex align-items-center ms-3 mb-0">
                                    same as customer address?{" "}
                                    <input
                                      type="checkbox"
                                      className="ms-2"
                                      onChange={handleSameAddressChange}
                                      checked={
                                        sameAddress ? sameAddress : false
                                      }
                                    />
                                  </p>
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Address-input"
                                  >
                                    Address{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="address"
                                    id="formrow-Address-input"
                                    class="form-control"
                                    placeholder="Enter your  Address"
                                    {...register2("address", {
                                      required: "Address is required",
                                      pattern: {
                                        value: /^[a-zA-z0-9/. ,-]{2,300}$/,
                                        message:
                                          "Address should have only alphabets",
                                      },
                                    })}
                                    disabled={sameAddress}
                                  />
                                  {errors2.address && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.address.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-City-input"
                                  >
                                    City <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="city"
                                    id="formrow-City-input"
                                    class="form-control"
                                    placeholder="Enter your City"
                                    {...register2("city", {
                                      required: "City is required",
                                      pattern: {
                                        value: /^[a-zA-Z]*[\w -]*[a-zA-Z]$/,
                                        message:
                                          "City should have only alphabets",
                                      },
                                    })}
                                    disabled={sameAddress}
                                  />
                                  {errors2.city && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.city.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-PhoneNumber-input"
                                  >
                                    Country{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  {/* {sameAddress ? (
																		<input
																			type="text"
																			class="form-control"
																			name="country"
																			{...register2('country')}
																			value={selectedCountryCode}
																			disabled={sameAddress}
																		/>
																	) : ( */}
                                  <Controller
                                    control={control2}
                                    name="country"
                                    rules={{
                                      required: "Country is required",
                                    }}
                                    render={({ field }) => (
                                      <select
                                        class="form-select"
                                        name="country"
                                        {...register2("country")}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          setSelectedCountryCode(
                                            e.target.value
                                          );
                                          fetchStatesData(e.target.value); // Set the value of field2
                                        }}
                                        value={field.value}
                                        disabled={sameAddress}
                                      >
                                        <option value="">Select</option>
                                        {countryData.map((item, index) => (
                                          <option
                                            value={item.country_code_char2}
                                          >
                                            {item.country_name}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  />
                                  {/* )} */}

                                  {errors2.country && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.country.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-PhoneNumber-input"
                                  >
                                    State{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  {sameAddress ? (
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="state"
                                      {...register2("state")}
                                      onChange={(e) => {
                                        setSelectedValue(e.target.value);
                                        unregister2("state");
                                      }}
                                      value={selectedValue}
                                      disabled={sameAddress}
                                    />
                                  ) : (
                                    <select
                                      class="form-select"
                                      name="state"
                                      {...register2("state")}
                                      onChange={(e) => {
                                        setSelectedValue(e.target.value);
                                        unregister2("state");
                                      }}
                                      value={selectedValue}
                                    // disabled={sameAddress}
                                    >
                                      <option value="">Select</option>
                                      {filteredStates.map((item, index) => (
                                        <option
                                          key={index}
                                          value={item.state_subdivision_name}
                                        >
                                          {item.state_subdivision_name}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                  {errors2.state && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.state.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Zip Code{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="zipcode"
                                    id="formrow-Zip-input"
                                    maxLength={7}
                                    class="form-control"
                                    placeholder="Enter Zip Code"
                                    {...register2("zipcode", {
                                      required: "Zip Code is required",
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message:
                                          "Zip Code should have only numbers",
                                      },
                                      maxLength: MaxLengthValidation(7),
                                      minLength: MinLengthValidation(4),
                                    })}
                                    disabled={sameAddress}
                                  />
                                  {errors2.zipcode && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.zipcode.message}
                                    </div>
                                  )}
                                </div>
                                <h5 className="my-3">Plan Information</h5>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-PhoneNumber-input"
                                  >
                                    Plan Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Controller
                                    control={control2}
                                    name="plan_id"
                                    rules={{
                                      required: "Plan Name is required",
                                    }}
                                    render={({ field }) => (
                                      <select
                                        class="form-select"
                                        name="plan_id"
                                        {...register2("plan_id")}
                                        disabled={true}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          // console.log(e.target.value);
                                          //setSelectedCountryCode(e.target.value);
                                          //fetchStatesData(e.target.value); // Set the value of field2
                                        }}
                                        value={field.value}
                                      >
                                        <option value="">Select</option>
                                        {/*<option value="2">Hosted PBX Platinum</option>
																				<option value="4">Hosted PBX Premium</option>
																				<option value="5">Hosted PBX Standard</option>*/}
                                        <option value="1" selected>
                                          Pay As You Go
                                        </option>
                                        {/*<option value="3">Small Business</option>*/}
                                        {/* {countryData.map((item,index) => (
															<option value={item.country_code_char2}>{item.country_name}</option>
														))} */}
                                      </select>
                                    )}
                                  />
                                  {errors2.plan_id && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.plan_id.message}
                                    </div>
                                  )}
                                </div>
                                {/* <div class="col-6 col-md-3 mb-3" >

																	<label class="form-label" for="formrow-PhoneNumber-input">
																		India Calling<span style={{ color: 'red' }}>*</span>
																	</label>
																	<div class="col-6 col-md-8">
																		<span class="mx-2"><label><input type="radio" value="YES" {...register2("india_calling", { required: 'India Calling is required' })} /> Enable</label></span>
																		<span class="mx-2"><label><input type="radio" value="NO" {...register2("india_calling", { required: 'India Calling is required' })} /> Disable</label></span>
																	</div>
																	{errors2.india_calling && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.india_calling.message}</div>}
																</div> */}
                                <div class="col-6 col-md-3 mb-3">
                                  <div class="row">
                                    <label
                                      class="form-label"
                                      for="formrow-PhoneNumber-input"
                                    >
                                      Customer Status{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div class="col-6 col-md-8">
                                      <span class="mx-2">
                                        <label>
                                          <input
                                            type="radio"
                                            value="active"
                                            {...register2("status", {
                                              required:
                                                "Customer Status is required",
                                            })}
                                          />{" "}
                                          Active
                                        </label>
                                      </span>
                                      <span class="mx-2">
                                        <label>
                                          <input
                                            type="radio"
                                            value="inactive"
                                            {...register2("status", {
                                              required:
                                                "Customer Status is required",
                                            })}
                                          />{" "}
                                          Inactive
                                        </label>
                                      </span>
                                      <span class="mx-2">
                                        <label>
                                          <input
                                            type="radio"
                                            value="blocked"
                                            {...register2("status", {
                                              required:
                                                "Customer Status is required",
                                            })}
                                          />{" "}
                                          Blocked
                                        </label>
                                      </span>
                                    </div>
                                  </div>
                                  {errors2.status && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.status.message}
                                    </div>
                                  )}
                                </div>
                                <div
                                  class="col-6 col-md-3 mb-3  "
                                  style={{ display: "none" }}
                                >
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Number of Lines MSA{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="no_of_lines_msa"
                                    id="formrow-no_of_lines_msa-input"
                                    class="form-control"
                                    placeholder="Enter your no_of_lines_msa Code"
                                    {...register2("no_of_lines_msa", {
                                      required: "Number of lines is required",
                                      pattern: {
                                        value: /^[A-Za-z0-9]+$/,
                                        message:
                                          "Number of Lines MSA should have only numbers and alphabets",
                                      },
                                    })}
                                  />
                                  {errors2.no_of_lines_msa && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.no_of_lines_msa.message}
                                    </div>
                                  )}
                                </div>
                                <div
                                  class="col-6 col-md-3 mb-3  "
                                  style={{ display: "none" }}
                                >
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    No. of Lines
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="no_of_lines"
                                    id="formrow-no_of_lines-input"
                                    class="form-control"
                                    placeholder="Enter No of Lines"
                                    {...register2("no_of_lines", {
                                      // required: 'No of Lines is required',
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message:
                                          "No of Lines should have only numbers",
                                      },
                                    })}
                                  />
                                  {errors2.no_of_lines && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.no_of_lines.message}
                                    </div>
                                  )}
                                </div>
                                <div
                                  class="col-6 col-md-3 mb-3  "
                                  style={{ display: "none" }}
                                >
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Line Cost
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="lines_cost"
                                    id="formrow-lines_cost-input"
                                    class="form-control"
                                    placeholder="Enter Line Cost"
                                    {...register2("lines_cost", {
                                      // required: 'Line Cost is required',
                                      pattern: {
                                        value: /^\d+(\.\d{1,2})?$/,
                                        message:
                                          "Line Cost should have only numbers with two decimals",
                                      },
                                    })}
                                    allow-decimal-numbers
                                  />
                                  {errors2.lines_cost && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.lines_cost.message}
                                    </div>
                                  )}
                                </div>
                                {/* <div class="col-6 col-md-3 mb-3  ">
																	<label class="form-label" for="formrow-Zip-input">Number of Rental Phones<span style={{ color: 'red' }}>*</span></label>
																	<input type="text" name="num_phones_rental" id="formrow-num_phones_rental-input" class="form-control" placeholder="Enter Number of Rental Phone" {...register2('num_phones_rental', {
																		required: 'Number of Rental Phone is required',
																		pattern: {
																			value: /^[0-9]+$/,
																			message: 'Number of Rental Phone should have only numbers',
																		},
																	})} />
																	{errors2.num_phones_rental && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.num_phones_rental.message}</div>}
																</div> */}
                                {/* <div class="col-6 col-md-3 mb-3  ">
																	<label class="form-label" for="formrow-Zip-input">Phone Rental<span style={{ color: 'red' }}>*</span></label>
																	<input type="text" name="cost_phone_rental" id="formrow-cost_phone_rental-input" class="form-control" placeholder="Enter Phone Rental" {...register2('cost_phone_rental', {
																		required: 'Phone Rental is required',
																		pattern: {
																			value: /^\d+(\.\d{1,2})?$/,
																			message: 'cost phone rental should have only numbers with two decimals',
																		},
																	})} allow-decimal-numbers />
																	{errors2.cost_phone_rental && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.cost_phone_rental.message}</div>}
																</div> */}
                                {/* <div class="col-6 col-md-3 mb-3  ">
																	<label class="form-label" for="formrow-Zip-input">Incoming Lines<span style={{ color: 'red' }}>*</span></label>
																	<input type="text" name="incoming_lines" id="formrow-incoming_lines-input" class="form-control" placeholder="Enter Incoming Lines" {...register2('incoming_lines', {
																		required: 'Incoming Lines is required',
																		pattern: {
																			value: /^[0-9]+$/,
																			message: 'Incoming Lines should have only numbers',
																		},
																	})} />
																	{errors2.incoming_lines && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.incoming_lines.message}</div>}
																</div> */}
                                {/* <div class="col-6 col-md-3 mb-3  ">
																	<label class="form-label" for="formrow-Zip-input">Incoming Line Cost<span style={{ color: 'red' }}>*</span></label>
																	<input type="text" name="incoming_lines_cost" id="formrow-incoming_lines_cost-input" class="form-control" placeholder="Enter Incoming Line Cost" {...register2('incoming_lines_cost', {
																		required: 'Incoming Line Cost is required',
																		pattern: {
																			value: /^\d+(\.\d{1,2})?$/,
																			message: 'Incoming Line Cost should have only numbers with two decimal',
																		},
																	})} allow-decimal-numbers />
																	{errors2.incoming_lines_cost && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.incoming_lines_cost.message}</div>}
																</div> */}
                                {/* <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Paid DIDs{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="paid_dids"
                                    id="formrow-paid_dids-input"
                                    class="form-control"
                                    placeholder="Enter Paid DIDs"
                                    {...register2("paid_dids", {
                                      required: "Paid DIDs is required",
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message:
                                          "Paid DIDs should have only numbers",
                                      },
                                    })}
                                  />
                                  {errors2.paid_dids && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.paid_dids.message}
                                    </div>
                                  )}
                                </div> */}
                                {/* <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    DID Cost{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="dids_cost"
                                    id="formrow-dids_cost-input"
                                    class="form-control"
                                    placeholder="Enter DID Cost"
                                    {...register2("dids_cost", {
                                      required: "DID Cost is required",
                                      pattern: {
                                        value: /^\d+(\.\d{1,2})?$/,
                                        message:
                                          "DID Cost should have only numbers with two decimal",
                                      },
                                    })}
                                    allow-decimal-numbers
                                  />
                                  {errors2.dids_cost && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.dids_cost.message}
                                    </div>
                                  )}
                                </div> */}
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Plan Tax{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="plan_tax"
                                    id="formrow-plan_tax-input"
                                    class="form-control"
                                    placeholder="Enter Plan Tax"
                                    {...register2("plan_tax", {
                                      required: "Plan Tax is required",
                                      pattern: {
                                        value: /^\d+(\.\d{1,2})?$/,
                                        message:
                                          "Plan Tax should have only numbers with two decimal",
                                      },
                                    })}
                                    allow-decimal-numbers
                                  />
                                  {errors2.plan_tax && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.plan_tax.message}
                                    </div>
                                  )}
                                </div>
                                {/* <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    International DIDs{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="no_of_international_dids"
                                    id="formrow-international_dids-input"
                                    class="form-control"
                                    placeholder="Enter International DIDs"
                                    {...register2("no_of_international_dids", {
                                      required:
                                        "International Dids are required",
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message:
                                          "International DIDs should have only numbers",
                                      },
                                    })}
                                  />
                                  {errors2.no_of_international_dids && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.no_of_international_dids.message}
                                    </div>
                                  )}
                                </div> */}
                                {/* <div class="col-6 col-md-3 mb-3  ">
																	<label class="form-label" for="formrow-Zip-input">Bill Date</label>
																	<input type="text" name="bill_date" id="formrow-bill_date-input" class="form-control" placeholder="Enter Bill Date" {...register2('bill_date', {
																		pattern: {
																			value: /^[0-9]+$/,
																			message: 'Bill Date should have only numbers',
																		},
																	})} />
																	{errors2.bill_date && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.bill_date.message}</div>}
																</div> */}
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Sub Users Limit
                                  </label>
                                  <input
                                    type="text"
                                    name="sub_user"
                                    id="formrow-sub_user-input"
                                    class="form-control"
                                    placeholder="Enter Sub Users Limit"
                                    {...register2("sub_user", {
                                      required: "Subusers list is required",
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message:
                                          "Sub Users Limit should have only numbers",
                                      },
                                    })}
                                  />
                                  {errors2.sub_user && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.sub_user.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Group of Companies{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Controller
                                    control={control2}
                                    name="group_status"
                                    rules={{
                                      required: "Group Status is required",
                                    }}
                                    render={({ field }) => (
                                      <select
                                        class="form-select"
                                        name="group_status"
                                        {...register2("group_status")}
                                        onChange={(e) => {
                                          field.onChange(e);
                                        }}
                                        value={field.value}
                                      >
                                        <option value="">Select</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                      </select>
                                    )}
                                  />
                                  {errors2.group_status && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.group_status.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Parent Company{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="group_parent_name"
                                    id="formrow-group_parent_name-input"
                                    class="form-control"
                                    placeholder="Enter Parent Company"
                                    {...register2("group_parent_name", {
                                      required:
                                        "Parent Company name is required",
                                      pattern: {
                                        value: /^[a-zA-Z]*[\w -]*[a-zA-Z]$/,
                                        message:
                                          "Parent Company should have only alphabets",
                                      },
                                    })}
                                  />
                                  {errors2.group_parent_name && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.group_parent_name.message}
                                    </div>
                                  )}
                                </div>
                                {/* <div class="col-6 col-md-3 mb-3  ">
																	<label class="form-label" for="formrow-Zip-input">Contract Status</label>
																
																	<Controller
																		control={control2}
																		name="contract_status"
																		rules={{
																			//required: "Contract Status is required",
																		}}
																		render={({ field }) => (
																			<select class="form-select" name="contract_status" {...register2('contract_status')} onChange={(e) => {
																				field.onChange(e);
																			}} value={field.value}>
																				<option value="">Select</option>
																				<option value="Y">YES</option>
																				<option value="N">No</option>
																				
																			</select>
																		)}
																	/>
																	{errors2.contract_status && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.contract_status.message}</div>}
																</div> */}
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    About Vitel{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="about_vitel"
                                    id="formrow-about_vitel-input"
                                    disabled
                                    class="form-control"
                                    placeholder="Enter About Vitel"
                                    {...register2("about_vitel", {
                                      required: "About Vitel is required",
                                      pattern: {
                                        value: /^[a-zA-Z]*[\w -]*[a-zA-Z]$/,
                                        message:
                                          "About Vitel should have only alphabets",
                                      },
                                    })}
                                  />
                                  {errors2.about_vitel && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.about_vitel.message}
                                    </div>
                                  )}
                                </div>
                                {/* <div class="col-6 col-md-3 mb-3  ">
																	<label class="form-label" for="formrow-Zip-input">Device & Users Company</label>
																	<Controller
																		control={control2}
																		name="device_user"
																		rules={{
																			//required: "Device & Users Company is required",
																		}}
																		render={({ field }) => (
																			<select class="form-select" name="device_user" {...register2('device_user')} onChange={(e) => {
																				field.onChange(e);
																			}} value={field.value}>
																				<option value="">Select</option>
																				<option value="Y">YES</option>
																				<option value="N">No</option>
																			</select>
																		)}
																	/>
																	{errors2.device_user && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.device_user.message}</div>}
																</div> */}
                                {/* <div class="col-6 col-md-3 mb-3  ">
																	<label class="form-label" for="formrow-Zip-input">User Type</label>
																	<input type="text" name="user_type" id="formrow-user_type-input" class="form-control" placeholder="Enter User Type" {...register2('user_type', {
																		pattern: {
																			value: /^[A-Za-z0-9]+$/,
																			message: 'User Type should have only numbers',
																		},
																	})} />
																	{errors2.user_type && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.user_type.message}</div>}
																</div> */}
                                {/* <div class="col-6 col-md-3 mb-3  ">
																	<label class="form-label" for="formrow-Zip-input">Customer Plan Type</label>
																	<input type="text" name="customer_plan" id="formrow-customer_plan-input" class="form-control" placeholder="Enter Customer Plan Type" {...register2('customer_plan', {
																		pattern: {
																			value: /^[A-Za-z0-9]+$/,
																			message: 'Customer Plan Type should have only numbers',
																		},
																	})} />
																	{errors2.customer_plan && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.customer_plan.message}</div>}
																</div> */}
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Reseller{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="reseller"
                                    id="formrow-reseller-input"
                                    class="form-control"
                                    placeholder="Enter Reseller"
                                    {...register2("reseller", {
                                      required: "Reseller is required",
                                      pattern: {
                                        value: /^[a-zA-Z]*[\w -]*[a-zA-Z]$/,
                                        message:
                                          "Reseller should have only alphabets",
                                      },
                                    })}
                                  />
                                  {errors2.reseller && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.reseller.message}
                                    </div>
                                  )}
                                </div>

                                <div className="col-6 col-md-3 mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="formrow-sales_person-input"
                                  >
                                    Sales Person{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <select
                                    name="sales_person"
                                    id="formrow-sales_person-input"
                                    className="form-select"
                                    {...register2("sales_person", {
                                      required: "Sales Person is required",
                                    })}
                                  >
                                    <option value="">
                                      Select Sales Person
                                    </option>
                                    {salesPersons?.map((person, index) => (
                                      <option
                                        key={index}
                                        value={person.username}
                                      >
                                        {person.username}
                                      </option>
                                    ))}
                                  </select>
                                  {errors2.sales_person && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.sales_person.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Billing Month{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Controller
                                    control={control2}
                                    name="billing_months"
                                    rules={{
                                      required: "Billing is required",
                                    }}
                                    render={({ field }) => (
                                      <select
                                        class="form-select"
                                        name="billing_months"
                                        {...register2("billing_months")}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          // console.log(e.target.value);
                                          //setSelectedCountryCode(e.target.value);
                                          //fetchStatesData(e.target.value); // Set the value of field2
                                        }}
                                        value={field.value}
                                      >
                                        <option value="">Select</option>
                                        <option value="1">January</option>
                                        <option value="2">February</option>
                                        <option value="3">March</option>
                                        <option value="4">April</option>
                                        <option value="5">May</option>
                                        <option value="6">June</option>
                                        <option value="7">July</option>
                                        <option value="8">August</option>
                                        <option value="9">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                        {/* {countryData.map((item,index) => (
																					<option value={item.country_code_char2}>{item.country_name}</option>
																				))} */}
                                      </select>
                                    )}
                                  />
                                  {/*<input type="text" name="billing_months" id="formrow-billing_months-input" class="form-control" placeholder="Enter Billing Month" {...register2('billing_months', {
																		pattern: {
																			value: /^[A-Za-z0-9]+$/,
																			message: 'Billing Month should have only alphabets and numbers',
																		},
																	})} />*/}
                                  {errors2.billing_months && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.billing_months.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3 d-flex flex-column">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Next Billdate{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Controller
                                    control={control2}
                                    name="bill_date"
                                    render={({ field }) => (
                                      <DatePicker
                                        className="form-control"
                                        placeholderText="YYYY-MM-DD"
                                        dateFormat="yyyy-MM-dd" // Set the date format
                                        selected={
                                          field.value
                                            ? new Date(field.value)
                                            : null
                                        } // Format the date value
                                        {...register2("bill_date", {
                                          required: "Bill Date is required",
                                          pattern: {
                                            value: /^[0-9/-]*$/,
                                            message: "Invalid Date format",
                                          },
                                        })}
                                        onChange={(date) => {
                                          // console.log("next-billing", date);
                                          field?.onChange(date);
                                        }}
                                        minDate={minDate}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                      />
                                    )}
                                  />
                                  {errors2.bill_date && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.bill_date.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    ITServe Customer{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  {/* <input type="text" name="itserve_customer" id="formrow-Zip-input" class="form-control" placeholder="Enter your Zip Code" {...register2('itserve_customer', { required: 'Zip is required',
													pattern: {
														value: /^[A-Za-z0-9]+$/,
														message: 'zip should have only numbers',
													},
												})} /> */}
                                  <Controller
                                    control={control2}
                                    name="itserve_customer"
                                    rules={{
                                      required: "ITServe Customer is required",
                                    }}
                                    render={({ field }) => (
                                      <select
                                        class="form-select"
                                        name="itserve_customer"
                                        {...register2("itserve_customer")}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          // console.log(e.target.value);
                                          //setSelectedCountryCode(e.target.value);
                                          //fetchStatesData(e.target.value); // Set the value of field2
                                        }}
                                        value={field.value}
                                      >
                                        <option value="">Select</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                        {/* {countryData.map((item,index) => (
															<option value={item.country_code_char2}>{item.country_name}</option>
														))} */}
                                      </select>
                                    )}
                                  />
                                  {errors2.itserve_customer && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.itserve_customer.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Type of Invoice{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>

                                  <Controller
                                    control={control2}
                                    name="type_of_invoice"
                                    rules={{
                                      required: "Type of Invoice is required",
                                    }}
                                    render={({ field }) => (
                                      <select
                                        class="form-select"
                                        name="type_of_invoice"
                                        {...register2("type_of_invoice")}
                                        onChange={(e) => {
                                          field.onChange(e);
                                        }}
                                        value={field.value}
                                      >
                                        <option value="">Select</option>
                                        <option value="Individual Invoice">
                                          Individual Invoice
                                        </option>
                                        <option value="Parent Invoice">
                                          Parent Invoice
                                        </option>
                                      </select>
                                    )}
                                  />
                                  {errors2.type_of_invoice && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.type_of_invoice.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    ITServe Chapter{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="itserve_chapter"
                                    id="formrow-itserve_chapter-input"
                                    class="form-control"
                                    placeholder="Enter ITServe Chapter"
                                    {...register2("itserve_chapter", {
                                      required: "ITServe Chapter is required",
                                      pattern: {
                                        value: /^[A-Za-z0-9]+$/,
                                        message:
                                          "ITServe Chapter should have only numbers and alphabets",
                                      },
                                    })}
                                  />
                                  {errors2.itserve_chapter && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.itserve_chapter.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    MSA Status{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>

                                  <Controller
                                    control={control2}
                                    name="msa_status"
                                    rules={{
                                      required: "MSA Status is required",
                                    }}
                                    render={({ field }) => (
                                      <select
                                        class="form-select"
                                        name="msa_status"
                                        {...register2("msa_status")}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          // console.log(e.target.value);
                                          //setSelectedCountryCode(e.target.value);
                                          //fetchStatesData(e.target.value); // Set the value of field2
                                        }}
                                        value={field.value}
                                      >
                                        <option value="">Select</option>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                        {/* {countryData.map((item,index) => (
															<option value={item.country_code_char2}>{item.country_name}</option>
														))} */}
                                      </select>
                                    )}
                                  />
                                  {errors2.msa_status && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.msa_status.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Defaulter{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Controller
                                    control={control2}
                                    name="defaulter_status"
                                    rules={{
                                      required: "Defaulter Status is required",
                                    }}
                                    render={({ field }) => (
                                      <select
                                        class="form-select"
                                        name="defaulter_status"
                                        {...register2("defaulter_status")}
                                        onChange={(e) => {
                                          field.onChange(e);
                                        }}
                                        value={field.value}
                                      >
                                        <option value="">Select</option>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </select>
                                    )}
                                  />
                                  {errors2.defaulter_status && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.defaulter_status.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-Zip-input"
                                  >
                                    Card Type{" "}

                                  </label>

                                  <Controller
                                    control={control2}
                                    name="type"
                                    rules={{
                                      // required: "Type is required",
                                    }}
                                    render={({ field }) => (
                                      <select
                                        class="form-select"
                                        name="type"
                                        {...register2("type")}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          // console.log(e.target.value);
                                          //setSelectedCountryCode(e.target.value);
                                          //fetchStatesData(e.target.value); // Set the value of field2
                                        }}
                                        value={field.value}
                                      >
                                        <option value="">Select</option>
                                        <option value="Card">Card</option>
                                        <option value="Bank">Bank</option>
                                        {/* {countryData.map((item,index) => (
															<option value={item.country_code_char2}>{item.country_name}</option>
														))} */}
                                      </select>
                                    )}
                                  />
                                  {errors2.type && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.type.message}
                                    </div>
                                  )}
                                </div>
                                {watch2('type') &&
                                  <>
                                    <div class="col-6 col-md-3 mb-3">
                                      <label
                                        class="form-label"
                                        for="formrow-Zip-input"
                                      >
                                        Customer Profile ID
                                        {/* <span style={{ color: 'red' }}>*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        name="customer_profile_id"
                                        id="formrow-customer_profile_id-input"
                                        class="form-control"
                                        placeholder="Enter Customer Profile ID"
                                        {...register2("customer_profile_id", {
                                          // required: 'Customer Profile ID is required',
                                          pattern: {
                                            value: /^[0-9]+$/,
                                            message:
                                              "Customer Profile ID should have only numbers",
                                          },
                                        })}
                                      />
                                      {errors2.customer_profile_id && (
                                        <div
                                          style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                          }}
                                        >
                                          {errors2.customer_profile_id.message}
                                        </div>
                                      )}
                                    </div>
                                    <div class="col-6 col-md-3 mb-3">
                                      <label
                                        class="form-label"
                                        for="formrow-Zip-input"
                                      >
                                        Customer Payment ID
                                        {/* <span style={{ color: 'red' }}>*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        name="payment_profile_id"
                                        id="formrow-payment_profile_id-input"
                                        class="form-control"
                                        placeholder="Enter Customer Payment ID"
                                        {...register2("payment_profile_id", {
                                          // required: 'Customer Payment ID  is required',
                                          pattern: {
                                            value: /^[0-9]+$/,
                                            message:
                                              "Customer Payment ID should have only numbers",
                                          },
                                        })}
                                      />
                                      {errors2.payment_profile_id && (
                                        <div
                                          style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                          }}
                                        >
                                          {errors2.payment_profile_id.message}
                                        </div>
                                      )}
                                    </div>

                                    <div class="col-6 col-md-3 mb-3">
                                      <label
                                        class="form-label"
                                        for="formrow-Zip-input"
                                      >
                                        Customer Address ID
                                        {/* <span style={{ color: 'red' }}>*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        name="customer_address_id"
                                        id="formrow-customer_address_id-input"
                                        maxLength={10}
                                        class="form-control"
                                        placeholder="Enter Customer Address ID"
                                        {...register2("customer_address_id", {
                                          // required: 'Customer Payment ID  is required',
                                          pattern: {
                                            value: /^[0-9]+$/,
                                            message:
                                              "Customer Address ID should have only numbers",
                                          },
                                          maxLength: MaxLengthValidation(10),
                                          minLength: MinLengthValidation(9),

                                        })}
                                      />
                                      {errors2.customer_address_id && (
                                        <div
                                          style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                          }}
                                        >
                                          {errors2.customer_address_id.message}
                                        </div>
                                      )}
                                    </div>
                                    <div class="col-6 col-md-3 mb-3">
                                      <label
                                        class="form-label"
                                        for="formrow-Zip-input"
                                      >
                                        Subscription ID
                                        {/* <span style={{ color: 'red' }}>*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        name="subscription_id"
                                        id="formrow-subscription_id-input"
                                        maxLength={10}
                                        class="form-control"
                                        placeholder="Enter Customer Payment ID"
                                        {...register2("subscription_id", {
                                          // required: 'Customer Payment ID  is required',
                                          pattern: {
                                            value: /^[0-9]+$/,
                                            message:
                                              "Subscription ID should have only numbers",
                                          },
                                          maxLength: MaxLengthValidation(10),
                                          minLength: MinLengthValidation(5),

                                        })}
                                      />
                                      {errors2.subscription_id && (
                                        <div
                                          style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                          }}
                                        >
                                          {errors2.subscription_id.message}
                                        </div>
                                      )}

                                    </div>
                                  </>
                                }




                                {/* <div class="col-6 col-md-3 mb-3">
																	<label class="form-label" for="formrow-Zip-input">M4 Account ID <span style={{ color: 'red' }}>*</span></label>
																	<input type="text" name="m4_account_id" id="formrow-m4_account_id-input" class="form-control" placeholder="Enter M4 Account ID" {...register2('m4_account_id', {
																		required: 'M4 Account ID is required',
																		pattern: {
																			value: /^[0-9]+$/,
																			message: 'M4 Account ID should have only numbers',
																		},
																	})} />
																	{errors2.m4_account_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.m4_account_id.message}</div>}
																</div>
																<div class="col-6 col-md-3 mb-3">
																	<label class="form-label" for="formrow-Zip-input">MOR Account ID <span style={{ color: 'red' }}>*</span></label>
																	<input type="text" name="mor_account_id" id="formrow-mor_account_id-input" class="form-control" placeholder="Enter MOR AccountID" {...register2('mor_account_id', {
																		required: 'MOR Account ID is required',
																		pattern: {
																			value: /^[0-9]+$/,
																			message: 'MOR Account ID should have only numbers',
																		},
																	})} />
																	{errors2.mor_account_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.mor_account_id.message}</div>}
																</div> */}

                                <h5 className="my-3">CEO Information</h5>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Address-input"
                                  >
                                    CEO Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="ceo"
                                    id="formrow-ceo-input"
                                    class="form-control"
                                    placeholder="Enter your  ceo"
                                    {...register2("ceo", {
                                      required: "ceo is required",
                                      pattern: {
                                        value: /^[a-zA-Z]*[\w -]*[a-zA-Z]$/,
                                        message: "CEO is not in Correct Format",
                                      },
                                    })}
                                  />
                                  {errors2.ceo && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.ceo.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-City-input"
                                  >
                                    CEO Email{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="ceo_mail"
                                    id="formrow-ceo_mail-input"
                                    class="form-control"
                                    placeholder="Enter your CEO Mail"
                                    {...register2("ceo_mail", {
                                      required: "CEO Mail is required",
                                      pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
                                        message:
                                          "CEO Mail should have valid format",
                                      },
                                    })}
                                  />
                                  {errors2.ceo_mail && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.ceo_mail.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3  ">
                                  <label
                                    class="form-label"
                                    for="formrow-Address-input"
                                  >
                                    CEO Phone No.{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="ceo_phone"
                                    id="formrow-ceo_phone-input"
                                    maxLength={12}
                                    class="form-control"
                                    placeholder="Enter Your CEO Phone"
                                    {...register2("ceo_phone", {
                                      required: "CEO Phone is required",
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message:
                                          "CEO Phone is not in Correct Format",
                                      },
                                      maxLength: MaxLengthValidation(12),
                                      minLength: MinLengthValidation(10),
                                    })}
                                  />
                                  {errors2.ceo_phone && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.ceo_phone.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <label
                                    class="form-label"
                                    for="formrow-City-input"
                                  >
                                    Federal ID{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="hidden"
                                    name="code"
                                    value="2565"
                                    id="formrow-payment_profile_id-input"
                                    class="form-control"
                                    {...register2("code")}
                                  ></input>
                                  <input
                                    type="text"
                                    name="federal_id"
                                    id="formrow-ceo_mail-input"
                                    class="form-control"
                                    placeholder="Enter your Federal Id"
                                    {...register2("federal_id", {
                                      required: "Federal ID is required",
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message:
                                          "Federal Id should have only numbers",
                                      },
                                      minLength: MinLengthValidation(6),
                                      maxLength: MaxLengthValidation(12),
                                    })}
                                  />
                                  {errors2.federal_id && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors2.federal_id.message}
                                    </div>
                                  )}
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <div class="mb-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <label className="form-label" for="paymentName">
                                        Driver License ID
                                      </label>
                                      <span className="font-size-12 text-primary">Allowed file types: jpeg/png.</span>
                                    </div>


                                    <input
                                      type="file"
                                      name="drivers_license"
                                      className="form-control"
                                      {...register2(
                                        "drivers_license",

                                        {
                                          // required: 'Driver License is required',
                                          /*{
                                        validate: () => {
                                          if (!Imgname) {
                                            return 'Profile Image is required';
                                          }
                                          return true;
                                          },
                                      }*/
                                        }
                                      )}
                                      onChange={(e) => handleFileChange(e)}
                                    />
                                    {errors2.driver_licence && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors2.driver_licence.message}
                                      </div>
                                    )}
                                    {/* <img src={base64Img} width="100px" /> */}
                                    {fileNameDl && (
                                      <>
                                        {fileNameDl.endsWith('.png') || fileNameDl.endsWith('.jpeg') || fileNameDl.endsWith('.JPEG') || fileNameDl.endsWith('.PNG') || fileNameDl.endsWith('.jpg') || fileNameDl.endsWith('.JPG') ? (
                                          <div>
                                            <img src={base64Img} alt="File Preview" style={{ width: '100px' }} />
                                            <a href="#/" onClick={() => handleDownload(base64Img)}>
                                              {fileNameDl}
                                            </a>
                                          </div>
                                        ) : (
                                          <a href="#/" onClick={() => handleDownload(base64Img)}>
                                            Download {fileNameDl}
                                          </a>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <div class="mb-3">

                                    <div className="d-flex justify-content-between align-items-center">
                                      <label className="form-label" for="paymentName">
                                        Signed address proof
                                      </label>
                                      <span className="font-size-12 text-primary">Allowed file types: jpeg/png.</span>
                                    </div>

                                    <input
                                      type="file"
                                      name="address_proof"
                                      className="form-control"
                                      {...register2("address_proof", {
                                        // required: 'Signed address proof of signing is required',
                                        /*{
                                        validate: () => {
                                          if (!Imgname) {
                                            return 'Profile Image is required';
                                          }
                                          return true;
                                          },
                                      }*/
                                      })}
                                      onChange={(e) => handleFileChange1(e)}
                                    />
                                    {errors2.address_proof && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors2.address_proof.message}
                                      </div>
                                    )}

                                    {/* <img src={base64Imgsign} width="100px" /> */}
                                    {fileNameSign && (
                                      <>
                                        {fileNameSign.endsWith('.png') || fileNameSign.endsWith('.jpeg') || fileNameSign.endsWith('.JPEG') || fileNameSign.endsWith('.PNG') || fileNameDl.endsWith('.jpg') || fileNameDl.endsWith('.JPG') ? (
                                          <div>
                                            <img src={base64Imgsign} alt="File Preview" style={{ width: '100px' }} />
                                            <a href='#/' onClick={() => handleDownload(base64Imgsign)}>
                                              {fileNameSign}
                                            </a>
                                          </div>
                                        ) : (
                                          <a href='#/' onClick={() => handleDownload(base64Imgsign)}>
                                            Download {fileNameSign}
                                          </a>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div class="col-6 col-md-3 mb-3">
                                  <div class="mb-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <label className="form-label" for="paymentName">
                                        W9 Form
                                      </label>
                                      <span className="font-size-12 text-primary">Allowed file types: jpeg/png/pdf.</span>
                                    </div>

                                    <input
                                      type="file"
                                      name="w9_form"
                                      className="form-control"
                                      {...register2("w9_form", {
                                        // required: 'W9 Form is required',
                                        /*{
                                        validate: () => {
                                          if (!Imgname) {
                                            return 'Profile Image is required';
                                          }
                                          return true;
                                          },
                                      }*/
                                      })}
                                      onChange={(e) => handleFileChange2(e)}
                                    />
                                    {errors2.w9_form && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors2.w9_form.message}
                                      </div>
                                    )}

                                    {/* <img src={base64Imgw9} width="100px" /> */}
                                    {fileNameW9 && (
                                      <>
                                        {fileNameW9.endsWith('.png') || fileNameW9.endsWith('.jpeg') || fileNameW9.endsWith('.JPEG') || fileNameW9.endsWith('.PNG') || fileNameDl.endsWith('.jpg') || fileNameDl.endsWith('.JPG') ? (
                                          <div>
                                            <img src={base64Imgw9} alt="File Preview" style={{ width: '100px' }} />
                                            <a href='#/' onClick={() => handleDownload(base64Imgw9)}>
                                              {fileNameW9}
                                            </a>
                                          </div>
                                        ) : (
                                          <a href='#/' onClick={() => handleDownload(base64Imgw9)}>
                                            Download {fileNameW9}
                                          </a>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                <button
                                  class="btn btn-warning w-md"
                                  type="button"
                                  onClick={() => {
                                    toggleSection("sectionA");
                                    toggleSection("sectionC");
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  class="btn btn-primary w-md me-md-2"
                                  id="account_submit"
                                  type="submit"
                                >
                                  Update
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* <!-- /.modal --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Customer Updation */}
          </div>
          {/* End Page-content */}
          <Footer />
        </div>
        {/* end main content*/}
      </div>
      {/* END layout-wrapper */}
      {/* Right bar overlay*/}
      <div className="rightbar-overlay"></div>
    </>
  );
}

export default CustomerList;
